export const useImageFunctions = (
  callbackDelete: (id: number | string) => Promise<void>,
) => {
  const uploadImage = (linkImage: string, name?: string) => {
    const link = document.createElement('a');
    link.href = linkImage;
    link.download = name || 'download.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const deleteImage = async (id: number | string) => {
    await callbackDelete(id);
  };
  return { uploadImage, deleteImage };
};
