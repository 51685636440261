export const capitalize = (word: string) =>
  `${word.charAt(0).toUpperCase()}${word.toLowerCase().slice(1)}`;

export const isEmailValid = (email: string) =>
  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

export const formatDescription = (description?: string, length = 140) => {
  if (!description) return;
  let formattedDescription = description.slice(0, length).trim();
  if (description?.length > length) formattedDescription += '...';
  return formattedDescription;
};

export const concatName = (firstName?: string, lastName?: string) =>
  `${firstName || ''} ${lastName || ''}`.trim();
