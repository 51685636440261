import { createUrl } from '@/utils/url';

const localeUrl = createUrl('locale');

const getLocaleVoice = localeUrl('list');
const getLocaleUiList = localeUrl('uiList');

export const locale = {
  getLocaleVoice,
  getLocaleUiList,
};
