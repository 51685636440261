<template>
  <modal-notification
    width="56rem"
    :is-open="isOpen"
    @on-close="$emit('close')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <template #header>
      <h3 class="onboard__header">
        <template v-if="isWorkspaceOwner">
          {{ $t('onboard.title') }}
        </template>
        <template v-else>
          {{ $t('onboard.join_title') }}
          <icon-marker :color="currentWorkspace?.color" />
          {{ currentWorkspace?.name }}
        </template>
      </h3>
    </template>
    <template #default>
      <form class="onboard__form" @click.prevent>
        <div class="onboard__row speech">
          <span>🔥 You have your first task!</span>
          <div>
            <span class="disabled-text">Performer</span>
            <span>You</span>
            <UiColorfilledText type="URGENT" badge>URGENT</UiColorfilledText>
          </div>
        </div>
        <div class="onboard__row">
          <h4 class="onboard__title">{{ $t('onboard.first_last_name') }}</h4>
          <div class="onboard__name-row">
            <ui-input-underscore
              :label="$t('onboard.name_placeholder')"
              v-model="onboardForm.firstName"
              :pictogram="false"
            />
            <ui-input-underscore
              :label="$t('onboard.last_name_placeholder')"
              v-model="onboardForm.lastName"
              :pictogram="false"
            />
          </div>
        </div>
        <div class="onboard__row" v-if="isWorkspaceOwner">
          <h4 class="onboard__title">{{ $t('onboard.workspace') }}</h4>
          <ui-input-underscore
            :label="$t('onboard.workspace_placeholder')"
            v-model="onboardForm.workspaceName"
            :pictogram="false"
          />
        </div>
      </form>
      <transition name="blur" appear mode="out-in">
        <UiSpinner
          class="onboard__pending"
          v-if="fetchPending && !accountsList.length"
        />
        <template v-else>
          <div class="onboard__accounts with-scroll">
            <div
              class="onboard__accounts-item"
              v-for="account in accountsUpdatesState"
              :key="account.id"
              @click="openedAccount = account"
            >
              <div class="onboard__accounts-credentials">
                <span>
                  {{ `${account?.firstName}&nbsp;${account?.secondName}` }}
                </span>
                <span>{{ account?.email }}</span>
              </div>
              <div class="onboard__accounts-setup">
                <span
                  v-if="viewedAccounts.includes(account.id)"
                  class="green-text"
                >
                  Calendar is set up
                </span>
                <template v-else>
                  <span>Set up calendar</span>
                  <img
                    src="@images/integrations/double-chevron-right.svg"
                    alt=""
                  />
                </template>
              </div>
            </div>
            <div
              class="onboard__accounts-empty"
              @click="googleSignInAccountCallback"
            >
              <img src="@images/integrations/google-calendar.svg" alt="" />
              <span v-if="!accountsList?.length">
                Do you want to add your
                <span class="purple-text">Google Account</span>? All your
                calendars will be in Voiset at once!
              </span>
              <span v-else> Add another account </span>
              <EmptyAddButton />
            </div>
          </div>
        </template>
      </transition>
      <ui-button
        class="onboard__confirm"
        :is-disabled="isConfirmDisabled"
        :is-loading="pending"
        @click="onConfirm"
      >
        {{ $t('onboard.confirm') }}
      </ui-button>
      <teleport to="body">
        <ModalSetupAccountGoogle
          :account="openedAccount"
          @onApply="onApplyUpdate"
          @onClose="onCloseUpdate"
        />
      </teleport>
      <AccountSetupSpinner :pending="pending">
        <template #label>Connecting your Google calendars</template>
        <template #description>
          Connect Google Calendars to the Voiset platform for automatic
          synchronization, updates, and event management.
        </template>
      </AccountSetupSpinner>
    </template>
    <template #footer><div /></template>
  </modal-notification>
</template>

<script setup lang="ts">
import { computed, provide, ref, watch } from 'vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import UiInputUnderscore from '@/components/control/UiInputUnderscore.vue';
import services from '@/api/services';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import { Workspace } from '@/types/workspaces';
import { useUserStore } from '@/stores/user';
import { useSettingsStore } from '@/stores/settings/settings';
import IconMarker from '@/components/icon/IconMarker.vue';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import EmptyAddButton from '@/components/empty/EmptyAddButton.vue';
import { googleSdkLoaded } from 'vue3-google-login';
import { GOOGLE_LOGIN_CONFIG } from '@/configs/google-login';
import { ElNotification } from 'element-plus';
import { useGoogleAccounts } from '@/stores/integrations/google';
import ModalSetupAccountGoogle from '@/components/modals/Integrations/google/ModalSetupAccountGoogle.vue';
import { GoogleAccount } from '@/types/integrations';
import { GoogleCalendarWithDirty } from '@/hooks/integrations/useCalendarsState';
import AccountSetupSpinner from '@/components/integrations/AccountSetupSpinner.vue';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';

defineEmits(['close']);

const isOpen = ref(false);
const pending = ref(false);

const onboardForm = ref({
  firstName: '',
  lastName: '',
  workspaceName: '',
});

provide(
  'startWorkspace',
  computed(() => onboardForm.value.workspaceName),
);

const isConfirmDisabled = computed(
  () =>
    !onboardForm.value.firstName ||
    !onboardForm.value.lastName ||
    !isAllAccountsViewed.value,
);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const settingsStore = useSettingsStore();
const usersStore = useSubordinatesStore();

const workspacesStore = useWorkspaceStore();
const { currentWorkspace, isWorkspaceOwner, list } =
  storeToRefs(workspacesStore);

const googleAccounts = useGoogleAccounts();
const { accountsList, fetchPending } = storeToRefs(googleAccounts);
const openedAccount = ref<GoogleAccount | null>();
const accountsUpdatesState = ref<
  Record<number, GoogleAccount & { calendars?: GoogleCalendarWithDirty[] }>
>({});

const viewedAccounts = ref<number[]>([]);
const isAllAccountsViewed = computed(
  () =>
    Object.keys(accountsUpdatesState.value).length ===
    viewedAccounts.value.length,
);

watch(
  () => accountsList.value,
  () => {
    const firstAccount = accountsList.value?.[0];
    !onboardForm.value.firstName &&
      (onboardForm.value.firstName = firstAccount?.firstName || '');
    !onboardForm.value.lastName &&
      (onboardForm.value.lastName = firstAccount?.secondName || '');
  },
  { immediate: true },
);

watch(
  () => accountsList.value,
  () => {
    accountsUpdatesState.value = accountsList.value.reduce((acc, a) => {
      !acc[a.id] && (acc[a.id] = a);
      return acc;
    }, {} as Record<number, GoogleAccount>);
  },
  { deep: true },
);

const onApplyUpdate = ({
  state,
  accountId,
}: {
  state: GoogleCalendarWithDirty;
  accountId: number;
}) => {
  openedAccount.value?.id && viewedAccounts.value.push(openedAccount.value.id);
  openedAccount.value = null;
  const account = accountsUpdatesState.value[accountId];
  const calendarId = account.calendars?.findIndex((c) => c.id === state.id);
  if (!calendarId) return;
  if (calendarId > -1) account.calendars?.splice(calendarId, 1, state);
};

const onCloseUpdate = () => {
  openedAccount.value?.id && viewedAccounts.value.push(openedAccount.value.id);
  openedAccount.value = null;
};

const onConfirm = async () => {
  if (!userData.value?.email) return;
  const requests = [];
  const generateUpdatesRequests = () =>
    Object.entries(accountsUpdatesState.value).map(([accountId, account]) =>
      (account.calendars as GoogleCalendarWithDirty[])
        ?.filter((c) => c.dirty)
        .map((c) => googleAccounts.setCalendar(c, accountId)),
    );

  try {
    pending.value = true;
    const { workspaceName, ...credentials } = onboardForm.value;
    requests.push(services.auth.changeUserInfo(credentials));
    if (workspaceName) {
      const modifyWorkspaceParams: Partial<Workspace> = {
        name: workspaceName,
        id: currentWorkspace.value?.id,
      };
      requests.push(services.workspaces.modify(modifyWorkspaceParams));
    }
    requests.push(...generateUpdatesRequests());
    await Promise.allSettled(requests);
    await Promise.allSettled([
      settingsStore.getUserData(),
      usersStore.fetch(),
      userStore.getUserData(),
    ]);

    const firstGoogleCalendar = accountsList.value?.[0]?.calendars?.[0];
    if (workspaceName || firstGoogleCalendar) await workspacesStore.fetchList();

    if (firstGoogleCalendar) {
      const id = list.value.find(
        (w) =>
          w.externalCalendarItem?.id === firstGoogleCalendar.id &&
          ['CREATE', 'CONFIRMED'].includes(w.externalCalendarItem?.status),
      )?.id;
      id && (await workspacesStore.setCurrent(id));
      await userStore.getUserData();
    }
  } catch (e) {
    console.error(e);
  } finally {
    isOpen.value = false;
    pending.value = false;
  }
};

const googleSignInAccountCallback = async () => {
  googleSdkLoaded((google) => {
    google.accounts.oauth2
      .initCodeClient({
        ...GOOGLE_LOGIN_CONFIG,
        callback: async (response) => {
          if (!response.code) return ElNotification.error('Error');
          const account = await googleAccounts.add({
            googleAuthorizationCode: response.code,
          });
          if (!account) return;
          const lastNumber = accountsList.value.push(account);
          await googleAccounts.fetchAccountCalendars(
            accountsList.value[lastNumber - 1],
          );
        },
      })
      .requestCode();
  });
};

watch(
  () => [userData.value, currentWorkspace.value],
  () => {
    if (!userData.value || !currentWorkspace.value) return;
    isOpen.value =
      !userData.value?.firstName &&
      !userData.value?.lastName &&
      !!currentWorkspace.value;
  },
  { deep: true, immediate: true },
);

watch(
  () => isOpen.value,
  () => isOpen.value && googleAccounts.fetchAccountsWithCalendars(),
  { immediate: true },
);
</script>

<style scoped lang="scss">
.onboard {
  &__form {
    @include flex-column(1.6rem);
    justify-content: center;
    align-items: center;
    padding: 1.6rem 2rem;
    border-radius: 1.6rem;
    box-shadow: 0 0 9.3px 0 rgba(163, 163, 163, 0.25);
    margin-bottom: 2rem;
  }

  &__header {
    @include flex-row(1rem);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @include f20;
    font-weight: 500;
  }

  &__pending {
    min-height: 10rem;
  }

  &__title {
    @include f16;
    text-align: left;
    margin-bottom: 1rem;
  }

  &__row {
    width: 100%;

    &.speech {
      @include flex-row(1rem);
      align-items: center;
      justify-content: space-between;

      & > div {
        @include flex-row(1rem);
        align-items: center;

        & > *:last-child {
          border-left: 0.1rem solid #a3c0f980;
          padding-left: 1rem;
        }
      }
    }
  }

  &__name {
    &-row {
      @include flex-row(1.2rem);

      & > * {
        width: 100%;
      }
    }
  }

  &__confirm {
    width: fit-content;
    margin: 2rem 0 0 auto;
  }

  &__accounts {
    width: 100%;
    @include flex-column(1rem);
    overflow: auto;
    max-height: 30rem;

    &-item {
      @include flex-row(1rem);
      align-items: center;
      justify-content: space-between;
      text-align: left;
      height: 5rem;
      padding: 0.5rem;
      cursor: pointer;
      @include gray-bg-hover;
    }

    &-setup {
      grid-row: span 2;
      @include flex-row(1rem);
      align-items: center;
    }

    &-credentials {
      @include flex-column;
    }

    &-empty {
      display: grid;
      grid-template-columns: auto 1fr 4.8rem;
      grid-template-rows: 4.8rem;
      align-items: center;
      gap: 2rem;
      padding: 1rem 1rem 1rem 0.5rem;
      cursor: pointer;
      position: sticky;
      bottom: 0;
      background: #fff;
      @include gray-bg-hover;

      & > img {
        height: 100%;
      }

      & > span {
        text-align: left;
      }
    }
  }
}
</style>
