import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { ISubordinatesFilters, ISubordinate } from '@/types/subordinate';
import { Pageable } from '@/types/pagination';

export class PositionService extends ApiService {
  getSubordinates(filters?: Partial<ISubordinatesFilters>) {
    return axiosInstance.get<Pageable<ISubordinate>>(
      ENDPOINTS.positions.getSubordinates,
      {
        ...(filters && { params: filters }),
      },
    );
  }

  getUsers(filters?: Partial<ISubordinatesFilters>) {
    return axiosInstance.get<Pageable<ISubordinate>>(
      ENDPOINTS.positions.getUsers,
      {
        ...(filters && { params: filters }),
      },
    );
  }
}
