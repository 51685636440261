import { RouteRecordRaw } from 'vue-router';
const LoginView = () => import('@/views/login.vue');

export default {
  path: '/login',
  name: 'Login',
  component: LoginView,
  meta: {
    layout: 'auth',
    public: true,
    image: 'progress.webp',
    slogan: 'auth.login_slogan',
  },
} as RouteRecordRaw;
