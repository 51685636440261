import { formatSeconds } from '@/utils/formatSeconds';
import { ref } from 'vue';

export const useAudioPlayer = () => {
  let audio: HTMLAudioElement | undefined;
  const isPlaying = ref(false);
  const playerTime = ref('0:00');

  const initAudioPlayer = (recordedFile: string | null) => {
    if (!recordedFile) return;
    audio = new Audio(recordedFile);

    audio.addEventListener(
      'timeupdate',
      () => (playerTime.value = formatSeconds(audio?.currentTime)),
    );
    audio.addEventListener(
      'canplay',
      () => (playerTime.value = formatSeconds(audio?.duration)),
    );
    audio.addEventListener('ended', () => (isPlaying.value = false));
  };

  const play = () => {
    audio?.play();
    isPlaying.value = true;
  };

  const pause = () => {
    audio?.pause();
    isPlaying.value = false;
  };

  return {
    initAudioPlayer,
    play,
    pause,
    isPlaying,
    playerTime,
  };
};
