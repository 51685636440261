import { RouteRecordRaw } from 'vue-router';

const BookingView = () => import('@/views/booking.vue');
const BookingIndex = () => import('@/views/booking/index.vue');

export default {
  path: '/booking',
  component: BookingView,
  redirect: { name: 'BookingUuid' },
  meta: {
    layout: 'booking',
    public: true,
    header: true,
  },
  children: [
    {
      name: 'BookingUuid',
      path: 'uuid',
      component: BookingIndex,
    },
  ],
} as RouteRecordRaw;
