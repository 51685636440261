<template>
  <div class="modal-create-footer footer">
    <div class="footer__lang">
      <el-popover width="fit-content" trigger="click">
        <lang-recognition-selector />
        <template #reference>
          <div class="footer__lang-reference">
            <span class="footer__lang-current">
              {{ userStore.userData?.locale.name }}
            </span>
            <span class="footer__lang-desc">
              {{ $t('create_modal_task.select_lange') }}
            </span>
          </div>
        </template>
      </el-popover>
    </div>
    <div class="footer__record">
      <ui-audio-recorder
        :audio="audio"
        @onStartRecord="onStartRecord"
        @onStopRecord="onStopRecord"
      />
    </div>
    <div class="footer__control">
      <ui-button
        :isDisabled="isCreateBtnDisabled"
        :isLoading="isSubmitLoading"
        class="footer__control-submit"
        @click="$emit('click-submit')"
      >
        {{ $t('notes.create') }}
        <icon-check color="#ffffff" />
      </ui-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed, toRefs, watch } from 'vue';
import LangRecognitionSelector from '@/components/modals/CreateTask/ModalCreateTask/LangRecognitionSelector.vue';
import IconCheck from '@/components/icon/IconCheck.vue';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';
import UiAudioRecorder from '@/components/ui/UiAudioRecorder.vue';
import { sockets } from '@/api/services';

const userStore = useUserStore();
const createNoteStore = useCreateNoteStore();
const { audio, state } = storeToRefs(createNoteStore);

defineEmits(['click-submit']);

type Props = {
  isSubmitLoading: boolean;
  isOpenModal: boolean;
};
defineProps<Props>();

const onStartRecord = async () => {
  sockets.speech.emitter
    .subscribe('AddPartialTranscript', (speech: string) => {
      audio.value.runtimeSpeech = speech;
    })
    .subscribe('AddTranscript', (speech: string) => {
      audio.value.runtimeSpeech = '';
      state.value.description += speech;
    })
    .subscribe('onClose', () => (audio.value.runtimeSpeech = ''));
};

const onStopRecord = async () => {
  sockets.speech.emitter.reset();
  await createNoteStore.generate(state.value.description);
};

const isCreateBtnDisabled = computed(() => {
  const { title, description } = state.value;

  return !(title && description) || isRecording.value;
});

const { isRecording, recordFile } = toRefs(audio.value);

watch(recordFile, (val) => (audio.value.blobData = val || undefined));
</script>

<style lang="scss" scoped>
.footer {
  @include flex-row;
  min-height: 11rem;

  & > * {
    flex: 1;
  }

  &__lang {
    margin: auto;
    @include flex-column(1rem);

    &-reference {
      @include flex-column(1rem);
      align-items: center;
    }

    &-current {
      @include flex-row;
      justify-content: center;
      align-items: center;
      width: fit-content;
      border-radius: 4rem;
      background: #fff;
      padding: 0.7rem 1.6rem;
      border: 0.2rem solid #b8b8d233;
      cursor: pointer;
    }

    &-current,
    &-desc {
      @include f12;
    }
  }

  &__record {
    border-left: 0.1rem solid #dde3ec;
    border-right: 0.1rem solid #dde3ec;
    position: relative;
    bottom: -1rem;
  }

  &__control {
    @include flex-column(2rem);
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    &-submit {
      @include flex-row(1rem);
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      @include f12;
    }
  }
}
</style>
