<template>
  <ui-dropdown
    v-model="model"
    :items="items"
    priority-links
    :label="$t('priority')"
    v-bind="$attrs"
    class="ui-dropdown-priority"
  >
    <template #customModelValue="{ modelValue }">
      <ui-colorfilled-text
        badge
        :type="modelValue.value as TaskPriority[number]"
        v-if="model.value"
        class="ui-dropdown-priority__active"
      >
        {{ $t(`task_priority.${modelValue.title}`) }}
      </ui-colorfilled-text>
      <template v-else> {{ modelValue.title }} </template>
    </template>
    <template #default="{ item }">
      <ui-colorfilled-text badge :type="item.value as TaskPriority[number]">
        {{ $t(`task_priority.${item.title}`) }}
      </ui-colorfilled-text>
    </template>
  </ui-dropdown>
</template>

<script
  setup
  lang="ts"
  generic="T extends { [Field in 'value' | 'title']: TaskPriority[number]}"
>
import { computed } from 'vue';
import UiDropdown from './UiDropdown.vue';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import { TaskPriority } from '@/consts';

const props = defineProps<{
  modelValue: T;
  items: T[];
}>();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const emit = defineEmits(['update:modelValue']);
</script>

<style scoped lang="scss">
.ui-dropdown-priority {
  &[is-smart-value]:not([is-smart-value='false']) {
    border-color: var(--color-primary);
  }

  &__active {
    width: 100%;
  }
}
</style>
