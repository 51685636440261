import { invitation } from '@/api/endpoints/invitations';
import { auth } from '@/api/endpoints/auth';
import { tasks } from '@/api/endpoints/tasks';
import { positions } from './positions';
import { locale } from './locale';
import { voice } from './voice';
import { filesEndpoint as files } from './files';
import { share } from './shareLink';
import { tariffs } from './tariffs';
import { notificationsEndpoint as notifications } from './notifications';
import { commentEndpoint as comment } from './comment';
import { analyticsEndpoint as analytics } from './analitycs';
import { purchaseEndpoint as purchase } from './purchase';
import { usersEndpoint as users } from './users';
import { reportsEndpoints as reports } from './reports';
import { companiesEndpoint as companies } from './companies';

export const ENDPOINTS = {
  invitation,
  auth,
  tasks,
  positions,
  voice,
  files,
  share,
  tariffs,
  comment,
  analytics,
  notifications,
  locale,
  purchase,
  users,
  reports,
  companies,
};
