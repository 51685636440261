<script setup lang="ts">
import NoteTransformCalendarDay from '@/components/notes/transform/calendar/NoteTransformCalendarDay.vue';
import { useNotesByCalendar } from '@/stores/notes/useNotesByCalendar';
import { storeToRefs } from 'pinia';

const notesByCalendar = useNotesByCalendar();
const { calendar, currentGroup } = storeToRefs(notesByCalendar);
</script>

<template>
  <div class="note-transform-calendar" v-if="currentGroup && calendar.length">
    <NoteTransformCalendarDay
      v-for="day in calendar"
      v-model:items="day.items"
      :color="currentGroup.workspace.color"
      :date="day.date"
      :key="day.date"
    />
  </div>
</template>

<style scoped lang="scss">
.note-transform-calendar {
  @include flex-column(1rem);
  padding: 1rem 3rem 1rem;
}
</style>
