import { Note } from '@/types/notes';
import { Task } from '@/types/tasks';
import { dragChosenPlugin } from '@/utils/drag/drag-chosen-plugin';
import { dragAndDrop } from '@formkit/drag-and-drop/vue';
import { Ref } from 'vue';

export const useNoteCalendarDrag = ({
  el,
  list,
}: {
  el: Ref<HTMLElement | undefined>;
  list: Ref<(Note | Task)[]>;
}) => {
  dragAndDrop<Note | Task>({
    parent: el,
    values: list,
    group: 'note-calendar',
    dragHandle: '.note-transform-calendar-note',
    dropZoneClass: 'drag-ghost',
    touchDropZoneClass: 'drag-ghost',
    threshold: {
      vertical: 0,
      horizontal: 0,
    },
    scrollBehavior: {
      x: 0.8,
      y: 0.8,
      scrollOutside: true,
    },
    plugins: [dragChosenPlugin()],
  });
};
