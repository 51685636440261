<template>
  <div class="ui-input">
    <input
      v-bind="$attrs"
      :value="modelValue"
      :class="[
        'ui-input__control',
        {
          'not-empty': modelValue?.length,
          'no-underscore': readonlyNoUnderscore,
          small,
        },
      ]"
      :readonly="readonly"
      @input="
        !readonly &&
          $emit('update:modelValue', ($event?.target as HTMLInputElement).value)
      "
      ref="input"
    />
    <label class="ui-label" v-if="label" @click="input?.focus">
      {{ label }}
    </label>
    <img
      class="ui-input__pictogram"
      v-if="!readonly && !$slots.action && pictogram"
      :src="require(`@images/pictogram/edit.svg`)"
      alt=""
    />
    <div class="ui-input__action" v-if="$slots.action">
      <slot name="action" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

type Props = {
  label?: string;
  modelValue?: string;
  readonly?: boolean;
  small?: boolean;
  readonlyNoUnderscore?: boolean;
  pictogram?: boolean;
};

withDefaults(defineProps<Props>(), {
  label: '',
  modelValue: '',
  pictogram: true,
});

const input = ref<HTMLInputElement | null>(null);
defineExpose({ input });
</script>

<style lang="scss" scoped>
.ui-input {
  position: relative;
  display: flex;
  align-items: center;
  &__control {
    height: 4.8rem;
    width: 100%;
    padding-bottom: 0.6rem;
    padding-right: 2.2rem;
    border: none;
    border-bottom: 0.1rem solid #b8b8d2;
    background: transparent;

    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;

    outline: none;
    transition: all 0.1s ease-in-out;

    &.small {
      height: 2rem;
    }

    &.no-underscore {
      border-bottom-color: transparent;
    }

    &:focus {
      border-color: var(--color-primary);
    }

    &:focus,
    &.not-empty {
      + label {
        top: 0;
        font-size: 1.2rem;
        color: #b8b8d2;
        line-height: 0.2rem;
      }
    }

    &.error {
      border-color: var(--color-danger);
    }
  }

  label {
    position: absolute;
    top: 50%;
    background: transparent;
    border-radius: 0.2rem;
    transform: translateY(-50%);
    @include f16;
    color: #b8b8d2;
    cursor: text;
    transition: all 0.15s ease-in-out;
    pointer-events: none;
  }

  .hide-pass {
    position: absolute;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__pictogram {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    right: 0;
  }

  &__action {
    display: flex;
    align-items: center;
  }
}
</style>
