import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import {
  GoogleAccount,
  GoogleCalendar,
  SetCalendarRequestParams,
  TokenType,
} from '@/types/integrations';
import { ThirdPartyRequestParams } from '@/types/user';

export class GoogleIntegrationService extends ApiService {
  getAccountCalendarsList(email: string, tokenType: TokenType) {
    return axiosInstance.get<GoogleCalendar[]>(
      ENDPOINTS.auth.accountCalendars,
      {
        params: { tokenType, email },
      },
    );
  }

  getAccountsList(tokenType: TokenType) {
    return axiosInstance.get<GoogleAccount[]>(ENDPOINTS.auth.accounts, {
      params: { tokenType },
    });
  }

  addAccount(auth: ThirdPartyRequestParams, tokenType: TokenType) {
    return axiosInstance.post<GoogleAccount>(ENDPOINTS.auth.accounts, auth, {
      params: { tokenType, web: true },
    });
  }

  setAccount(
    connectedId: string | number,
    calendarStatus: GoogleAccount['calendarStatus'],
  ) {
    return axiosInstance.put<GoogleAccount>(ENDPOINTS.auth.accounts, null, {
      params: { connectedId, calendarStatus },
    });
  }

  setCalendar(params: SetCalendarRequestParams) {
    return axiosInstance.put<GoogleCalendar>(ENDPOINTS.auth.setCalendar, null, {
      params,
    });
  }
}
