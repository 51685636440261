<template>
  <ui-drop-area ref="dropAreaRef">
    <template #default="{ isActiveDropArea }">
      <div
        class="ui-drop-files"
        :class="{ 'ui-drop-files--active': isActiveDropArea }"
        @drop.prevent="handleDrop"
        @dragenter.prevent
        @dragover.prevent
        @dragleave.prevent
      >
        <input
          ref="inputFileRef"
          type="file"
          multiple
          class="ui-drop-files__control"
          @change="onChange"
        />
        <div class="ui-drop-files__title">
          <icon-cloud class="ui-drop-files__title-icon" />
          {{ $t('upload_drag_file') }}
        </div>
        <div class="ui-drop-files__preview" v-if="modelValue.length">
          <div
            class="ui-drop-files__preview-item"
            v-for="(file, idx) in modelValue"
            :key="idx"
            @click="removeFile(idx)"
          >
            <img
              v-if="isImage(file)"
              :src="getSrc(file)"
              class="ui-drop-files__preview-img"
              alt="img"
            />
            <div v-else class="ui-drop-files__preview-document">
              <div class="ui-drop-files__preview-extension">
                <icon-create color="#778bee" />
                <span>{{ file.name.split('.').pop() }}</span>
              </div>
              <span>{{ file.name }}</span>
            </div>
            <icon-trash color="#fff" class="ui-drop-files__preview-icon" />
          </div>
        </div>
      </div>
    </template>
  </ui-drop-area>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import UiDropArea from '@/components/ui/UiDropArea.vue';
import IconCloud from '@/components/icon/IconCloud.vue';
import IconTrash from '@/components/icon/IconTrash.vue';
import { isImage } from '@/utils/files';
import IconCreate from '@/components/icon/IconCreate.vue';

const emit = defineEmits(['update:modelValue']);

const dropAreaRef = ref<InstanceType<typeof UiDropArea> | null>(null);

const props = defineProps<{
  modelValue: File[];
}>();

const onChange = (e: Event) => {
  const files = (e.target as HTMLInputElement).files;
  if (files) {
    emit('update:modelValue', [...props.modelValue, ...Array.from(files)]);
  }
};

const handleDrop = function (e: DragEvent) {
  const dt = e.dataTransfer;
  if (!dt?.files) return;

  emit('update:modelValue', [...props.modelValue, ...Array.from(dt.files)]);
  if (dropAreaRef.value) {
    dropAreaRef.value.setInactiveDropArea();
  }
};

const removeFile = (idx: number) => {
  emit(
    'update:modelValue',
    props.modelValue.filter((_, fileIdx) => idx !== fileIdx),
  );
};

const getSrc = (file: File) => URL.createObjectURL(file);
</script>

<style scoped lang="scss">
.ui-drop-files {
  position: relative;
  min-height: 15rem;
  padding-top: 2rem;
  background-color: #fcfcfc;
  border: 0.1rem dashed #b8b8d2;
  border-radius: 0.8rem;
  cursor: pointer;

  &--active {
    border-color: var(--color-primary);
  }

  &__control {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    @include f14;
    color: #1a2a39;

    &-icon {
      margin-right: 1.4rem;
    }
  }

  &__preview {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 1rem 4rem;

    &-item {
      position: relative;
      border-radius: 0.8rem;
      overflow: hidden;
      width: 100%;
      height: 10rem;
      min-width: 11.8rem;
      cursor: pointer;
      flex-grow: 1;
      box-shadow: 0 1rem 3rem -0.5rem rgba(169, 169, 169, 0.2);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(#000000, 0.4);
        transition: all 0.2s linear;
        opacity: 0;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
        .ui-drop-files__preview-icon {
          opacity: 1;
        }
      }
    }

    &-img {
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-position: center;
    }

    &-document {
      @include flex-column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      height: 100%;
      width: 100%;
      box-shadow: 0 10px 30px -5px rgba(169, 169, 169, 0.2);

      > span {
        width: inherit;
        @include text-ellipsis;
        text-align: center;
      }
    }

    &-extension {
      position: relative;

      > svg {
        width: 6rem;
        height: 6rem;
      }

      > span {
        position: absolute;
        width: 4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: var(--color-primary);
      }
    }

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transition: all 0.2s linear;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
  }
}
</style>
