import { RouteRecordRaw } from 'vue-router';
const UsersView = () => import('@/views/users/users.vue');
const UsersIndex = () => import('@/views/users/index.vue');
const UsersInvitations = () => import('@/views/users/invitations.vue');

export default {
  path: '/users',
  component: UsersView,
  meta: {
    layout: 'app',
  },
  children: [
    { path: '', name: 'Users', component: UsersIndex },
    { path: 'invitations', name: 'Invitations', component: UsersInvitations },
  ],
} as RouteRecordRaw;
