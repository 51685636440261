<template>
  <div class="ui-filter">
    <div class="ui-filter__header">
      <span>{{ label }}</span>
    </div>
    <div class="ui-filter-dropdown">
      <button
        :class="['ui-filter__button', { active: isEnabledAssignPreset }]"
        @click="setAssignPreset"
      >
        {{ $t('tasks.filters.values.ASSIGMENT_TO_ME') }}
      </button>
      <button
        :class="[
          'ui-filter__button',
          { active: isEnabledCompletedTasksPreset },
        ]"
        @click="setCompletedTasksPreset"
      >
        {{ $t('tasks.filters.values.COMPLETED_TASKS') }}
      </button>
    </div>
    <div class="ui-filter__header">
      <span>{{ $t('tasks.filters.time_presets') }}</span>
      <div class="ui-filter__clear" @click="resetFilters">
        {{ $t('tasks.filters.clear') }}
      </div>
    </div>
    <div class="ui-filter-dropdown">
      <div
        class="ui-filter-dropdown__row"
        v-for="option in options"
        :key="option?.label"
      >
        <el-radio-group
          :name="props.filterField"
          :model-value="filtersState?.[filterField]"
          @update:model-value="setFilter?.(filterField, $event)"
        >
          <el-radio
            :value="option.value"
            :label="getLabel(option?.label) || option.value"
          />
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { TableFilter } from '@/types/common';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { completedTasksStatuses, ITaskStatusItem } from '@/consts';

const { t, te } = useI18n();

const getLabel = (label?: string) => (label && te(label) ? t(label) : label);

const props = defineProps<TableFilter>();

const filtersState: Record<string, any> | undefined = inject('filtersState');
const setFilter: ((...args: any[]) => void) | undefined = inject('setFilter');

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const isEnabledAssignPreset = computed(
  () =>
    filtersState?.executorIdList?.length === 1 &&
    filtersState?.executorIdList.includes(userData.value?.id),
);

const setAssignPreset = () => {
  if (!filtersState?.executorIdList || !userData.value?.id) return;
  filtersState.executorIdList = [userData.value.id];
};

const isEnabledCompletedTasksPreset = computed(
  () =>
    filtersState?.statusList.length === 2 &&
    filtersState.statusList.every((s: ITaskStatusItem) =>
      completedTasksStatuses.includes(s),
    ),
);

const setCompletedTasksPreset = () => {
  if (!filtersState?.statusList) return;
  filtersState.statusList = completedTasksStatuses;
};

const resetFilters = () => {
  const filter = filtersState?.[props.filterField];
  setFilter && setFilter(props.filterField, filter instanceof Array ? [] : '');
};
</script>

<style scoped lang="scss"></style>
