import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import services from '@/api/services';
import { useInvitationsFilter } from './invitationsFilter';
import { ElNotification } from 'element-plus';
import { Pageable } from '@/types/pagination';
import { IInvitation, InternalInvitation } from '@/types/invitation';

import { useDebounceFn } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { AxiosError } from 'axios';

export const useInvitationsStore = defineStore('invitations', () => {
  const { t } = useI18n();
  const { formatWithLocale } = useCurrentLanguage();

  const invitations = ref<Pageable<IInvitation> | null>(null);
  const invitationsView = computed(() => {
    if (!invitations.value) return null;
    return Object.assign(
      { ...invitations.value },
      {
        content: invitations.value?.content?.map((item) => {
          return {
            ...item,
            createDate: formatWithLocale(
              new Date(item.createDate),
              'dd MMM yyyy',
            ),
            expireDate: formatWithLocale(
              new Date(item.expireDate),
              'dd MMM yyyy',
            ),
          };
        }),
      },
    );
  });
  const invitationsFilterStore = useInvitationsFilter();
  const pending = ref(false);
  const error = ref(false);
  const internalInvitations = ref<InternalInvitation[]>([]);

  const { filters, filtersList, sorts } = storeToRefs(invitationsFilterStore);
  const { setFilter, setSort } = invitationsFilterStore;

  const fetchInvitations = async () => {
    try {
      error.value = false;
      pending.value = true;

      const response = await services.invitation.findAll({
        ...filters.value,
        ...sorts.value,
      });
      invitations.value = response.data;
      return response;
    } catch (e) {
      error.value = true;
      console.error(e);
      (e as AxiosError).response?.status !== 401 &&
        ElNotification.error({ message: t('some_error'), offset: 80 });
    } finally {
      pending.value = false;
    }
  };

  const revokeInvite = async (code: string) => {
    try {
      await services.invitation.revokeInvite({
        invitationCode: code,
      });
      await fetchInvitations();
    } catch (e) {
      ElNotification.error({ message: t('some_error'), offset: 80 });
      console.log(e);
    }
  };

  const createInvite = async (info: {
    email: string;
    companyId?: number;
    positionName?: string;
    scheduleTime?: string;
  }) => {
    return (await services.invitation.createInvite(info)).data;
  };

  const fetchInternalInvitations = async () => {
    internalInvitations.value = (
      await services.invitation.getInternalInvitations()
    ).data;
  };

  const setInvitationStatus = async (params: {
    invitationCode: string;
    result: boolean;
  }) => {
    return await services.invitation.setInvitationStatus(params);
  };

  const debouncedFetch = useDebounceFn(async () => {
    await fetchInvitations();
  }, 300);

  watch(() => [filters, sorts], debouncedFetch, { deep: true });

  return {
    pending,
    error,
    fetchInvitations,
    filters,
    setFilter,
    setSort,
    invitations,
    invitationsView,
    filtersList,
    revokeInvite,
    createInvite,
    sorts,
    fetchInternalInvitations,
    setInvitationStatus,
    internalInvitations,
  };
});
