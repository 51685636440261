import { defineStore, storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import services from '@/api/services';
import { Task } from '@/types/tasks';
import { Pageable } from '@/types/pagination';
import { ElNotification } from 'element-plus';
import { useTasksFiltersStore } from '@/stores/tasks/tasksFilters';
import { useDebounceFn } from '@vueuse/core';
import { compareDesc, endOfDay, format, startOfDay } from 'date-fns';
import { useI18n } from 'vue-i18n';
import { AxiosError } from 'axios';
import { stringDateToUTC } from '@/utils/time';
import { CURRENT_TIME_ZONE } from '@/consts/timeFormats';

export interface MyAnalytics {
  completed: number;
  overdue: number;
  percentCompleted: number;
  percentOverdue: number;
}

export const useTasksStore = defineStore('tasks', () => {
  const { t } = useI18n();
  const tasks = ref<Pageable<Task> | null>(null);
  const tasksCount = ref(0);
  const todoTaskCounter = ref<string | number>(0);

  const myAnalytics = ref<MyAnalytics>({
    completed: 0,
    overdue: 0,
    percentCompleted: 0,
    percentOverdue: 0,
  });

  const isLoadingAnalytics = ref(true);
  const pending = ref(false);
  const error = ref(false);

  const taskFiltersStore = useTasksFiltersStore();
  const { filters, filtersList, getDatesRangeView, getDatesRange, sorts } =
    storeToRefs(taskFiltersStore);
  const { initialFilters, setFilter, setSort, setDatesRange, refreshFilters } =
    taskFiltersStore;

  const debouncedFetch = useDebounceFn(async () => {
    await Promise.all([fetch(), getMyAnalytics()]);
  }, 0);

  watch(() => [filters, sorts], debouncedFetch, { deep: true });

  async function fetch() {
    try {
      error.value = false;
      pending.value = true;

      const response = await services.tasks.fetchTasks({
        ...filters.value,
        ...sorts.value,
      });
      response.data.content = response.data.content.map((task) => {
        const isAllDayTask = task.deadline.endsWith('23:59:59.999');
        const deadline = isAllDayTask
          ? task.deadline
          : stringDateToUTC(task.deadline, {
              initialDateUTC0: true,
              timeZone: CURRENT_TIME_ZONE,
            }) || '';
        return {
          ...task,
          deadline,
          deadlineLast: stringDateToUTC(task.deadlineLast, {
            initialDateUTC0: true,
            timeZone: CURRENT_TIME_ZONE,
          }),
          timeNotification: stringDateToUTC(task.timeNotification, {
            initialDateUTC0: true,
            timeZone: CURRENT_TIME_ZONE,
          }),
        };
      });
      tasks.value = response.data;
      return response;
    } catch (e) {
      error.value = true;
      console.error(e);
      (e as AxiosError).response?.status !== 401 &&
        ElNotification.error({ message: t('some_error'), offset: 80 });
    } finally {
      pending.value = false;
    }
  }

  async function getMyAnalytics({ ignoreFilters } = { ignoreFilters: false }) {
    try {
      isLoadingAnalytics.value = true;
      const { data } = await services.analytics.getSelf(
        format(
          startOfDay(
            ignoreFilters ? new Date() : new Date(filters.value.dateFrom),
          ),
          'yyyy-MM-dd HH:mm',
        ),
        format(
          endOfDay(ignoreFilters ? new Date() : new Date(filters.value.dateTo)),
          'yyyy-MM-dd HH:mm',
        ),
      );

      myAnalytics.value.completed = data.executedCountTasks || 0;
      myAnalytics.value.overdue = data.overdueCountTasks || 0;
      myAnalytics.value.percentCompleted =
        Math.round((data.executedCountTasks / data.totalCountTasks) * 100) || 0;
      myAnalytics.value.percentOverdue =
        Math.round((data.overdueCountTasks / data.totalCountTasks) * 100) || 0;
    } catch (e) {
      error.value = true;
      console.error(e);
      (e as AxiosError).response?.status !== 401 &&
        ElNotification.error({ message: t('some_error'), offset: 80 });
    } finally {
      isLoadingAnalytics.value = false;
    }
  }

  async function getMonthTaskCounts(
    month: number,
    year: number,
    executorId?: number,
  ) {
    try {
      const { taskDTO } = filters.value;
      return await services.tasks.getMonthTask(
        { ...taskDTO, ...(executorId && { executorIdList: [executorId] }) },
        month,
        year,
      );
    } catch (e) {
      console.error(e);
      (e as AxiosError).response?.status !== 401 &&
        ElNotification.error({
          message: t('some_error'),
          offset: 80,
        });
    }
  }

  async function getMonthTaskCountsByExecutor(
    month: number,
    year: number,
    executorId?: number,
  ) {
    if (!executorId) return;
    try {
      const taskDto = initialFilters().taskDTO;
      taskDto.executorIdList.push(executorId);
      return await services.tasks.getMonthTask(taskDto, month, year);
    } catch (e) {
      console.error(e);
      (e as AxiosError).response?.status !== 401 &&
        ElNotification.error({
          message: t('some_error'),
          offset: 80,
        });
    }
  }

  const updateTask = (task: Task) => {
    const currentTaskIndex = tasks.value?.content.findIndex(
      (item) => item.id === task.id,
    );

    if (
      tasks.value &&
      currentTaskIndex !== undefined &&
      currentTaskIndex > -1
    ) {
      tasks.value.content[currentTaskIndex] = task;
    }
  };

  const getTodoCount = async () => {
    try {
      const count = (await services.tasks.getTodoCount()).data;
      todoTaskCounter.value = new Intl.NumberFormat('en-GB', {
        notation: 'compact',
        compactDisplay: 'short',
      }).format(count);
    } catch (e) {
      console.error(e);
    }
  };

  const getTasksCount = async (id?: string | number) => {
    if (!id) return;
    try {
      return (tasksCount.value = (
        await services.tasks.getTasksCountById(id)
      ).data);
    } catch (e) {
      console.error(e);
    }
  };

  const isDateExpired = (row: Task) => {
    if (row.taskStatus === 'CLOSED') return;
    if (row.deadlineLast)
      return compareDesc(new Date(), new Date(row.deadlineLast)) < 0;

    return compareDesc(new Date(), new Date(row.deadline)) < 0;
  };

  return {
    tasks,
    pending,
    error,
    filters,
    filtersList,
    todoTaskCounter,
    getDatesRangeView,
    getDatesRange,
    fetch,
    initialFilters,
    refreshFilters,
    setFilter,
    setSort,
    setDatesRange,
    sorts,
    updateTask,
    getMonthTaskCounts,
    getMonthTaskCountsByExecutor,
    getTasksCount,
    isLoadingAnalytics,
    myAnalytics,
    getMyAnalytics,
    getTodoCount,
    isDateExpired,
  };
});
