import { RouteRecordRaw } from 'vue-router';
const TrialView = () => import('@/views/trial.vue');
const TrialIndex = () => import('@/views/trial/index.vue');
const TrialTariffId = () => import('@/views/trial/_tariffId.vue');

export default {
  path: '/trial',
  redirect: { name: 'TrialIndex' },
  name: 'TrialView',
  component: TrialView,
  children: [
    {
      path: '',
      name: 'TrialIndex',
      component: TrialIndex,
    },
    {
      path: ':tariffId',
      name: 'TrialTariffId',
      component: TrialTariffId,
    },
  ],
  meta: {
    layout: 'default',
    header: true,
  },
} as RouteRecordRaw;
