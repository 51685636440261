<template>
  <div class="widget-workspaces" v-skeleton="!currentWorkspace">
    <el-popover
      trigger="click"
      placement="bottom"
      width="35.5rem"
      popper-class="workspaces-manage"
      v-model:visible="isWidgetVisible"
    >
      <div class="widget-workspaces__options options" id="dashboard-workspaces">
        <template v-if="ownWorkspacesList?.length">
          <div class="options__row">
            <span class="disabled-text">{{ $t('workspaces.created') }}</span>
          </div>
          <div class="options__header">
            <img src="@images/integrations/google/voiset-logo.webp" alt="" />
            <span>Voiset</span>
            <div
              class="options__edit"
              @click="
                isOwnEditOpen = true;
                isWidgetVisible = false;
              "
            >
              {{ $t('workspaces.edit_btn') }}
            </div>
          </div>
          <div
            :class="[
              'options__list',
              'with-scroll',
              { pending: widgetPending },
            ]"
          >
            <div v-for="owner in ownWorkspacesList" :key="owner.id">
              <WorkspaceItem
                :key="owner.id"
                :item="owner"
                :is-active="currentWorkspace?.id === owner.id"
                @click="onChangeWorkspace(owner.id)"
              />
            </div>
          </div>
        </template>
        <template v-if="externalList.GOOGLE">
          <UiDropdownExpandable static :class="{ pending: widgetPending }">
            <template #label>
              <div class="widget-workspaces__label">
                <img
                  alt=""
                  :src="externalList.GOOGLE.config.icon"
                  v-if="externalList.GOOGLE.config?.icon"
                />
                {{ externalList.GOOGLE.config?.label }}
                <el-tooltip
                  content="General settings"
                  placement="right"
                  effect="customized"
                  v-if="externalList.GOOGLE.config?.optionsLink"
                >
                  <router-link
                    :to="{ name: externalList.GOOGLE.config.optionsLink }"
                    class="widget-workspaces__link"
                  >
                    <img src="@images/gear.svg" alt="" />
                  </router-link>
                </el-tooltip>
              </div>
            </template>
            <div
              v-for="group in externalList.GOOGLE.content"
              :key="group.account"
            >
              <UiDropdownExpandable
                :static="externalList.GOOGLE.content.length === 1"
                :is-initial-open="
                  currentWorkspace?.id &&
                  group.list.map((w: Workspace) => w.id).includes(currentWorkspace.id)
                "
              >
                <template
                  #label="{ isOpen }"
                  v-if="externalList.GOOGLE.content.length > 1"
                >
                  <div class="widget-workspaces__label sub">
                    <IconChevron :direction="isOpen ? 'bottom' : 'right'" />
                    <span>{{ group.account }}</span>
                  </div>
                </template>
                <div
                  :style="{
                    overflow: 'hidden',
                    marginLeft:
                      externalList.GOOGLE.content.length > 1 ? '1rem' : '',
                  }"
                >
                  <div class="options__list with-scroll">
                    <WorkspaceItem
                      v-for="item in group.list"
                      :key="item.id"
                      :item="item"
                      :is-active="currentWorkspace?.id === item.id"
                      @click="onChangeWorkspace(item.id)"
                      @onGoogleCalendarModeChange="
                        onGoogleCalendarModeChange({
                          ...$event,
                          accountEmail: group.account,
                        })
                      "
                    />
                  </div>
                </div>
              </UiDropdownExpandable>
            </div>
          </UiDropdownExpandable>
        </template>
        <template v-if="guestWorkspacesList.length">
          <div class="options__row" style="margin-top: 1rem">
            <span class="disabled-text">{{ $t('workspaces.guest') }}</span>
            <div
              class="options__edit"
              @click="
                isGuestEditOpen = true;
                isWidgetVisible = false;
              "
            >
              {{ $t('workspaces.edit_btn') }}
            </div>
          </div>
          <div
            :class="[
              'options__list',
              'with-scroll',
              { pending: widgetPending },
            ]"
          >
            <WorkspaceItem
              v-for="item in guestWorkspacesList"
              :key="item.id"
              :item="item"
              :is-active="currentWorkspace?.id === item.id"
              @click="onChangeWorkspace(item.id)"
            />
          </div>
        </template>
        <div class="options__control">
          <div class="options__row" @click="onCreateWorkspace">
            <span>{{ $t('workspaces.create_btn') }}</span>
            <img src="@images/workspaces/new.svg" alt="" />
          </div>
          <div
            class="options__row"
            @click="
              isJoinOpen = true;
              isWidgetVisible = false;
            "
          >
            <span>{{ $t('workspaces.join_btn') }}</span>
            <img src="@images/workspaces/join.svg" alt="" />
          </div>
        </div>
      </div>
      <template #reference>
        <div
          :class="['widget-workspaces__reference', { active: isWidgetVisible }]"
        >
          {{ $t('workspaces.title') }}
          <div class="widget-workspaces__reference-current">
            <icon-marker :color="currentWorkspace?.color" />
            <span class="widget-workspaces__reference-name">
              {{ currentWorkspace?.name }}
            </span>
          </div>
          <icon-chevron :direction="isWidgetVisible ? 'top' : 'bottom'" />
        </div>
      </template>
    </el-popover>
    <modal-create-workspace
      :is-open="isCreateOpen"
      @close="isCreateOpen = false"
    />
    <modal-edit-workspaces
      :list="ownWorkspacesList"
      :is-open="isOwnEditOpen"
      @close="isOwnEditOpen = false"
    />
    <modal-edit-guest-workspaces
      :list="guestWorkspacesList"
      :is-open="isGuestEditOpen"
      @close="isGuestEditOpen = false"
    />
    <modal-join-workspace :is-open="isJoinOpen" @close="isJoinOpen = false" />
    <modal-plan-buy
      :title="$t('card_tariff.no_workspaces_label')"
      @close="isPlansModalVisible = false"
      :is-open="isPlansModalVisible"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import IconMarker from '@/components/icon/IconMarker.vue';
import WorkspaceItem from '@/components/workspaces/WorkspaceItem.vue';
import ModalCreateWorkspace from '@/components/modals/Workspaces/Create/ModalCreateWorkspace.vue';
import ModalEditWorkspaces from '@/components/modals/Workspaces/ModalEditWorkspaces.vue';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useUserStore } from '@/stores/user';
import IconChevron from '@/components/icon/IconChevron.vue';
import ModalJoinWorkspace from '@/components/modals/Workspaces/ModalJoinWorkspace.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { useCoachesStore } from '@/stores/coaches/coaches';
import ModalEditGuestWorkspaces from '@/components/modals/Workspaces/ModalEditGuestWorkspaces.vue';
import UiDropdownExpandable from '@/components/ui/UiDropdown/UiDropdownExpandable.vue';
import { GoogleCalendar } from '@/types/integrations';
import { useGoogleAccounts } from '@/stores/integrations/google';
import { Workspace } from '@/types/workspaces';

const workspacesStore = useWorkspaceStore();
const { setCurrent } = workspacesStore;
const {
  currentWorkspace,
  ownWorkspacesList,
  guestWorkspacesList,
  externalList,
  isCreateOpen,
  isJoinOpen,
  isWidgetVisible,
  activeWorkspacesIds,
} = storeToRefs(workspacesStore);

const coachesStore = useCoachesStore();
const { activeCoach } = storeToRefs(coachesStore);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const tariffStore = useTariffStore();
const { workspacesLeft } = storeToRefs(tariffStore);

const googleAccounts = useGoogleAccounts();
const { accountsList } = storeToRefs(googleAccounts);

const isOwnEditOpen = ref(false);
const isGuestEditOpen = ref(false);
const widgetPending = ref(false);
const isPlansModalVisible = ref(false);

watch(
  () => activeWorkspacesIds.value,
  () => {
    if (
      userData.value?.currentCompany &&
      activeWorkspacesIds.value.length &&
      !activeWorkspacesIds.value.includes(userData.value.currentCompany)
    )
      onChangeWorkspace(activeWorkspacesIds.value[0]);
  },
  { deep: true },
);

const onChangeWorkspace = async (id: number) => {
  try {
    widgetPending.value = true;
    await setCurrent(id);
    await userStore.getUserData();
  } catch (e) {
    console.error(e);
  } finally {
    widgetPending.value = false;
  }
};

const onCreateWorkspace = () => {
  workspacesLeft.value > 0
    ? (isCreateOpen.value = true)
    : (isPlansModalVisible.value = true);
  isWidgetVisible.value = false;
};

const onGoogleCalendarModeChange = async (
  update: Partial<GoogleCalendar> & {
    id: GoogleCalendar['id'];
    accountEmail?: string;
  },
) => {
  if (!update.accountEmail) return;
  const connectedId = accountsList.value.find(
    (a) => a.email === update.accountEmail,
  )?.id;
  if (!connectedId) return;
  await googleAccounts.setCalendar(update, connectedId);
  await workspacesStore.fetchList();
};
</script>

<style scoped lang="scss">
.widget-workspaces {
  @include f14;

  &__reference {
    cursor: pointer;
    @include flex-row(1rem);
    align-items: center;
    padding: 1.3rem;
    border: 1px solid transparent;
    border-radius: 1rem;
    width: 35.5rem;
    white-space: nowrap;

    &.active {
      border-color: #dde3ec;
    }

    @include gray-bg-hover;

    & > svg {
      margin-left: auto;
    }

    &-current {
      @include flex-row(1rem);
      justify-content: center;
      align-items: center;
    }

    &-name {
      max-width: 17.5rem;
      @include text-ellipsis;
    }
  }

  &__options {
    padding: 1rem 0;
    width: 100%;
    min-width: 29rem;
  }

  &__label {
    @include flex-row(0.5rem);
    align-items: center;
    @include f16;
    font-weight: 600;
    width: 100%;
    padding: 0.3rem 0.6rem;
    margin-bottom: 0.5rem;

    &.sub {
      @include f14;
      font-weight: 600;
      margin-left: 1rem;

      & > svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      & > div {
        overflow: hidden;
      }
    }

    & > img {
      width: 3rem;
      height: 3rem;
      box-shadow: 0 0 4px 0 rgba(164, 164, 164, 0.25);
      border-radius: 0.8rem;
    }
  }

  &__link {
    margin-left: auto;

    & > img {
      vertical-align: middle;
      padding: 0.5rem;
      @include gray-bg-hover;
      border-radius: 50%;
    }
  }
}

.options {
  @include flex-column;

  .pending {
    opacity: 0.5;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;
  }

  &__row {
    padding: 0.3rem 1rem;
    @include flex-row(1rem);
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    @include flex-row(1rem);
    align-items: center;
    padding: 0.3rem 0.6rem;
    margin-bottom: 0.5rem;
    cursor: pointer;

    & > span {
      @include f16;
      font-weight: 600;
    }

    & > img {
      width: 3rem;
      height: 3rem;
      box-shadow: 2px 2px 5px 0 rgba(116, 116, 116, 0.25);
      border-radius: 0.8rem;
    }
  }

  &__edit {
    @include f14;
    margin-left: auto;
    transition: color 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }

  &__control {
    cursor: pointer;
    padding-top: 2rem;
    border-top: 1px solid #dde3ec;
    @include flex-column(1rem);

    & > * {
      @include gray-bg-hover;
    }
  }

  &__list {
    @include flex-column;
    opacity: 1;
    max-height: 20vh;
    overflow: auto;
  }
}
</style>
