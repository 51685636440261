import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { TaskFilters } from '@/types/tasks';

export class ReportsService extends ApiService {
  getTasksReport(filters: TaskFilters) {
    const { taskDTO, ...params } = filters;
    return axiosInstance.post<Blob>(ENDPOINTS.reports.tasks, taskDTO, {
      params,
      responseType: 'blob',
    });
  }
}
