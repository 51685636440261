import { RouteRecordRaw } from 'vue-router';

const ResetPassView = () => import('@/views/reset-pass/reset-pass.vue');
const ResetPassViewIndex = () => import('@/views/reset-pass/index.vue');
const ResetPassViewCreate = () => import('@/views/reset-pass/create.vue');
const ResetPassViewForgot = () => import('@/views/reset-pass/forgot.vue');
const ResetPassViewSuccess = () => import('@/views/reset-pass/success.vue');

export default {
  path: '/reset-pass',
  name: 'ResetPassView',
  component: ResetPassView,
  meta: {
    layout: 'auth',
    public: true,
  },
  children: [
    {
      path: '',
      name: 'ResetPassViewIndex',
      component: ResetPassViewIndex,
    },
    {
      path: 'forgot',
      name: 'ResetPassViewForgot',
      component: ResetPassViewForgot,
    },
    {
      path: 'create',
      name: 'ResetPassViewCreate',
      component: ResetPassViewCreate,
      beforeEnter: (to, from, next) => {
        if (to.query.uuid) {
          next();
        } else {
          next('/');
        }
      },
    },
    {
      path: 'success',
      name: 'ResetPassViewSuccess',
      component: ResetPassViewSuccess,
      beforeEnter: (to, from, next) => {
        if (from.name === 'ResetPassViewCreate') {
          next();
        } else {
          next('/');
        }
      },
    },
  ],
} as RouteRecordRaw;
