import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { AnalyticsFilters } from '@/types/analytics';

export class AnalyticsService extends ApiService {
  getSelf(dateFrom: string, dateTo: string, analyticFilters = '') {
    return axiosInstance.get<any>(ENDPOINTS.analytics.getSelf, {
      params: {
        dateFrom,
        dateTo,
        analyticFilters,
      },
    });
  }

  getTeam(filters: AnalyticsFilters) {
    const { teamAnalyticDTO, ...params } = filters;
    return axiosInstance.post<any>(
      ENDPOINTS.analytics.getTeam,
      teamAnalyticDTO,
      { params },
    );
  }

  getLineGraph(filters: AnalyticsFilters) {
    const { teamAnalyticDTO, ...params } = filters;
    return axiosInstance.post<any>(
      ENDPOINTS.analytics.lineGraph,
      teamAnalyticDTO,
      { params },
    );
  }
}
