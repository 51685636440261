<template>
  <div
    class="block-drop-area"
    @dragenter.prevent="onDragEnterHandler"
    @dragover.prevent="onDragEnterHandler"
    @dragleave.prevent="onDragLeaveHandler"
  >
    <slot
      :isActiveDropArea="data.isActiveDropArea"
      :setInactiveDropArea="setInactiveDropArea"
      :setActiveDropArea="setActiveDropArea"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';

const data = reactive({
  isActiveDropArea: false,
});

let enterTarget: EventTarget;

const onDragLeaveHandler = (e: Event) => {
  if (enterTarget === e.target) {
    data.isActiveDropArea = false;
  }
};
const onDragEnterHandler = (e: Event) => {
  if (e.target) {
    enterTarget = e.target;
  }
  data.isActiveDropArea = true;
};

const setInactiveDropArea = () => {
  data.isActiveDropArea = false;
};

const setActiveDropArea = () => {
  data.isActiveDropArea = true;
};

defineExpose({
  setInactiveDropArea,
  setActiveDropArea,
});
</script>

<style lang="scss">
.block-drop-area {
  display: flex;
  flex-direction: column;
}
</style>
