<script setup lang="ts">
import UiAudioPlayer from '@/components/ui/UIAudioPlayer.vue';
import { storeToRefs } from 'pinia';
import { useNoteToTaskStore } from '@/stores/notes/useNoteToTask';

defineEmits<{
  (e: 'schedule'): void;
}>();

const noteToTaskStore = useNoteToTaskStore();
const { audioFile, isAudioLoading, isCreating, isStateValid } =
  storeToRefs(noteToTaskStore);
</script>

<template>
  <div class="note-task-card-modal-footer">
    <div
      class="note-task-card-modal-footer__player"
      v-skeleton="isAudioLoading"
    >
      <UiAudioPlayer v-if="audioFile" :audio="audioFile" />
    </div>
    <ui-button
      @click="noteToTaskStore.end"
      :is-disabled="!isStateValid"
      :is-loading="isCreating"
      class="note-task-card-modal-footer__schedule"
    >
      Create
    </ui-button>
  </div>
</template>

<style scoped lang="scss">
.note-task-card-modal-footer {
  @include flex-row(2rem);
  justify-content: space-between;
  align-items: center;

  &__player {
    border: 1px solid #a3c0f9;
    border-radius: 8px;
  }

  &__schedule {
    @include flex-row(1rem);
    align-items: center;
    max-width: 23rem;
    width: 100%;
  }
}
</style>
