import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { Workspace, WorkspaceGroup } from '@/types/workspaces';
import services from '@/api/services';
import { numberToColor } from '@/utils/common';
import { useUserStore } from '@/stores/user';
import {
  subordinatesMappingToView,
  SubordinateViewType,
} from '@/utils/subordinates';
import { useGoogleAccounts } from '@/stores/integrations/google';
import { TokenType } from '@/types/integrations';
import { calendarConfig } from '@/utils/calendar';
import { useTariffStore } from '@/stores/tariffs/tariffs';

export const useWorkspaceStore = defineStore('workspace', () => {
  const pending = ref(false);

  const isWidgetVisible = ref(false);
  const isCreateOpen = ref(false);
  const isJoinOpen = ref(false);
  const isNoMoreWorkspacesModalVisible = ref(false);

  const tariffStore = useTariffStore();
  const { workspacesLeft } = storeToRefs(tariffStore);

  const onCreateWorkspace = () => {
    workspacesLeft.value > 0
      ? (isCreateOpen.value = true)
      : (isNoMoreWorkspacesModalVisible.value = true);
  };

  const list = ref<Workspace[]>([]);
  const users = ref<SubordinateViewType[]>([]);

  const googleAccounts = useGoogleAccounts();
  const { googleAccountsMapping } = storeToRefs(googleAccounts);

  const userStore = useUserStore();
  const { userData } = storeToRefs(userStore);

  const activeWorkspacesIds = computed(() =>
    list.value
      .filter((w) => {
        const external = w.externalCalendarItem;
        return (
          (!external && ['CREATED', 'UPDATED'].includes(w.status)) ||
          (external && ['CONFIRMED', 'CREATE'].includes(external.status))
        );
      })
      .map((w) => w.id),
  );

  const currentWorkspace = computed(() =>
    list.value.find((c) => c.id === userData.value?.currentCompany),
  );

  const isWorkspaceOwner = computed(
    () => currentWorkspace.value?.creatorId === userData.value?.id,
  );

  const externalList = computed(() =>
    list.value.reduce((groups, w) => {
      if (
        !w.externalCalendarItem ||
        ['DELETE', 'NOT_VISIBLE'].includes(w.externalCalendarItem.status)
      )
        return groups;
      const type = w.externalCalendarItem.typeOfCalendar;
      if (!type) return groups;
      const calendarId = w.externalCalendarItem.id;
      const account = Object.entries(googleAccountsMapping.value).find(
        ([, { ids, status }]) =>
          status === 'ACTIVE' && ids?.includes(calendarId),
      )?.[0];
      if (!account) return groups;

      const content = groups[type]?.content.find((c) => c.account === account);
      content
        ? content.list.push(w)
        : (groups[type] = {
            config: calendarConfig(type),
            content: [...(groups[type]?.content || []), { account, list: [w] }],
          });
      return groups;
    }, {} as Record<TokenType, WorkspaceGroup>),
  );

  const internalList = computed(() => ({
    config: calendarConfig(),
    content: {
      account: 'Voiset workspaces',
      list: list.value.filter((w) => !w.externalCalendarItem),
    },
  }));

  const ownWorkspacesList = computed<Workspace[]>(() =>
    list.value.filter(
      (w) => !w.externalCalendarItem && w.creatorId === userData.value?.id,
    ),
  );

  const guestWorkspacesList = computed<Workspace[]>(() =>
    list.value.filter(
      (w) => !w.externalCalendarItem && w.creatorId !== userData.value?.id,
    ),
  );

  const fetchList = async () => {
    try {
      pending.value = true;
      const { data } = await services.workspaces.list();
      list.value = data
        .filter((c) => c.status !== 'DELETED')
        .map((c) => Object.assign(c, { color: numberToColor(c.id) }));
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const create = async (name: string) => {
    return await services.workspaces.create({ name });
  };

  const modify = async (params: Partial<Workspace>) => {
    return await services.workspaces.modify(params);
  };

  const deleteCompany = async (id: number) => {
    try {
      pending.value = true;
      await services.workspaces.delete(id);
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const setCurrent = async (companyId: number) => {
    try {
      pending.value = true;
      await services.workspaces.setCurrent({ companyId });
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const addUser = async (params: { userId: number; companyId: number }) => {
    try {
      pending.value = true;
      await services.workspaces.addUser(params);
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const removeUser = async (params: { userId: number; companyId: number }) => {
    try {
      pending.value = true;
      await services.workspaces.removeUser(params);
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const fetchUsers = async () => {
    try {
      pending.value = true;
      const response = await services.positions.getUsers({
        pageSize: 1000,
      });
      users.value = response.data.content.map(subordinatesMappingToView);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      pending.value = false;
    }
  };

  const $reset = () => {
    list.value = [];
    users.value = [];
    isCreateOpen.value = false;
    isJoinOpen.value = false;
    isNoMoreWorkspacesModalVisible.value = false;
  };

  return {
    list,
    users,
    activeWorkspacesIds,
    ownWorkspacesList,
    guestWorkspacesList,
    internalList,
    externalList,
    currentWorkspace,
    isWorkspaceOwner,
    pending,
    isJoinOpen,
    isCreateOpen,
    isNoMoreWorkspacesModalVisible,
    isWidgetVisible,
    onCreateWorkspace,
    fetchList,
    create,
    modify,
    deleteCompany,
    setCurrent,
    addUser,
    removeUser,
    fetchUsers,
    $reset,
  };
});
