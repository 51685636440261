<template>
  <modal-notification
    :title="`${$t('popups.user_view.delete_user')}?`"
    class="notification-modal"
    :is-open="isOpen"
    :message="$t('popups.user_view.delete_user_message')"
    width="30rem"
    @on-close="$emit('on-close')"
  >
    <template #footer>
      <div class="notification-modal__btns">
        <ui-button plain @click="onDeleteUser">
          {{ $t('popups.user_view.delete_btn') }}
        </ui-button>
        <ui-button @click="$emit('on-close')">
          {{ $t('popups.user_view.cancel_btn') }}
        </ui-button>
      </div>
    </template>
  </modal-notification>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import { ElNotification } from 'element-plus';
import UiButton from '@/components/control/UiButton.vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { toRefs } from 'vue';
import { ISubordinate } from '@/types/subordinate';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const { t } = useI18n();
const { removeSubordinateFromCompany } = useSubordinatesStore();

const props = defineProps<{
  isOpen: boolean;
  user?: (ISubordinate & Record<string, any>) | null;
}>();

const emits = defineEmits(['on-close', 'on-delete']);

const { user } = toRefs(props);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const onDeleteUser = async () => {
  try {
    if (!userData?.value?.currentCompany || !user.value?.id) return;
    await removeSubordinateFromCompany({
      userId: user.value.id,
      companyId: userData.value.currentCompany,
    });
    ElNotification.success({
      message: t('popups.user_view.delete_user-success'),
      offset: 80,
    });
  } catch (e) {
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    emits('on-delete');
  }
};
</script>

<style scoped lang="scss"></style>
