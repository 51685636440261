import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import {
  IInvitation,
  IInvitationFilters,
  InternalInvitation,
} from '@/types/invitation';
import { Pageable } from '@/types/pagination';
import { Workspace } from '@/types/workspaces';
import { ISubordinate } from '@/types/subordinate';

export class InvitationService extends ApiService {
  finishInviteRegistration(params: {
    invitationCode: string;
    lastName: string;
    name: string;
    password: string;
    web?: boolean;
  }) {
    params.web = true;
    return axiosInstance.get<ISubordinate>(
      ENDPOINTS.invitation.finishInviteRegistration,
      {
        params,
      },
    );
  }

  createInvite(params: {
    email: string;
    companyId?: number;
    positionName?: string;
    scheduleTime?: string;
  }) {
    return axiosInstance.get<any>(ENDPOINTS.invitation.createInvite, {
      params,
    });
  }

  confirmLink(params: { invitationCode: string }) {
    return axiosInstance.get<any>(ENDPOINTS.invitation.confirmLink, { params });
  }

  revokeInvite(params: { invitationCode: string }) {
    return axiosInstance.get<string>(ENDPOINTS.invitation.revokeInvite, {
      params,
    });
  }

  getInternalInvitations() {
    return axiosInstance.get<InternalInvitation[]>(
      ENDPOINTS.invitation.userInvitations,
    );
  }

  setInvitationStatus(params: { invitationCode: string; result: boolean }) {
    return axiosInstance.post<Workspace>(
      ENDPOINTS.invitation.userInvitations,
      null,
      {
        params,
      },
    );
  }

  findAll(filters: IInvitationFilters) {
    const { filters: filtersBody, ...params } = filters;

    return axiosInstance.post<Pageable<IInvitation>>(
      ENDPOINTS.invitation.findAll,
      filtersBody.status,
      {
        params,
      },
    );
  }
}
