import { useWorkspaceStore } from '@/stores/workspaces';
import { watchPausable } from '@vueuse/core';
import { onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { sleep } from '@/utils/common';

interface Params {
  mount: boolean;
  change: boolean;
  triggerOnFirstChange: boolean;
}

const defaults: Params = {
  mount: true,
  change: true,
  triggerOnFirstChange: false,
};

export const onBeforeMountAndWorkspaceChange = (
  cb: () => Promise<unknown>,
  params: Partial<Params> = {},
) => {
  const config = Object.assign(defaults, params);

  const hasFirstChange = ref(false);
  const workspacesStore = useWorkspaceStore();
  const { currentWorkspace } = storeToRefs(workspacesStore);

  const { pause, resume } = watchPausable(
    () => currentWorkspace.value?.id,
    (val) => {
      if (!val) return;
      if (!params.triggerOnFirstChange && !hasFirstChange.value)
        hasFirstChange.value = true;
      else cb();
    },
    { immediate: false },
  );
  pause();

  onBeforeMount(async () => {
    if (config.change) {
      resume();
    }
    if (config.mount) {
      await cb();
      await sleep(300);
    }
  });
};
