type EmitFn = (...args: any[]) => unknown;

export class EventEmitter {
  constructor(public _events: Record<string, EmitFn[]> = {}) {}

  subscribe(eventName: string, callback: EmitFn) {
    !this._events[eventName] && (this._events[eventName] = []);
    this._events[eventName].push(callback);
    return this;
  }

  unsubscribe(eventName: string, callback: EmitFn) {
    this._events[eventName] = this._events[eventName].filter(
      (eventCallback) => callback !== eventCallback,
    );
    return this;
  }

  emit(eventName: string, args?: unknown) {
    const event = this._events[eventName];
    event && event.forEach((callback) => callback.call(null, args));
    return this;
  }

  clear(eventName: string) {
    this._events[eventName] = [];
    return this;
  }

  reset() {
    this._events = {};
    return this;
  }
}
