import { useUserStore } from '@/stores/user';
import services from '@/api/services';
import { getRecognitionConfig } from '@/configs/speechmatics';
import { EventEmitter } from '@/utils/emit';

export class SpeechMaticsService {
  private readonly url: string;
  private socket: any;
  isConnected: boolean;
  path?: string = '';
  recognitionStarted: boolean;
  jwt: string;
  emitter: EventEmitter;
  constructor({
    url,
  }: {
    url: string;
    path?: string;
    configs?: Record<string, any>;
  }) {
    this.url = url;
    this.isConnected = false;
    this.recognitionStarted = false;
    this.jwt = '';
    this.emitter = new EventEmitter();
  }

  get socketInstance() {
    return this.socket;
  }

  async connect(params: { locale?: string; token: string }) {
    const userStore = useUserStore();
    let currentLocale =
      userStore.userData?.locale.abbreviation.toLocaleLowerCase() as string;

    if (params.locale) currentLocale = params.locale;

    if (!this.jwt) {
      const { data: dataJwt } = await services.voice.getSpeechToken(
        params.token,
      );
      this.jwt = dataJwt.key_value;
    }

    return new Promise((resolve) => {
      this.socket = new WebSocket(
        `${this.url}/${currentLocale}?jwt=${this.jwt}`,
      );

      this.socket.onopen = () => {
        this.isConnected = true;
        this.socket.send(JSON.stringify(getRecognitionConfig(currentLocale)));
      };
      this.socket.onmessage = (e: any) => {
        const data = JSON.parse(e.data);
        if (data.message === 'RecognitionStarted') {
          resolve(this.isConnected);
        }
        ['AddPartialTranscript', 'AddTranscript'].includes(data.message) &&
          this.emitter.emit(data.message, data.metadata.transcript);
      };

      this.socket.onclose = (event: any) => {
        event.type === 'close' && this.emitter.emit('onClose');
        if (event.wasClean) {
          console.log('SOCKET CLOSE');
        } else {
          console.log('[close] Соединение прервано');
        }
      };
    });
  }

  close() {
    this.isConnected && this.socket.close();
  }
}
