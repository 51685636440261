import { createUrl } from '@/utils/url';

const tasksUrl = createUrl('web/task');

const findTasks = tasksUrl('filter/findTasks/period');
const createTask = tasksUrl('create');
const updateTask = (id: string | number) => tasksUrl(`update/${id}`);
const getTask = (id?: string) => tasksUrl(`${id || ''}`);
const updateStatus = (id?: number) => tasksUrl(`changeStatus/${id || ''}`);
const monthTask = tasksUrl('filter/fullMonthTask');
const todoCount = 'task/todo';
const tasksCount = (id: string | number) => tasksUrl(`users/${id}`);
const getMonthAgendaByPeriod = tasksUrl('calendar/period');

export const tasks = {
  findTasks,
  createTask,
  updateTask,
  getTask,
  updateStatus,
  monthTask,
  todoCount,
  tasksCount,
  getMonthAgendaByPeriod,
};
