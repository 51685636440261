<script setup lang="ts">
import NoteTransformStepCard from '@/components/notes/transform/steps/NoteTransformStepCard.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import NotesListWorkspace from '@/components/notes/list/NotesListWorkspace.vue';
import { storeToRefs } from 'pinia';
import { useNotesByWorkspacesStore } from '@/stores/notes/useNotesByWorkspaces';

const notesTransformStore = useNotesTransformStore();
const notesByWorkspaces = useNotesByWorkspacesStore();
const { mode } = storeToRefs(notesTransformStore);
const { groups } = storeToRefs(notesByWorkspaces);

const handleEnd = () => {
  notesTransformStore.planners = notesByWorkspaces.end();
  notesTransformStore.goToNextStep();
};
</script>

<template>
  <NoteTransformStepCard stretch>
    <template #header>
      <p>{{ $t(`notes.transform.workspace.title.${mode}`) }}</p>
      <small> {{ $t(`notes.transform.workspace.description.${mode}`) }}</small>
    </template>
    <div class="notes-transform-step-workspace">
      <NotesListWorkspace
        v-for="group in groups"
        :key="group.workspace.id"
        :workspace="group.workspace"
        :mode="mode"
        v-model="group.notes"
      />
    </div>
    <template #footer>
      <div class="notes-transform-step-workspace__controls">
        <ui-button @click="handleEnd">
          {{ $t('notes.action.next') }}
        </ui-button>
      </div>
    </template>
  </NoteTransformStepCard>
</template>

<style scoped lang="scss">
.notes-transform-step-workspace {
  display: flex;
  justify-content: start;
  overflow-x: auto;
  overflow-y: hidden;

  & > * {
    flex: 0 0 370px;
  }
  height: 100%;
  align-items: start;
  gap: 2.4rem;
  margin-left: -3rem;
  width: calc(100% + 6rem);
  padding: 1.2rem 3rem;

  &__controls {
    @include flex-row;
    justify-content: end;
    button {
      min-width: 110px;
    }
  }
}
</style>
