<template>
  <modal-notification
    width="45rem"
    :is-open="isOpen"
    :message="$t('billings.change_subscription.message')"
    @on-close="onClose"
    :no-btns="isSubscribeSuccess"
  >
    <template #header>
      <div class="change-plan-modal__title" v-if="!isSubscribeSuccess">
        {{ $t('billings.change_subscription.title') }}
        <ui-plan-title
          size="medium"
          :type="tariffsView.content.name"
          color="#778BEE"
        />
      </div>
    </template>
    <template #default>
      <transition name="blur" appear mode="out-in">
        <div v-if="subscribePending" class="change-plan-modal__notification">
          <ui-spinner style="height: 6rem" />
          <span>
            {{ $t('billings.change_subscription.message-processed') }}
          </span>
          <span>
            {{ $t('billings.change_subscription.message-precessed-note') }}
          </span>
        </div>
        <div
          class="change-plan-modal__notification"
          v-else-if="!subscribePending && isSubscribeSuccess"
        >
          <img src="@images/success.svg" alt="" style="height: 6rem" />
          <span>{{ $t('billings.change_subscription.message-success') }}</span>
          <span>
            {{ $t('billings.change_subscription.message-success-note') }}
          </span>
        </div>
        <div class="change-plan-modal__content" v-else>
          <span>{{ $t('billings.change_subscription.message') }}</span>
          <span>{{ $t('billings.change_subscription.message-note') }}</span>
        </div>
      </transition>
    </template>
    <template #footer>
      <div class="change-plan-modal__btns">
        <ui-button
          @click="$emit('close')"
          plain
          :is-disabled="subscribePending"
        >
          {{ $t('billings.change_subscription.back') }}
        </ui-button>
        <ui-button :is-disabled="subscribePending" @click="onChangePlan">
          {{ $t('billings.change_subscription.change') }}
        </ui-button>
      </div>
    </template>
  </modal-notification>
</template>

<script setup lang="ts">
import UiButton from '@/components/control/UiButton.vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import UiPlanTitle from '@/components/ui/UiPlan/UiPlanTitle.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useStripe } from '@/hooks/useStripe';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { sleep } from '@/utils/common';

const props = defineProps<{ isOpen?: boolean; paymentId?: string }>();
const emits = defineEmits(['close', 'close-with-success', 'close-with-fail']);

const subscribePending = ref(false);
const isSubscribeSuccess = ref(false);

const { t } = useI18n();
const billingStore = useTariffStore();
const { tariffsView } = storeToRefs(billingStore);

const { onSubscribe } = useStripe();

const onChangePlan = async () => {
  if (!props.paymentId) return;
  try {
    subscribePending.value = true;
    isSubscribeSuccess.value = !!(await onSubscribe(props.paymentId)).data;
    await sleep(1000);
    await billingStore.fetchUserTariff();
  } catch (e) {
    ElNotification.error({
      message: t('some_error'),
      offset: 80,
    });
    emits('close-with-fail');
    console.error(e);
  } finally {
    subscribePending.value = false;
  }
};

const onClose = async () => {
  setTimeout(() => (isSubscribeSuccess.value = false), 1000);
  emits(isSubscribeSuccess.value ? 'close-with-success' : 'close');
};
</script>

<style scoped lang="scss">
.change-plan-modal {
  &__title {
    @include flex-row(1rem);
    align-items: center;
    justify-content: center;
  }

  &__content {
    @include flex-column(2rem);
    @include f14;
  }

  &__btns {
    @include flex-row(1.2rem);
    align-items: center;
    justify-content: flex-end;
    padding-top: 2rem;

    & > * {
      min-width: 30%;
    }
  }

  &__notification {
    @include flex-column(1.6rem);
    align-items: center;
    justify-content: center;

    & > span {
      &:first-of-type {
        @include f16;
      }
    }
  }
}
</style>
