import { Note } from '@/types/notes';
import { dragAndDrop } from '@formkit/drag-and-drop/vue';
import { ComputedRef, Ref } from 'vue';
import { multiDrag } from '@/utils/drag/multi-drag';
import { dragChosenPlugin } from '@/utils/drag/drag-chosen-plugin';
import { useTouchDevice } from '@/hooks/useTouchDevice';

export const useNoteToTaskDrag = ({
  el,
  list,
  isSelected,
  selection,
  onStart,
  onEnd,
  group,
}: {
  el: Ref<HTMLElement | undefined>;
  list: Ref<Note[]>;
  isSelected: ComputedRef<(num: number) => boolean>;
  selection: Ref<number[]>;
  onStart?: () => void;
  onEnd?: () => void;
  group?: string;
}) => {
  const { isTouchDevice } = useTouchDevice();

  return dragAndDrop<Note>({
    parent: el,
    values: list,
    group: group || 'note-to-task',
    dragHandle: '.note-item__card',
    dropZoneClass: 'drag-ghost',
    touchDropZoneClass: 'drag-ghost',
    longTouch: true,
    longTouchTimeout: 300,
    threshold: {
      vertical: 0,
      horizontal: 0,
    },
    scrollBehavior: {
      x: 0.8,
      y: 0.8,
      scrollOutside: true,
    },
    plugins: [
      ...(!isTouchDevice.value
        ? [
            dragChosenPlugin({
              handleEnd() {
                selection.value = [];
              },
            }),
          ]
        : []),
      multiDrag<Note>({
        dropZoneClass: 'drag-ghost',
        touchDropZoneClass: 'drag-ghost',
        sortable: true,
        selections: (values) => {
          return values.filter((v) => isSelected.value(v.id));
        },
        onStart() {
          onStart?.();
        },
        onEnd() {
          selection.value = [];
          onEnd?.();
        },
      }),
    ],
  });
};
