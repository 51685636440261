<script setup lang="ts">
import IconMarker from '@/components/icon/IconMarker.vue';
import { computed } from 'vue';
import { Workspace } from '@/types/workspaces';

type Props = { workspace: Workspace };
const props = defineProps<Props>();

const syncState = computed(() =>
  props.workspace.id
    ? { value: true, color: 'var(--color-success)', text: 'Sync on' }
    : { value: false, color: '#1A2A39', text: 'Sync off' },
);
</script>

<template>
  <div class="calendar-item" @click="$emit('onSyncChange', !syncState.value)">
    <IconMarker :color="workspace.color" />
    <span>{{ workspace.name }}</span>
    <div>
      <el-switch class="green" :modelValue="syncState.value" />
      <span :style="{ color: syncState.color }">{{ syncState.text }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.calendar-item {
  @include flex-row(1rem);
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  & > div {
    @extend .calendar-item;

    & > span {
      transition: all 0.15s ease-in-out;
    }
  }

  & > *:last-child {
    margin-left: auto;
  }
}
</style>
