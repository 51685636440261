<template>
  <div class="popup-share__container">
    <div class="popup-share__create" @click="copyText">
      <ui-input v-model="linkInput" readonly :class="{ success: isCopied }">
        <template #action>
          <icon-copy class="popup-share__create-copy" />
        </template>
      </ui-input>
    </div>
    <!--    <p-->
    <!--      v-if="linkInput.length"-->
    <!--      class="popup-share__upgrade"-->
    <!--      v-html="t('view_modal_task.shareTask.upgrade_plan')"-->
    <!--    />-->
    <!--    <div v-else-if="linkInput.length" class="popup-share__main">-->
    <!--      <span class="popup-share__desc">-->
    <!--        {{ t('view_modal_task.shareTask.desc') }}-->
    <!--      </span>-->
    <!--      <UISmsShareTask @on-close="$emit('onClose')" />-->
    <!--    </div>-->
  </div>
</template>

<script lang="ts" setup>
import UiInput from '../control/UiInput.vue';
import services from '@/api/services';
import { copyToClipboard } from '@/utils/copyToClipboard';
import IconCopy from '../icon/IconCopy.vue';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';
import { useI18n } from 'vue-i18n';
import { ElNotification } from 'element-plus';

defineProps<{ fullscreen?: boolean; isOpen?: boolean }>();

const { t } = useI18n();
const { activeTask } = storeToRefs(useModalViewTaskStore());

const isLoading = ref(false);
const isCopied = ref(false);
const linkInput = ref('');

const createLink = async () => {
  try {
    isLoading.value = true;
    if (activeTask.value?.id) {
      const { data } = await services.share.create({
        taskId: activeTask.value?.id,
      });
      linkInput.value = data;
    }
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
  }
};

const copyText = () => {
  if (!linkInput.value) return;
  const result = copyToClipboard(linkInput.value);
  if (result) {
    isCopied.value = true;
    ElNotification.success({
      message: t('task_sharing.link_copied'),
      offset: 80,
    });
  }
};

onMounted(async () => await createLink());
</script>

<style lang="scss" scoped>
.popup-share {
  &__create {
    &-btn {
      height: 3.8rem;
      margin-right: 1rem;
      flex-shrink: 0;
      @include f12;
      min-width: 10rem;
      padding: 0 1rem;
    }

    &-copy {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }
  }

  &__main {
    @include flex-column(2rem);
    margin-top: 2rem;
  }

  &__upgrade {
    @include f14;
    margin-top: 2rem;
    word-break: break-word;
  }

  &__desc {
    @include f14;
    display: inline-block;
  }

  &__message {
    :deep(textarea) {
      @include f12;
    }
  }

  &__button {
    width: 100%;
  }
}

:deep(.popup-share__upgrade-link) {
  color: #778bee;
}
</style>
