<template>
  <div>
    <modal-notification
      v-bind="props"
      class="notification-modal"
      @on-close="$emit('close')"
      width="45rem"
    >
      <template #footer>
        <slot name="footer">
          <div class="notification-modal__btns">
            <ui-button plain @click="$emit('on-confirm')">
              <icon-trash color="#EC7975" style="max-height: 1.6rem" />
              {{ $t('workspaces.delete.confirm') }}
            </ui-button>
            <ui-button @click="$emit('close')">
              {{ $t('workspaces.delete.cancel') }}
            </ui-button>
          </div>
        </slot>
      </template>
    </modal-notification>
  </div>
</template>

<script setup lang="ts">
import ModalNotification from '@/components/modals/ModalNotification.vue';
import IconTrash from '@/components/icon/IconTrash.vue';

const props = defineProps<{
  isOpen: boolean;
  message: string;
  title: string;
}>();
defineEmits(['on-confirm', 'close']);
</script>

<style scoped lang="scss"></style>
