<template>
  <div class="ui-filter">
    <div class="ui-filter__header">
      <span>{{ label }}</span>
      <div class="ui-filter__clear" @click="resetFilters">
        {{ $t('tasks.filters.clear') }}
      </div>
    </div>
    <div class="ui-filter-dropdown">
      <div
        class="ui-filter-dropdown__row"
        v-for="option in options"
        :key="option?.label"
      >
        <el-checkbox-group
          :model-value="filtersState?.[filterField]"
          @update:model-value="setFilter?.(filterField, $event)"
        >
          <el-checkbox :model-value="option.value" :value="option.value">
            <ui-colorfilled-text
              v-if="filterField === 'priorityList'"
              badge
              :type="option.value as TTaskPriorityItem"
              style="width: 70%"
            >
              {{ option.label }}
            </ui-colorfilled-text>
            <template
              v-else-if="['statusList', 'status'].includes(filterField)"
            >
              <img
                width="16"
                :src="
                  option?.icon ||
                  require(`@images/statuses/${String(
                    option.value,
                  ).toLowerCase()}.svg`)
                "
                alt="icon"
              />
              {{ getLabel(option.label) || option.value }}
            </template>
            <span v-else>{{ getLabel(option.label) || option.value }}</span>
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue';
import { TableFilter } from '@/types/common';
import { useI18n } from 'vue-i18n';
import { TTaskPriorityItem } from '@/consts';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';

const { t, te } = useI18n();

const getLabel = (label?: string) => (label && te(label) ? t(label) : label);

const props = defineProps<TableFilter>();

const filtersState: Record<string, any> | undefined = inject('filtersState');
const setFilter: ((...args: any[]) => void) | undefined = inject('setFilter');

const resetFilters = () => {
  const filter = filtersState?.[props.filterField];
  setFilter && setFilter(props.filterField, filter instanceof Array ? [] : '');
};
</script>

<style scoped lang="scss"></style>
