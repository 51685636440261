import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { Pageable } from '@/types/pagination';
import { Comment } from '@/types/comment';

// interface

export class CommentService extends ApiService {
  addComments(id: number, text: string) {
    return axiosInstance.post(
      ENDPOINTS.comment.createComment(id),
      text.replace(/"/g, ''),
    );
  }

  getComments(
    id: number,
    params: { pageNum: number; pageSize: number } = {
      pageNum: 1,
      pageSize: 10,
    },
  ) {
    const { pageNum, pageSize } = params;
    return axiosInstance.get<Pageable<Comment>>(ENDPOINTS.comment.getComments, {
      params: {
        pageNum,
        pageSize,
        taskId: id,
        order: 'DESC',
      },
    });
  }
}
