import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';

export class localeService extends ApiService {
  getLocaleVoice() {
    return axiosInstance.get(ENDPOINTS.locale.getLocaleVoice);
  }

  getLocaleUiList() {
    return axiosInstance.get(ENDPOINTS.locale.getLocaleUiList);
  }
}
