export function formatBytes(bytes: number, decimals = 0) {
  if (!+bytes || bytes < 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['BYTES', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export async function dataUrlToFile(dataUrl: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const type = dataUrl.split(';')[0].split(':')[1];
  const ext = type.split('/')[1];
  const blob: Blob = await res.blob();

  return new File([blob], `image.${ext}`, { type });
}
