<script setup lang="ts">
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { computed, onBeforeMount } from 'vue';
import NoteTransformGenerateAnimation from '@/components/notes/transform/NoteTransformGenerateAnimation.vue';
import NoteTransformGenerateCard from '@/components/notes/transform/NoteTransformGenerateCard.vue';
import { storeToRefs } from 'pinia';
import IconClose from '@/components/icon/IconClose.vue';
import NoteCardModalFooter from '@/components/notes/card/NoteCardModalFooter.vue';
import NoteCardBody from '@/components/notes/card/NoteCardBody.vue';

const notesTransformStore = useNotesTransformStore();
const { initialNotes, mode } = storeToRefs(notesTransformStore);

const note = computed(() => initialNotes.value[0]);

onBeforeMount(async () => {
  await notesTransformStore.scheduleTasks();
  setTimeout(() => {
    if (notesTransformStore.currentStep === 'generate') {
      if (mode.value === 'one') notesTransformStore.goToNextStep();
      else notesTransformStore.end(false);
    }
  }, 3000);
});
</script>

<template>
  <div
    class="note-transform-step-generate"
    :style="{ width: mode === 'many' ? 'auto' : '100%' }"
  >
    <NoteTransformGenerateAnimation v-if="mode === 'many'" height="180px" />
    <div class="note-transform-step-generate__one" v-else>
      <NoteTransformGenerateCard>
        <template #title>{{ $t('notes.one') }}</template>
        <template #actions>
          <IconClose />
        </template>
        <NoteCardBody
          :title="note?.title ?? ''"
          :description="note?.description ?? ''"
          readonly
        />
        <template #footer>
          <NoteCardModalFooter readonly />
        </template>
      </NoteTransformGenerateCard>
      <NoteTransformGenerateCard>
        <template #title>{{ $t('notes.transform.generate.created') }}</template>
        <div class="note-transform-step-generate__animation">
          <p>{{ $t('notes.transform.generate.moving') }}</p>
          <NoteTransformGenerateAnimation height="180px" />
        </div>
      </NoteTransformGenerateCard>
    </div>
  </div>
</template>

<style scoped lang="scss">
.note-transform-step-generate {
  max-width: 1276px;
  margin: 0 auto;

  &__one {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 2rem;
  }

  &__animation {
    margin-top: 5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
  }
}
</style>
