<template>
  <router-link :to="to" v-slot="{ isActive }" custom>
    <router-link
      class="nav-item"
      :to="to"
      :class="{ 'router-link-active': $route.path.includes(to) }"
      @click="$emit('closeSidebar')"
      v-bind="$attrs"
    >
      <component
        class="nav-item__icon"
        :is-active="isActive || $route.path.includes(to)"
        :is="icon"
      />
      <span class="nav-item__text" v-if="showLabel">{{ label }}</span>
    </router-link>
  </router-link>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
type Props = {
  to: string;
  iconName: string;
  label?: string;
  showLabel?: boolean;
};
const props = withDefaults(defineProps<Props>(), { label: '' });
defineEmits(['closeSidebar']);

const icon = defineAsyncComponent(
  () => import('@/components/icon/nav/Icon' + props.iconName),
);
</script>

<style lang="scss" scoped>
.nav {
  &-item {
    @include flex-row(1rem);
    align-items: center;
    width: 100%;
    padding: 0.8rem 2.6rem;
    transition: all 0.2s linear;

    &:hover,
    &.router-link-active {
      .nav-item {
        &__icon {
          background: var(--color-primary);
          color: #f7f7f7;
        }
        &__text {
          color: var(--color-primary);
        }
      }
    }

    &__icon {
      width: 4rem;
      height: 4rem;
      padding: 0.8rem;
      border-radius: 50%;
      background: #fcfcfc;
      flex: 0 0 auto;
      transition: background 0.15s ease-in-out;
      box-shadow: 0 0.4rem 2rem rgba(#ababab40, 0.25);
      color: #1a2a39;
    }

    &__text {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 3rem;
      @include text-ellipsis;
    }
  }
}
</style>
