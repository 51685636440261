import { RouteRecordRaw } from 'vue-router';

const ScheduleView = () => import('@/views/schedule.vue');
const ScheduleIndex = () => import('@/views/schedule/index.vue');

export default {
  path: '/schedule',
  component: ScheduleView,
  redirect: { name: 'ScheduleIndex' },
  meta: {
    layout: 'booking',
    public: true,
    header: true,
  },
  children: [
    {
      path: '',
      name: 'ScheduleIndex',
      component: ScheduleIndex,
    },
  ],
} as RouteRecordRaw;
