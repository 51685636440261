<script setup lang="ts">
import { computed, ref } from 'vue';
import { isSameMinute, subMinutes } from 'date-fns';
import format from 'date-fns/format';
import { useI18n } from 'vue-i18n';

type Props = {
  modelValue?: string | false;
  startTime?: Date;
  disabled?: boolean;
};
const props = defineProps<Props>();
const { t } = useI18n();

const isShow = ref(false);

const reminderValues = computed(() => {
  const values = [];
  values.push({ value: false, label: t('time.no_remind') });
  if (!props.startTime) return values;
  for (let i = 15; i <= 60; i += 15) {
    const rawValue = subMinutes(props.startTime, i);
    const value = format(rawValue, 'yyyy-MM-dd HH:mm');
    let label = t('time.period', { min: i });
    if (i === 30) label += ` (${format(rawValue, 'HH:mm')})`;

    values.push({ value, label, rawValue });
  }
  return values;
});

const currentValue = computed(() => {
  if (!props.modelValue) return;
  console.log(props.modelValue);
  return reminderValues.value.find((v) =>
    isSameMinute(new Date(props.modelValue || 0), v.rawValue || 0),
  )?.label;
});
</script>

<template>
  <div :class="['ui-reminder', { disabled }]">
    {{ $t('time.reminder') }}
    <el-popover
      placement="bottom"
      width="fit-content"
      trigger="click"
      v-model:visible="isShow"
    >
      <div class="ui-reminder__list">
        <div
          v-for="item in reminderValues"
          :key="item.label"
          :class="{ selected: currentValue === item.label }"
          @click="
            $emit('update:modelValue', item.value);
            isShow = false;
          "
        >
          {{ item.label }}
        </div>
      </div>
      <template #reference>
        <div class="ui-reminder__reference">
          {{ currentValue || $t('time.no_remind') }}
        </div>
      </template>
    </el-popover>
  </div>
</template>

<style scoped lang="scss">
.ui-reminder {
  @include flex-row(1rem);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.15s ease-in-out;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

  &__reference {
    position: relative;
    @include flex-row;
    align-items: center;
    justify-content: center;
    max-height: 3.2rem;
    padding: 0.8rem;
    background: #f2f7f7;
    border-radius: 0.8rem;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    outline: none;
  }

  &__list {
    @include flex-column;
    overflow: auto;
    max-height: 25rem;

    & > div {
      padding: 1rem 0;
      @include gray-bg-hover;
      cursor: pointer;
      user-select: none;

      &.selected {
        background: #f2f7f7;
      }
    }
  }
}
</style>
