<script lang="ts" setup>
import { VueSelecto } from 'vue3-selecto';
import { useVModel } from '@vueuse/core';
import { useTouchDevice } from '@/hooks/useTouchDevice';

const props = defineProps<{
  modelValue: number[];
}>();
const emit = defineEmits(['update:modelValue']);

const data = useVModel(props, 'modelValue', emit);

const onSelect = (e: any) => {
  e.added.forEach((el: HTMLElement) => {
    const n = Number(el.dataset['select']);
    const index = data.value.indexOf(n);
    if (index === -1) data.value.push(n);
  });
  e.removed.forEach((el: HTMLElement) => {
    const n = Number(el.dataset['select']);
    const index = data.value.indexOf(n);
    if (index !== -1) data.value.splice(index, 1);
  });
};

const { isTouchDevice } = useTouchDevice();
</script>

<template>
  <VueSelecto
    v-if="!isTouchDevice"
    :selectableTargets="['.note-item[data-select]']"
    :selectByClick="false"
    :selectFromInside="false"
    :continueSelect="true"
    :hitRate="0"
    @select="onSelect"
  />
</template>
