<script setup lang="ts">
import UiModal from '@/components/ui/UiModal.vue';
import { useVModel } from '@vueuse/core';
import { useNoteStore } from '@/stores/notes/useNote';
import { storeToRefs } from 'pinia';
import NoteCardModalFooter from '@/components/notes/card/NoteCardModalFooter.vue';
import NoteItemControls, {
  NoteActionType,
} from '@/components/notes/item/NoteItemControls.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import NoteCardBody from '@/components/notes/card/NoteCardBody.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { ref, watch } from 'vue';
import { CoachName } from '@/types/coaches';
import CouchSchedule from '@/components/coaches/note/CoachNoteSchedule.vue';

const props = defineProps<{
  modelValue: boolean;
}>();
const emit = defineEmits(['update:modelValue']);

const isOpen = useVModel(props, 'modelValue', emit);

const noteStore = useNoteStore();
const { isStarted, note, isNoteLoading, initialNote } = storeToRefs(noteStore);

const notesTransformStore = useNotesTransformStore();

const handleSchedule = () => {
  if (!initialNote.value) return;
  isStarted.value = false;
  notesTransformStore.start([initialNote.value]);
};

const handleAction = (type: NoteActionType) => {
  if (type === 'done' || type === 'back') {
    note.value.status = type === 'done' ? 'CLOSE' : 'ACTIVE';
  } else if (type === 'schedule') {
    handleSchedule();
  }
};

const { isCoachesEnabled } = useCouch();

function useCouch() {
  const coachesStore = useCoachesStore();
  const { setCoach } = coachesStore;
  const { activeCoach, isCoachesEnabled } = storeToRefs(coachesStore);

  const cashedCoach = ref<CoachName | null>(null);

  watch(
    () => isOpen.value,
    (value) => {
      if (value) {
        cashedCoach.value = activeCoach.value.name;
        setCoach('note');
      } else {
        cashedCoach.value && setCoach(cashedCoach.value);
      }
    },
  );

  return { isCoachesEnabled };
}
</script>

<template>
  <UiModal
    :is-open="isOpen"
    @on-close="noteStore.end()"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :close-disabled="true"
    :title="$t('notes.one')"
    class="note-card-modal"
    :no-scroll="isCoachesEnabled"
  >
    <template #headerActions>
      <NoteItemControls
        v-if="!isNoteLoading"
        :is-completed="note.status === 'CLOSE'"
        @action="handleAction"
      />
      <widget-coach />
    </template>
    <template #body>
      <couch-schedule />
      <NoteCardBody
        v-model:title="note.title"
        v-model:description="note.description"
        :is-loading="isNoteLoading"
      />
    </template>
    <template #footer>
      <NoteCardModalFooter @schedule="handleSchedule" />
    </template>
  </UiModal>
</template>

<style scoped lang="scss">
.note-card-modal {
}
</style>

<style lang="scss">
.note-card-modal {
  height: unset !important;
  min-height: 628px !important;

  .modal__body {
    padding: 1.6rem;
  }

  .el-dialog__footer {
    padding: 2.2rem 2.4rem !important;
  }
}
</style>
