<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in" appear>
        <component :is="Component" />
      </transition>
    </router-view>
  </component>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import { useHead } from '@vueuse/head';

const title = 'Log in to Voiset I seamless task management with AI';
useHead({
  title,
  meta: [
    {
      key: 'og:title',
      property: 'og:title',
      content: title,
    },
  ],
});

const route = useRoute();
const i18t = useI18n();
const { lang } = storeToRefs(useSettingsStore());

const DEFAULT_LAYOUT = 'default';
const layout = computed(
  () => (route.meta?.layout || DEFAULT_LAYOUT) + '-layout',
);

watch(
  () => lang.value,
  (newLang) => newLang && (i18t.locale.value = newLang.toLowerCase()),
);
</script>
<style lang="scss">
#app {
  height: 100%;
}
</style>
