import { CURRENT_TIME_ZONE } from '@/consts/timeFormats';
import { Task } from '@/types/tasks';
import { isAllDayDate, stringDateToUTC } from './time';

export const formatTaskTimeToUTC = (task: Task) => {
  const isAllDayTask = isAllDayDate(task.deadline);

  task.deadline = isAllDayTask
    ? task.deadline
    : stringDateToUTC(task.deadline, {
        initialDateUTC0: true,
        timeZone: CURRENT_TIME_ZONE,
      }) || '';

  task.deadlineLast = stringDateToUTC(task.deadlineLast, {
    initialDateUTC0: true,
    timeZone: CURRENT_TIME_ZONE,
  });
  task.timeNotification = stringDateToUTC(task.timeNotification, {
    initialDateUTC0: true,
    timeZone: CURRENT_TIME_ZONE,
  });
  return task;
};
