import { useUserStore } from '@/stores/user';
import { useWorkspaceStore } from '@/stores/workspaces';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { useGoogleAccounts } from '@/stores/integrations/google';
import { useSettingsStore } from '@/stores/settings/settings';
import { useModalCreateTaskStore } from '@/stores/modalTaskCreate';

export const onLogout = () => {
  useUserStore().removeAuthData();
  useWorkspaceStore().$reset();
  useTariffStore().removeUserTariff();
  useGoogleAccounts().$reset();
  useSettingsStore().$reset();
  useModalCreateTaskStore().resetTask();
};
