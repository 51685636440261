import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { FeedbackBody, UserTypeFeedback } from '@/types/user';

export class UsersService extends ApiService {
  setFeedback(feedback: FeedbackBody, typeRequest: UserTypeFeedback) {
    return axiosInstance.post(ENDPOINTS.users.setFeedback, feedback, {
      params: {
        typeRequest,
      },
    });
  }
}
