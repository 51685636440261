<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import {
  addMinutes,
  endOfDay,
  isBefore,
  isSameMinute,
  isWithinInterval,
  setHours,
  setMinutes,
  startOfDay,
  startOfMinute,
} from 'date-fns';
import format from 'date-fns/format';
import { whenever } from '@vueuse/core';

type Props = {
  modelValue?: Date;
  start?: Date | string;
  end?: Date | string;
  formatTime?: string;
  step?: number;
  hiddenValue?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  formatTime: 'HH:mm',
  start: '00:00',
  end: '23:59',
  step: 15,
});

const emits = defineEmits(['update:modelValue']);

const isShow = ref(false);
whenever(
  () => isShow.value,
  async () =>
    await nextTick(() =>
      document
        .querySelectorAll(`#_${format(selectedDate.value, 'HH')}`)
        .forEach((el) => el.scrollIntoView({ block: 'nearest' })),
    ),
);

const divideTime = (time: Date | string) => {
  if (typeof time !== 'string') time = format(time, 'HH:mm');
  const [h, m] = (time as string).split(':');
  return { h: Number(h), m: Number(m) };
};

const selectedDate = ref<Date>(new Date());

const formatDate = (time: Date | string) => {
  const { h, m } = divideTime(time);
  if (isNaN(h) || isNaN(m)) return props.modelValue || new Date();

  return startOfMinute(setMinutes(setHours(selectedDate.value, h), m));
};

const startDate = computed(() => formatDate(props.start));
const endDate = computed(() => formatDate(props.end));

const allTimeListWithStep = computed(() => {
  const list: Date[] = [];
  const start = startOfDay(selectedDate.value);
  const end = endOfDay(selectedDate.value);
  let current = start;

  while (isBefore(current, end)) {
    list.push(current);
    current = addMinutes(current, props.step);
  }
  return list;
});
const currentTimeList = computed(() => {
  return allTimeListWithStep.value
    .filter((d) =>
      isWithinInterval(d, { start: startDate.value, end: endDate.value }),
    )
    .map((d) => ({
      raw: d,
      view: format(d, props.formatTime),
      id: format(d, '_HH'),
    }));
});

const onTimeChange = (time: Date | string) => {
  let { h, m } = divideTime(time);
  if (isNaN(h) || h > 23) h = 23;
  if (isNaN(m) || m > 59) m = 59;
  selectedDate.value = setMinutes(setHours(selectedDate.value, h), m);
  emits('update:modelValue', selectedDate.value);
};

watch(
  () => props.modelValue,
  () => props.modelValue && (selectedDate.value = props.modelValue),
);
</script>

<template>
  <div class="ui-timepicker">
    <el-popover
      placement="bottom"
      width="8rem"
      trigger="click"
      popper-style="padding:0;min-width:unset"
      @beforeEnter="!currentTimeList.length && (isShow = false)"
      v-model:visible="isShow"
    >
      <div class="ui-timepicker__list with-scroll">
        <div
          v-for="{ raw, view, id } in currentTimeList"
          :key="view"
          :id="id"
          :class="{ selected: isSameMinute(raw, selectedDate) }"
          @click="onTimeChange(raw)"
        >
          {{ view }}
        </div>
      </div>
      <template #reference>
        <input
          v-bind="$attrs"
          class="ui-timepicker__reference"
          :value="selectedDate && format(selectedDate, formatTime)"
          @change="onTimeChange(($event.target as HTMLInputElement).value)"
          @keyup.enter="onTimeChange(($event.target as HTMLInputElement).value)"
          @blur="isShow = false"
          v-maska
          data-maska="TT:TT"
          data-maska-tokens="{ 'T': { 'pattern': '[0-9]' } }"
        />
      </template>
    </el-popover>
  </div>
</template>

<style scoped lang="scss">
.ui-timepicker {
  @include f14;

  &__reference {
    position: relative;
    @include flex-row;
    align-items: center;
    justify-content: center;
    max-width: 8rem;
    max-height: 3.2rem;
    padding: 0.8rem 1.6rem;
    background: #f2f7f7;
    border-radius: 0.8rem;
    cursor: pointer;
    border: none;
    outline: none;
  }

  &__list {
    @include flex-column;
    overflow: auto;
    max-height: 25rem;

    & > div {
      padding: 1rem 0;
      margin: 0 0.4rem;
      text-align: center;
      @include gray-bg-hover;
      cursor: pointer;
      user-select: none;

      &.selected {
        background: #f2f7f7;
      }
    }
  }
}
</style>
