export const TARIFF_CARD_THEME = {
  default: {
    primaryColor: '#9BC95E',
    textColor: '#1A2A39',
    background: '#FCFCFC',
    controls: 'green',
    periodColor: '#676e7b',
    border: '#9bc95e',
  },
  active: {
    primaryColor: '#778BEE',
    textColor: '#1A2A39',
    background: '#C3D7FF',
    controls: 'default',
    periodColor: '#FCFCFC',
    border: '#2567aa',
  },
};

export const TARIFF_POPULAR_TAB_THEME = {
  default: {
    background: '#CDF399',
    color: '#8DBA51',
    img: 'default',
  },
  active: {
    background: '#b3cdff',
    color: '#5567c1',
    img: 'active',
  },
};
