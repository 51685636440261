<script lang="ts" setup>
import { computed } from 'vue';
import { useCurrencyConvert } from '@/hooks/useCurrencyConvert';

const { convertPriceWithLanguagePartials } = useCurrencyConvert();

interface Props {
  price: number;
  currency: string;
  period?: string | null;
  type?: 'small' | 'default';
  color?: string | boolean;
  periodColor?: string;
  crossed?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'default',
  color: '#1A2A39',
  periodColor: '#676e7b',
});

const sizes = computed(() => {
  if (props.type === 'default') {
    return {
      price: '1.6rem',
      period: '1.2rem',
      currency: '1.2rem',
    };
  }
  return {
    price: '1.2rem',
    period: '1.2rem',
    currency: '1.2rem',
  };
});

const convertPrice = computed(() => {
  const price = convertPriceWithLanguagePartials(props.price, props.currency);
  const integer = price.find((item) => item.type === 'integer')?.value;
  const decimal = price.find((item) => item.type === 'decimal')?.value;
  const fraction = price.find((item) => item.type === 'fraction')?.value;

  return {
    price: `${integer}${decimal}${fraction}`,
    currency: price.find((item) => item.type === 'currency')?.value || '',
  };
});
</script>

<template>
  <p :class="['ui__price', { crossed }]">
    <img
      class="ui__price-cross"
      src="@images/cross.svg"
      alt=""
      v-if="crossed"
    />
    <span class="ui__price-currency">
      {{ convertPrice.currency }}
    </span>
    <span>{{ convertPrice.price }}</span>
    <span v-if="period" class="ui__price-period"> /{{ period }} </span>
  </p>
</template>
<style lang="scss" scoped>
.ui__price {
  position: relative;
  @include flex-row;
  align-items: baseline;
  font-size: v-bind('sizes.price');
  font-weight: 500;
  justify-content: center;
  font-family: 'Unbounded', sans-serif;
  color: v-bind(color);

  &.crossed > span {
    color: #707a83;
  }

  &-period {
    font-weight: 400;
    font-size: v-bind('sizes.period');
    color: v-bind(periodColor);
    white-space: nowrap;
  }

  &-currency {
    color: #1a2a39;
    font-size: v-bind('sizes.currency');
    font-weight: 400;
  }

  &-cross {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
