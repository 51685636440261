import services from '@/api/services';
import { filesTypesMapping } from '@/consts';
import { ElNotification } from 'element-plus';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useCreateTaskFiles = () => {
  const { t } = useI18n();
  const files = ref<File[]>([]);
  const isFilesLoading = ref(false);

  const createFiles = async (): Promise<number[]> => {
    const filesIds: number[] = [];
    if (files.value.length) {
      const groupedFiles = files.value.reduce(
        (groupedFiles: Record<string, File[]>, file) => {
          const type =
            filesTypesMapping[
              file.type.split('/')[0] as keyof typeof filesTypesMapping
            ] || 'ATTACHMENT';
          if (!groupedFiles[type]) groupedFiles[type] = [];
          groupedFiles[type].push(file);
          return groupedFiles;
        },
        {},
      );

      for await (const group of Object.keys(groupedFiles)) {
        const formData = new FormData();
        formData.append('fileType', group);

        groupedFiles[group].forEach((file) => {
          formData.append('files', file);
        });

        try {
          const resFiles = await services.files.add(formData);
          resFiles.data.forEach((file) => {
            filesIds.push(file.id);
          });
        } catch (e) {
          ElNotification.error({
            message: t('some_error'),
            offset: 80,
          });
          console.error(e);
        }
      }
    }

    return filesIds;
  };

  return {
    files,
    isFilesLoading,
    createFiles,
  };
};
