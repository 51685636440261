import { RouteRecordRaw } from 'vue-router';

const NotesView = () => import('@/views/notes/notes.vue');
const NotesIndexView = () => import('@/views/notes/index.vue');

export default {
  path: '/notes',
  component: NotesView,
  meta: {
    layout: 'app',
  },
  children: [
    {
      path: '',
      name: 'notes',
      component: NotesIndexView,
    },
  ],
} as RouteRecordRaw;
