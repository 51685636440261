import { TokenType } from '@/types/integrations';

export const createHighLight = (
  color: string,
  date: string | Date,
  label: string,
) => ({
  highlight: { color, fillMode: 'outline' },
  dates: new Date(date),
  popover: { label },
});

export const getHighLightColor = (count: number, isVisible?: boolean) => {
  if (isVisible === false) return 'custom-gray';
  if (count < 3) {
    return 'custom-green';
  } else if (count >= 3 && count < 5) {
    return 'custom-orange';
  } else {
    return 'custom-red';
  }
};

export const calendarConfig = (type?: TokenType) => {
  if (type === 'GOOGLE')
    return {
      label: 'Google calendars',
      icon: require('@images/integrations/google/google-logo.svg'),
      optionsLink: 'SettingsIntegrationsCalendarsGoogle',
    };
  return {
    label: 'Voiset workspaces',
    icon: require('@images/integrations/google/voiset-logo.webp'),
  };
};
