export const getRecognitionConfig = (locale: string) => {
  return {
    message: 'StartRecognition',
    audio_format: {
      type: 'raw',
      encoding: 'pcm_f32le',
      sample_rate: 44000,
    },
    transcription_config: {
      language: locale.toLocaleLowerCase(),
      // output_locale: 'en-US',
      operating_point: 'enhanced',
      additional_vocab: [],
      enable_partials: true,
      max_delay: 2,
      max_delay_mode: 'flexible',
      enable_entities: true,
      punctuation_overrides: {
        permitted_marks: [',', '.', '?', '!'],
        sensitivity: 0,
      },
    },
  };
};
