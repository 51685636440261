<script setup lang="ts">
import NoteTransformStepCard from '@/components/notes/transform/steps/NoteTransformStepCard.vue';
import { Vue3Lottie } from 'vue3-lottie';
import DndAnimation1 from '@/animation/dnd-1.json';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { onBeforeMount } from 'vue';
import { useNotesByWorkspacesStore } from '@/stores/notes/useNotesByWorkspaces';

const notesTransformStore = useNotesTransformStore();
const notesByWorkspacesStore = useNotesByWorkspacesStore();

onBeforeMount(async () => {
  await notesByWorkspacesStore.start(notesTransformStore.initialNotes);
  setTimeout(() => {
    if (notesTransformStore.currentStep === 'prepare') {
      notesTransformStore.goToNextStep();
    }
  }, 1000);
});
</script>

<template>
  <NoteTransformStepCard>
    <template #header>
      <p>{{ $t('notes.transform.prepare') }}</p>
    </template>
    <vue3-lottie :width="710" :height="275" :animation-data="DndAnimation1" />
  </NoteTransformStepCard>
</template>

<style lang="scss">
.lottie-animation-container {
  svg {
    transform: unset !important;
  }
}
</style>
