<script lang="ts" setup>
import WidgetShareTask from '@/components/widget/WidgetShareTask.vue';
import IconShare from '@/components/icon/IconShare.vue';
import IconMessage from '@/components/icon/IconMessage.vue';
import IconClose from '@/components/icon/IconClose.vue';
import { ref } from 'vue';
import HeaderActionButton from './HeaderActionButton.vue';
import IconQuestion from '@/components/icon/IconQuestion.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';

interface Props {
  isOpen?: boolean;
  isShare?: boolean;
  isLoading?: boolean;
  isShowComments?: boolean;
}

defineProps<Props>();
const emit = defineEmits<{
  (e: 'toggle-comments'): void;
  (e: 'close'): void;
}>();

const couchStore = useCoachesStore();
const share = ref(false);

const setShare = (val: boolean) => {
  share.value = val;
};
</script>

<template>
  <div :class="$style.wrapper">
    <HeaderActionButton
      :tooltip="
        $t(
          !isShowComments
            ? 'view_modal_task.comments'
            : 'view_modal_task.hide_comments',
        )
      "
      @click="emit('toggle-comments')"
    >
      <icon-message color="currentColor" />
    </HeaderActionButton>
    <el-popover
      v-if="!isShare && !isLoading"
      :visible="share"
      @update:visible="setShare"
      placement="bottom"
      popper-class="share__popup"
      width="100%"
      trigger="click"
      @hide="setShare(false)"
      @show="setShare(true)"
    >
      <widget-share-task @on-close="setShare(false)" />
      <template #reference>
        <div>
          <HeaderActionButton
            v-if="!isLoading"
            :tooltip="$t('view_modal_task.share')"
          >
            <IconShare />
          </HeaderActionButton>
        </div>
      </template>
    </el-popover>
    <HeaderActionButton
      :tooltip="$t('tooltips')"
      :active="couchStore.isCoachesEnabled"
      @click="couchStore.resolveCouch()"
    >
      <IconQuestion width="18" height="18" />
    </HeaderActionButton>
    <button
      v-if="!isShare"
      :class="$style.close"
      @click="isOpen && emit('close')"
    >
      <icon-close />
    </button>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.btn {
  display: flex;
  align-items: center;
  @include f14;
  line-height: 1.1;
  min-height: 2.8rem;
  border-radius: 6px;
  transition: all 0.1s linear;
  padding: 0 1rem;
  margin-right: 1rem;

  &:hover {
    background-color: #ecfadb;
  }

  svg {
    margin-right: 0.85rem;
  }

  &.share {
    margin-right: 1.25rem;
  }
}

.close {
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
</style>
