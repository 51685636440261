import { SortOrder } from '@/types/common';

export const tableSorting = <
  T extends { order: SortOrder; columnSort: T['columnSort'] },
>(
  filters: T,
  columnSort: T['columnSort'],
) => {
  if (filters.columnSort === columnSort || !columnSort) {
    switch (filters.order) {
      case null:
        filters.order = 'DESC';
        break;
      case 'DESC':
        filters.order = 'ASC';
        break;
      case 'ASC':
        filters.order = null;
        break;
    }
  } else {
    filters.columnSort = columnSort;
    filters.order = 'DESC';
  }
};
