import { defineStore, storeToRefs } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { TaskDTO, TaskFilters, TTaskSorts } from '@/types/tasks';
import { endOfDay, format, startOfDay } from 'date-fns';
import { tableSorting } from '@/utils/table';
import { taskFastFilters, taskPriorities, taskStatuses } from '@/consts';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import { TableFilter } from '@/types/common';

import {
  filtersMapping,
  subordinatesFiltersMapping,
} from '@/utils/filtersMapping';
import { useI18n } from 'vue-i18n';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import UiTableFiltersCheckboxList from '@/components/table/filters/UiTableFiltersCheckboxList.vue';
import UiTableFiltersUsersList from '@/components/table/filters/UiTableFiltersUsersList.vue';
import UiTableFiltersPresetList from '@/components/table/filters/UiTableFiltersPresetList.vue';

export const useTasksFiltersStore = defineStore('tasksFilters', () => {
  const { t } = useI18n();
  const { formatWithLocale } = useCurrentLanguage();

  const initialFilters = (): TaskFilters => {
    const dates = JSON.parse(localStorage.calendarRange || false);
    return {
      dateTo: format(
        endOfDay(dates?.end ? new Date(dates.end) : new Date()),
        'yyyy-MM-dd HH:mm',
      ),
      dateFrom: format(
        startOfDay(dates?.start ? new Date(dates?.start) : new Date()),
        'yyyy-MM-dd HH:mm',
      ),
      pageNum: 1,
      pageSize: 10,
      taskDTO: {
        executorIdList: [],
        priorityList: [],
        statusList: [],
        taskFastFilters: '',
        taskAssignment: '',
        taskNameSearch: '',
      },
    };
  };

  const sorts = reactive<TTaskSorts>({
    order: 'DESC',
    columnSort: 'createDateTime',
  });
  const filters = ref<TaskFilters>(initialFilters());

  const subordinatesStore = useSubordinatesStore();
  const { subordinates, pending: subordinatesPending } =
    storeToRefs(subordinatesStore);

  const setExistingWorkspaceUsers = async () => {
    if (subordinatesPending.value) return;
    await subordinatesStore.fetch();
    const actualUsersIds = subordinates.value?.content.map((s) => s.id);
    if (!actualUsersIds) return;
    const currentActualUsers = filters.value.taskDTO.executorIdList.filter(
      (id) => actualUsersIds.includes(id),
    );
    setFilter('executorIdList', currentActualUsers);
  };

  const filtersList = computed<TableFilter[]>(() => [
    {
      options: taskFastFilters.map(filtersMapping),
      label: t('tasks.filters.presets'),
      filterField: 'taskFastFilters',
      component: UiTableFiltersPresetList,
    },
    {
      options: Object.keys(taskStatuses).map(filtersMapping),
      label: t('tasks.filters.status'),
      filterField: 'statusList',
      component: UiTableFiltersCheckboxList,
    },
    {
      options: Object.keys(taskPriorities).map(filtersMapping),
      label: t('tasks.filters.priority'),
      filterField: 'priorityList',
      component: UiTableFiltersCheckboxList,
    },
    {
      options:
        subordinates.value?.content?.map(subordinatesFiltersMapping) || [],
      label: t('tasks.filters.performer'),
      filterField: 'executorIdList',
      component: UiTableFiltersUsersList,
    },
  ]);

  function setSort(columnSort: TTaskSorts['columnSort']) {
    tableSorting<TTaskSorts>(sorts, columnSort);
  }

  function setFilter<FilterField extends keyof TaskFilters['taskDTO']>(
    field: FilterField,
    values: (typeof filters.value.taskDTO)[FilterField],
  ) {
    filters.value.taskDTO[field] = values;
  }

  const refreshFilters = () =>
    (Object.keys(filters.value.taskDTO) as (keyof TaskDTO)[]).forEach(
      <T extends keyof TaskDTO>(key: T) => {
        filters.value.taskDTO[key] = (
          filters.value.taskDTO[key] instanceof Array ? [] : ''
        ) as TaskDTO[T];
      },
    );

  function setPagination(pageNum: number, pageSize: number) {
    filters.value.pageNum = pageNum;
    filters.value.pageSize = pageSize;
  }

  function setPage(page: number) {
    filters.value.pageNum = page;
  }

  function setPageSize(pageSize: number) {
    filters.value.pageSize = pageSize;
  }

  const getDatesRange = computed({
    get() {
      return {
        start: new Date(filters.value.dateFrom),
        end: new Date(filters.value.dateTo),
      };
    },
    set(value: { start: Date; end: Date }) {
      setDatesRange(value);
    },
  });

  const getDatesRangeView = computed(() => {
    const DATE_FORMAT = 'dd MMM';
    const localeFormat = (date: string) =>
      formatWithLocale(new Date(date), DATE_FORMAT);
    const start = localeFormat(filters.value.dateFrom);
    const end = localeFormat(filters.value.dateTo);
    const singleDay = start === end && localeFormat(filters.value.dateTo);
    return { start, end, singleDay };
  });

  function setDatesRange(dates: { start: Date; end: Date }) {
    const DATE_FORMAT = 'yyyy-MM-dd HH:mm';
    const { start, end } = dates;
    localStorage.calendarRange = JSON.stringify(dates);
    filters.value.dateFrom = format(startOfDay(new Date(start)), DATE_FORMAT);
    filters.value.dateTo = format(endOfDay(new Date(end)), DATE_FORMAT);
  }

  return {
    sorts,
    filters,
    filtersList,
    refreshFilters,
    getDatesRange,
    getDatesRangeView,
    setDatesRange,
    setFilter,
    setSort,
    initialFilters,
    setPagination,
    setPage,
    setPageSize,
    setExistingWorkspaceUsers,
  };
});
