<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Vector">
      <path
        d="M12.6667 14.666C12.6667 15.0342 12.3683 15.3327 12.0001 15.3327C11.6319 15.3327 11.3334 15.0342 11.3334 14.666C11.3334 14.2978 11.6319 13.9993 12.0001 13.9993C12.3683 13.9993 12.6667 14.2978 12.6667 14.666Z"
        fill="currentColor"
      />
      <path
        d="M15.3334 11.9993C15.3334 12.3675 15.0349 12.666 14.6667 12.666C14.2986 12.666 14.0001 12.3675 14.0001 11.9993C14.0001 11.6312 14.2986 11.3327 14.6667 11.3327C15.0349 11.3327 15.3334 11.6312 15.3334 11.9993Z"
        fill="currentColor"
      />
      <path
        d="M2.00008 3.99935C2.00008 4.36754 1.7016 4.66602 1.33341 4.66602C0.965225 4.66602 0.666748 4.36754 0.666748 3.99935C0.666748 3.63116 0.965225 3.33268 1.33341 3.33268C1.7016 3.33268 2.00008 3.63116 2.00008 3.99935Z"
        fill="currentColor"
      />
      <path
        d="M4.66675 1.33268C4.66675 1.70087 4.36827 1.99935 4.00008 1.99935C3.63189 1.99935 3.33341 1.70087 3.33341 1.33268C3.33341 0.964492 3.63189 0.666016 4.00008 0.666016C4.36827 0.666016 4.66675 0.964492 4.66675 1.33268Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00008 4.32129L7.56528 6.1144C7.39148 6.83114 6.83187 7.39075 6.11513 7.56455L4.32203 7.99935L6.11513 8.43415C6.83187 8.60795 7.39148 9.16756 7.56528 9.8843L8.00008 11.6774L8.43488 9.8843C8.60868 9.16756 9.16829 8.60795 9.88503 8.43415L11.6781 7.99935L9.88503 7.56455C9.16829 7.39075 8.60868 6.83114 8.43488 6.1144L8.00008 4.32129ZM8.87024 3.35039C8.64897 2.43789 7.35119 2.43789 7.12992 3.35039L6.52109 5.8612C6.44209 6.18699 6.18772 6.44136 5.86193 6.52036L3.35112 7.12919C2.43862 7.35046 2.43862 8.64824 3.35112 8.86951L5.86193 9.47834C6.18772 9.55734 6.44209 9.81171 6.52109 10.1375L7.12992 12.6483C7.35119 13.5608 8.64897 13.5608 8.87024 12.6483L9.47908 10.1375C9.55807 9.81171 9.81244 9.55734 10.1382 9.47834L12.649 8.86951C13.5615 8.64824 13.5615 7.35046 12.649 7.12919L10.1382 6.52036C9.81244 6.44136 9.55807 6.18699 9.47907 5.8612L8.87024 3.35039Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00316 14.8758C5.88729 15.1863 5.54169 15.344 5.23124 15.2281C3.22028 14.4776 1.60496 12.9194 0.779904 10.9479C0.65198 10.6422 0.796081 10.2907 1.10176 10.1628C1.40745 10.0349 1.75896 10.179 1.88688 10.4847C2.58422 12.151 3.95111 13.4695 5.65085 14.1039C5.9613 14.2198 6.11904 14.5654 6.00316 14.8758Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.877 6.01319C14.5666 6.12931 14.2209 5.97184 14.1048 5.66147C13.4654 3.95237 12.1315 2.57983 10.4463 1.88769C10.1398 1.76179 9.99335 1.41124 10.1192 1.10472C10.2451 0.798196 10.5957 0.65177 10.9022 0.777668C12.8952 1.59624 14.4718 3.218 15.2287 5.24099C15.3448 5.55135 15.1873 5.89708 14.877 6.01319Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>
