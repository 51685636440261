import { computed, Ref } from 'vue';
import { ITariffItemResponse } from '@/types/tariffs';

export const usePrices = (
  tariff: Ref<ITariffItemResponse | undefined>,
  isAnnually: Ref<boolean>,
) => {
  const prices = computed(() => {
    const initialMonthRawPrice = tariff.value?.price || 0;
    const initialAnnuallyRawPrice = tariff.value?.annualTariff?.price || 0;
    const initialAnnuallyRawDiscountPrice = initialMonthRawPrice * 12;

    const currentRawPrice = computed(() => {
      return isAnnually.value ? initialAnnuallyRawPrice : initialMonthRawPrice;
    });

    const currentPrice = computed(() =>
      (currentRawPrice.value / 100).toFixed(2),
    );

    return {
      currentRawPrice,
      initialAnnuallyRawPrice,
      initialMonthRawPrice,
      initialAnnuallyRawDiscountPrice,
      currentPrice,
    };
  });

  return { prices };
};
