import { createUrl } from '@/utils/url';

const filesUrl = createUrl('files');

const files = filesUrl('');
const getUserList = filesUrl('personal/list');
const getWorkspaceList = filesUrl('company/list');

export const filesEndpoint = {
  files,
  getUserList,
  getWorkspaceList,
};
