<template>
  <ui-autocomplete
    class="autocomplete-users"
    v-model="modelSearch"
    :items="filteredList"
    :is-open="isOpen"
    @select-item="$emit('selectItem', $event)"
  >
    <template #header>
      <ui-autocomplete-user
        :item="currentUser"
        @click="$emit('selectItem', currentUser)"
        style="margin-bottom: 1rem"
      />
    </template>
    <template #default="{ item }: { item: SubordinateViewType }">
      <ui-autocomplete-user :item="item" />
    </template>
  </ui-autocomplete>
</template>

<script setup lang="ts">
import UiAutocomplete from '@/components/ui/UiAutocomplete/UiAutocomplete.vue';
import UiAutocompleteUser from '@/components/ui/UiAutocomplete/UiAutocompleteUser.vue';
import { computed } from 'vue';
import { SubordinateViewType } from '@/utils/subordinates';
import { useUserStore } from '@/stores/user';

const props = defineProps<{
  items: SubordinateViewType[];
  isOpen: boolean;
  search: string;
}>();

const userStore = useUserStore();
const currentUser = computed(() =>
  props.items.find((user) => user.id === userStore.userData?.id),
);

const filteredList = computed(() => {
  return props.items.filter((item) => {
    if (item.id === userStore.userData?.id) return false;
    if (modelSearch.value) {
      return item.fullName
        .toLocaleLowerCase()
        .includes(modelSearch.value.toLocaleLowerCase());
    }
    return item;
  });
});

const modelSearch = computed<string>({
  get() {
    return props.search;
  },
  set(value) {
    emit('update:search', value);
  },
});

const emit = defineEmits(['update:modelValue', 'update:search', 'selectItem']);
</script>

<style scoped lang="scss"></style>
