<script setup lang="ts"></script>

<template>
  <div class="note-transform-generate">
    <div class="note-transform-generate__header">
      <div class="note-transform-generate__title">
        <slot name="title" />
      </div>
      <div class="note-transform-generate__actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="note-transform-generate__body">
      <slot />
    </div>
    <div v-if="$slots.footer" class="note-transform-generate__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.note-transform-generate {
  border-radius: 20px;
  background: #fcfcfc;
  overflow: hidden;
  width: 100%;
  @include flex-column;

  &__header {
    padding: 1.6rem 2.4rem;
    box-shadow: 0 10px 30px -5px #57575712;
    background: #fcfcfc;
    @include flex-row();
    justify-content: space-between;
  }

  &__title {
    @include f16;
    line-height: 3rem;
  }

  &__body {
    flex: 1 1 auto;
    padding: 2rem 2.4rem;
  }

  &__footer {
    flex: 0 0 auto;
    border-radius: 20px;
    padding: 2rem 2.4rem;
    background: #fcfcfc;
    box-shadow: 0px -6px 20px 0px #c5c5c533;
  }
}
</style>
