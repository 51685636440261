import { RouteRecordRaw } from 'vue-router';
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';

const ShareView = () => import('@/views/shareLink.vue');

export default {
  path: '/shareLink/:uuid',
  component: ShareView,
  meta: {
    layout: 'unsigned',
    public: true,
    header: true,
  },
  beforeEnter: (to) => {
    const modalViewStore = useModalViewTaskStore();
    if (to.params.uuid) {
      modalViewStore.getShareTask(to.params.uuid as string);
    }
  },
} as RouteRecordRaw;
