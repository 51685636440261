import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import services from '@/api/services';
import { ThirdPartyRequestParams } from '@/types/user';
import {
  AccountStatus,
  GoogleAccount,
  GoogleCalendar,
  SetCalendarRequestParams,
} from '@/types/integrations';
import { numberToColor } from '@/utils/common';
import { useWorkspaceStore } from '@/stores/workspaces';

export const useGoogleAccounts = defineStore('google', () => {
  const TOKEN_TYPE = 'GOOGLE';

  const accountsList = ref<GoogleAccount[]>([]);
  const setPending = ref(false);
  const fetchPending = ref(false);

  const workspacesStore = useWorkspaceStore();

  const fetchAccountsList = async () => {
    try {
      fetchPending.value = true;
      accountsList.value = [];
      accountsList.value = (
        await services.integrations.google.getAccountsList(TOKEN_TYPE)
      ).data.filter((a) => a.calendarStatus !== 'DELETE');
    } catch (e) {
      console.error(e);
    } finally {
      fetchPending.value = false;
    }
  };

  const fetchAccountCalendars = async (account?: GoogleAccount) => {
    try {
      if (!account?.email) return;
      const { data } =
        await services.integrations.google.getAccountCalendarsList(
          account.email,
          TOKEN_TYPE,
        );
      account.calendars = data
        .filter((c) => c.status !== 'DELETE')
        .map((d) => Object.assign(d, { color: numberToColor(d.id) }));
    } catch (e) {
      console.error(e);
    }
  };

  const fetchCalendarsByAccountList = async () => {
    try {
      if (!accountsList.value.length) return;
      fetchPending.value = true;
      await Promise.allSettled(accountsList.value.map(fetchAccountCalendars));
    } catch (e) {
      console.error(e);
    } finally {
      fetchPending.value = false;
    }
  };

  const fetchAccountsWithCalendars = async () => {
    await fetchAccountsList();
    await fetchCalendarsByAccountList();
  };

  const add = async (auth: ThirdPartyRequestParams) => {
    try {
      setPending.value = true;
      const { data } = await services.integrations.google.addAccount(
        auth,
        TOKEN_TYPE,
      );
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      setPending.value = false;
    }
  };

  const setStatus = async (
    connectedId: string | number,
    calendarStatus: GoogleAccount['calendarStatus'],
  ) => {
    try {
      setPending.value = true;
      const { data } = await services.integrations.google.setAccount(
        connectedId,
        calendarStatus,
      );
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      setPending.value = false;
    }
  };

  const setCalendar = async (
    update: Partial<GoogleCalendar> & { id: GoogleCalendar['id'] },
    connectedId: string | number,
  ) => {
    try {
      setPending.value = true;
      const { status, syncCalendarMode, id: calendarId } = update;
      const params: SetCalendarRequestParams = {
        connectedId,
        calendarId,
        ...(syncCalendarMode && { syncCalendarMode }),
        ...(status && { calendarStatus: status }),
      };

      const { data: updatedCalendar } =
        await services.integrations.google.setCalendar(params);
      if (!updatedCalendar) return;

      const parentAccount = accountsList.value.find(
        (a) => a.id === params.connectedId,
      );
      if (!parentAccount) return;
      const oldCalendarId = parentAccount.calendars?.findIndex(
        (c) => c.id === updatedCalendar.id,
      );
      if (oldCalendarId === undefined || oldCalendarId === -1) return;
      parentAccount.calendars?.splice(oldCalendarId, 1, updatedCalendar);
      await workspacesStore.fetchList();
    } catch (e) {
      console.error(e);
    } finally {
      setPending.value = false;
    }
  };

  const remove = async (account?: GoogleAccount) => {
    if (!account) return;
    const id = account.id;
    const updatedAcc = await setStatus(id, 'DELETE');
    if (!updatedAcc) return;
    const updatedAccId = accountsList.value.findIndex(
      (l) => l.id === updatedAcc.id,
    );
    if (updatedAccId > -1) accountsList.value.splice(updatedAccId, 1);
    await workspacesStore.fetchList();
  };

  const connectToggle = async (account?: GoogleAccount) => {
    if (!account) return;
    const status = account.calendarStatus;
    const id = account.id;
    if (!(id && status && ['ACTIVE', 'NO_ACTIVE'].includes(status))) return;

    const toggledStatus = status === 'ACTIVE' ? 'NO_ACTIVE' : 'ACTIVE';
    const updatedAcc = await setStatus(id, toggledStatus);
    if (!updatedAcc) return;
    const updatedAccId = accountsList.value.findIndex(
      (l) => l.id === updatedAcc.id,
    );
    if (updatedAccId > -1) {
      accountsList.value.splice(updatedAccId, 1, updatedAcc);
      await fetchAccountCalendars(accountsList.value[updatedAccId]);
      await workspacesStore.fetchList();
    }
  };

  const googleAccountsMapping = computed(() =>
    accountsList.value.reduce((acc, a) => {
      const ids = a.calendars?.map((c) => c.id);
      if (!ids) return acc;
      acc[a.email] = { ...(ids && { ids }), status: a.calendarStatus };
      return acc;
    }, {} as Record<string, { ids?: number[]; status: AccountStatus }>),
  );

  const $reset = () => {
    accountsList.value = [];
    fetchPending.value = false;
    setPending.value = false;
  };

  return {
    accountsList,
    setPending,
    fetchPending,
    googleAccountsMapping,
    fetchAccountsList,
    fetchAccountCalendars,
    fetchCalendarsByAccountList,
    fetchAccountsWithCalendars,
    add,
    remove,
    setStatus,
    setCalendar,
    connectToggle,
    $reset,
  };
});
