import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { Workspace } from '@/types/workspaces';

export class WorkspacesService extends ApiService {
  list() {
    return axiosInstance.get<Workspace[]>(ENDPOINTS.companies.list);
  }

  create(params: { name: string }) {
    return axiosInstance.post<Workspace>(ENDPOINTS.companies.create, params);
  }

  modify(params: Partial<Workspace>) {
    return axiosInstance.put(ENDPOINTS.companies.modify, params);
  }

  setCurrent(params: { companyId: number }) {
    return axiosInstance.post(ENDPOINTS.companies.setCurrent, null, { params });
  }

  delete(id: number) {
    return axiosInstance.delete<any>(ENDPOINTS.companies.deleteCompany, {
      data: { id },
    });
  }

  removeUser(params: { userId: number; companyId: number }) {
    const { userId, companyId } = params;
    return axiosInstance.delete(ENDPOINTS.companies.removeUser, {
      data: { id: companyId },
      params: { userId },
    });
  }

  addUser(params: { userId: number; companyId: number }) {
    const { userId, companyId } = params;
    return axiosInstance.post(
      ENDPOINTS.companies.addUser,
      {
        id: companyId,
      },
      { params: { userId } },
    );
  }
}
