import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import { computed, MaybeRefOrGetter, toRef } from 'vue';

export const useWorkspaceById = (
  workspaceId: MaybeRefOrGetter<number | undefined>,
) => {
  const id = toRef(workspaceId);

  const { list } = storeToRefs(useWorkspaceStore());

  const workspace = computed(() => list.value.find((w) => w.id === id.value));
  const external = computed(() => workspace.value?.externalCalendarItem);
  const externalGoogle = computed(() => {
    if (external.value?.typeOfCalendar !== 'GOOGLE') {
      return null;
    }
    return {
      item: external.value,
      isSingleSync: external.value.syncCalendarMode === 'SINGLE',
    };
  });

  return {
    workspace,
    external,
    externalGoogle,
  };
};
