<script setup lang="ts">
import IconClose from '@/components/icon/IconClose.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';

withDefaults(
  defineProps<{
    full?: boolean;
    stretch?: boolean;
  }>(),
  {
    full: false,
    stretch: false,
  },
);

const notesTransformStore = useNotesTransformStore();
</script>

<template>
  <div
    class="note-transform-step-card__wrapper"
    :class="{ full: full, stretch: stretch }"
  >
    <div class="note-transform-step-card" :class="{ full: full }">
      <button
        @click="notesTransformStore.cancel"
        class="note-transform-step-card__close"
      >
        <IconClose />
      </button>
      <div class="note-transform-step-card__header">
        <slot name="header" />
      </div>
      <slot />
      <div v-if="$slots.footer" class="note-transform-step-card__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.note-transform-step-card {
  position: relative;
  height: 100%;
  background: #fcfcfc;
  padding: 3rem;
  box-shadow: 10px 10px 40px 0 #42424240;
  border-radius: 1.2rem;
  @include flex-column(2.4rem);

  &.full,
  &__wrapper.full {
    width: 100%;
    margin: 0 auto;
  }

  &__wrapper.stretch {
    height: 96%;
    max-width: min(90%, 144rem);
    width: 100%;
  }

  &__header {
    @include flex-column(1rem);
    align-items: center;

    :deep(p) {
      @include f16;
      font-weight: 500;
      text-align: center;
    }

    :deep(small) {
      @include f14;
      text-align: center;
      display: inline-block;
      max-width: 65rem;
    }
  }

  &__close {
    position: absolute;
    top: 1.2rem;
    right: 1.6rem;
  }
}
</style>
