<script setup lang="ts">
import { GoogleCalendar } from '@/types/integrations';
import IconMarker from '@/components/icon/IconMarker.vue';
import { computed } from 'vue';
import OneWaySync from '@/components/integrations/google/settings/heplers/OneWaySync.vue';
import TwoWaySync from '@/components/integrations/google/settings/heplers/TwoWaySync.vue';

type Props = { calendar: GoogleCalendar };
const props = defineProps<Props>();
const emits = defineEmits(['onStatusChange', 'onModeChange']);

const syncState = computed(() =>
  ['CREATE', 'CONFIRMED'].includes(props.calendar.status)
    ? { value: true, color: 'var(--color-success)', text: 'Sync on' }
    : { value: false, color: '#1A2A39', text: 'Sync off' },
);

const onStatusChange = () => {
  const status = syncState.value.value ? 'NOT_VISIBLE' : 'CREATE';
  emits('onStatusChange', {
    status,
    id: props.calendar.id,
  });
};

const onModeChange = (mode: {
  syncCalendarMode: GoogleCalendar['syncCalendarMode'];
}) => emits('onModeChange', { ...mode, id: props.calendar.id });
</script>

<template>
  <div :class="['calendar-item', { open: syncState.value }]">
    <div class="calendar-item__reference" @click="onStatusChange">
      <IconMarker :color="calendar.color" />
      <span>{{ calendar.title }}</span>
      <div>
        <el-switch class="green" :modelValue="syncState.value" />
        <span :style="{ color: syncState.color }">{{ syncState.text }}</span>
      </div>
    </div>
    <div class="calendar-item__content">
      <OneWaySync
        :sync="calendar.syncCalendarMode"
        @onModeChange="onModeChange"
      />
      <TwoWaySync
        :sync="calendar.syncCalendarMode"
        @onModeChange="onModeChange"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.calendar-item {
  display: grid;
  grid-template-rows: 2.6rem 0fr;
  gap: 1rem;
  transition: all 0.25s ease-in-out;

  &.open {
    grid-template-rows: 2.6rem 1fr;
  }

  &__reference {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    & > span {
      text-align: left;
      font-weight: 600;
    }

    & > div {
      @include flex-row(1rem);
      align-items: center;

      & > span {
        width: 5.5rem;
        transition: all 0.15s ease-in-out;
      }
    }
  }

  &__content {
    @include flex-column;
    overflow: hidden;
  }
}
</style>
