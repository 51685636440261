export const createNumberedArray = (length: number) =>
  Array.from({ length }, (v, i) => i + 1);

export const numericSort = <T extends Record<K, T[K]>, K extends keyof T>(
  array: T[],
  prop: K,
  direction: 'ASC' | 'DESC' = 'ASC',
) => {
  const d = direction === 'DESC' ? -1 : 1;
  return array.sort((a, b) => (a[prop] - b[prop]) * d);
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const colorsCache = new Map<number, string>();

const idTo16 = (id: number) => Math.floor(id).toString(16).padStart(2, '0');

export const numberToColor = (id?: number) => {
  if (!id) return '#cecede';
  const cachedColor = colorsCache.get(id);
  if (cachedColor) return cachedColor;

  const red = idTo16(id * 2);
  const green = idTo16(id * 5);
  const blue = idTo16(id * 25);

  return `#${red}${green}${blue}`.slice(0, 7);
};

export const stringToColor = (string: string) => {
  let hash = 0;
  string.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, '0');
  }
  color += 'BF';
  return color;
};
