import { createUrl } from '@/utils/url';

const notesUrl = createUrl('/web/notes');

const getAll = notesUrl('');
const update = notesUrl('');
const get = (id: number) => notesUrl(`${id}`);
const create = notesUrl('create');
const scheduleCalendar = notesUrl('schedule/calendar');
const scheduleTask = notesUrl('schedule/task');
const scheduleWorkspace = notesUrl('schedule/workspace');

export const notesEndpoint = {
  getAll,
  update,
  get,
  create,
  scheduleCalendar,
  scheduleTask,
  scheduleWorkspace,
};
