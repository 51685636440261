import { IFile } from './common';
import { LangUI } from '@/types/lang';

export type UserRole = 'ADMIN' | 'ASSISTANT' | 'USER';
export type UserType = 'COMPANY' | 'OWNER' | 'WORKER';
export enum UserTypeFeedback {
  FEEDBACK = 'FEEDBACK',
  SUPPORT = 'SUPPORT',
}

export interface FeedbackBody {
  description: string;
  email: string;
  subject: string;
}

export interface User {
  id: number;
  email: string;
  avatar: null | IFile;
  firstName: string;
  invitationCode: string;
  lastName: string;
  locale: {
    abbreviation: string;
    id: number;
    name: string;
  };
  currentCompany: number;
  password: string;
  positionName: string;
  role: UserRole;
  userType: UserType;
  uiLocale: LangUI;
  position: {
    id: number;
    name: string;
  };
}

export type ThirdPartyRequestParams =
  | { appleAuthorizationCode: string }
  | { googleAuthorizationCode: string }
  | { microsoftAuthorizationCode: string };

export type AppleAuthSuccessResponse = {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};
