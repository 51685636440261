import { URL_REGEXP_PATTERN } from '@/consts/regExps';

export const getUnparsedStringBeforeCaret = () => {
  const sel = window.getSelection && window.getSelection();
  if (!sel || sel.rangeCount <= 0) return;

  const range = sel.getRangeAt(0);
  if (range?.collapsed) {
    const text = range.startContainer?.textContent?.substring(
      0,
      range.startOffset + 1,
    );
    const words = text?.split(' ');

    const isSpaceLastSymbol = words && words[words.length - 1] === '';
    if (isSpaceLastSymbol && words.length >= 2) {
      return words[words.length - 2];
    }
  }
};

export const tryToParseByPattern = (pattern: RegExp) => {
  const unparsedString = getUnparsedStringBeforeCaret();
  if (unparsedString) {
    const urlRegexp = new RegExp(pattern);

    if (urlRegexp.test(unparsedString)) return unparsedString;
  }
};

export const urlToHref = (url: string) =>
  url.replace('www.', '').startsWith('http') ? url : `https://${url}`;

export const mapFnTextToHtml = (word: string) => {
  if (/\n/g.test(word)) return '<div><br></div>';
  if (URL_REGEXP_PATTERN.test(word)) {
    return word.replace(
      URL_REGEXP_PATTERN,
      `<a contenteditable="false" target="_blank" href="${urlToHref(
        word,
      )}">${word}</a>`,
    );
  }
  return word;
};
