import { ApiService } from '@/api/services/core';
import { axiosInstance, baseURL } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { Pageable } from '@/types/pagination';
import { Comment } from '@/types/comment';
import { ICreateTaskRequest, Task } from '@/types/tasks';
import { IFile, ITableSorts } from '@/types/common';
import { SharedTask, SharedTasksRequestParams } from '@/types/share';
import axios from 'axios';

export class ShareLinkService extends ApiService {
  create({
    fullName = 'user created by link',
    taskId,
  }: {
    fullName?: string;
    taskId: number;
  }) {
    return axiosInstance.get<any>(ENDPOINTS.share.create, {
      params: {
        fullName,
        taskId,
      },
    });
  }

  getTaskById(secureTaskId: string) {
    return axios.get<Task>(ENDPOINTS.share.getTask, {
      baseURL,
      params: {
        secureTaskId,
      },
      withCredentials: true,
    });
  }

  getFileById(fileId: number, secureTaskId: string) {
    return axiosInstance.get<Blob>(ENDPOINTS.share.getFiles, {
      params: {
        fileId,
        secureTaskId,
      },
      withCredentials: true,
      responseType: 'blob',
    });
  }

  addComments(secureTaskId: number, text: string) {
    return axiosInstance.get(ENDPOINTS.share.addComment, {
      params: {
        secureTaskId,
        text: text.replace(/"/g, ''),
      },
      withCredentials: true,
    });
  }

  getComments(
    id: number,
    params: { pageNum: number; pageSize: number } = {
      pageNum: 1,
      pageSize: 10,
    },
  ) {
    const { pageNum, pageSize } = params;
    return axiosInstance.get<Pageable<Comment>>(
      ENDPOINTS.share.getTaskComments,
      {
        params: {
          pageNum,
          pageSize,
          secureTaskId: id,
          order: 'DESC',
        },
        withCredentials: true,
      },
    );
  }

  updateTaskStatus(id: number, status: string) {
    return axiosInstance.get<Task>(ENDPOINTS.share.changeStatus, {
      params: {
        secureTaskId: id,
        status,
      },
      withCredentials: true,
    });
  }

  add(formData: FormData) {
    return axiosInstance.post<IFile[]>(ENDPOINTS.share.uploadFiles, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });
  }

  updateTask(
    createTaskPayload: ICreateTaskRequest,
    secureTaskId: string | number,
  ) {
    const { taskStatus, ...body } = createTaskPayload;
    return axiosInstance.post<Task>(ENDPOINTS.share.updateTask, body, {
      params: { secureTaskId, taskStatus },
      withCredentials: true,
    });
  }

  list(params: SharedTasksRequestParams & ITableSorts<SharedTask>) {
    return axiosInstance.get<Pageable<SharedTask>>(ENDPOINTS.share.list, {
      params,
    });
  }

  setVisibility(params: { availableToOpen: boolean; linkId: number }) {
    return axiosInstance.post(ENDPOINTS.share.visible, null, { params });
  }
}
