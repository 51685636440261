import { defineStore } from 'pinia';
import { RawTask } from '@/types/notes';
import { computed, ref } from 'vue';
import { ICreateTaskRequest } from '@/types/tasks';
import { useRawTasks } from '@/hooks/notes/useRawTasks';
import { useAudioFile } from '@/hooks/useAudioFile';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useTaskDate } from '@/hooks/useTaskDate';
import { useCreateTaskFiles } from '@/hooks/useCreateTaskFiles';
import { useNotesTransformStore } from './useNotesTransform';

export const useNoteToTaskStore = defineStore('note-to-task', () => {
  const { t } = useI18n();

  const isCreating = ref(false);
  const state = ref<ICreateTaskRequest>({} as ICreateTaskRequest);

  const { convertRawTask, createTasks } = useRawTasks();
  const { audioFile, isAudioLoading, getAudioFile } = useAudioFile();
  const { files, isFilesLoading, createFiles } = useCreateTaskFiles();
  const { dateView, setDate, setTime } = useTaskDate(state);

  const taskPriority = computed({
    get() {
      const priority = state.value.taskPriority;
      return {
        title: priority,
        value: priority,
      };
    },
    set(val) {
      state.value.taskPriority = val.value;
    },
  });

  const isStateValid = computed(() => {
    const { taskPriority, taskContent, executorId, name, deadline } =
      state.value;

    return (
      deadline &&
      taskPriority &&
      taskContent &&
      taskContent.length <= 10_000 &&
      executorId &&
      name &&
      name.length <= 75
    );
  });

  const start = async (raw: RawTask) => {
    state.value = convertRawTask(raw);
    if (raw.audioFileId) await getAudioFile(raw.audioFileId);
  };

  const notesTransformStore = useNotesTransformStore();
  const end = async () => {
    if (!state.value) return;
    isCreating.value = true;
    try {
      const filesIds = await createFiles();
      await createTasks([
        {
          ...state.value,
          filesIds,
        },
      ]);
      notesTransformStore.end(false);
      ElNotification.success({
        message: t('tasks.create_message'),
        offset: 80,
      });
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
    } finally {
      isCreating.value = false;
    }
  };

  const $reset = () => {
    state.value = {} as ICreateTaskRequest;
    audioFile.value = null;
    files.value = [];
    isCreating.value = false;
  };

  return {
    state,
    files,
    isStateValid,
    dateView,
    audioFile,
    taskPriority,
    isCreating,
    isFilesLoading,
    isAudioLoading,
    start,
    end,
    setDate,
    setTime,
    $reset,
  };
});
