import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { notesEndpoint } from '@/api/endpoints/notes';
import {
  CreateNote,
  GetAllNotesReqParams,
  Note,
  NotePlanner,
  RawTask,
} from '@/types/notes';
import { Pageable } from '@/types/pagination';

export class NotesService extends ApiService {
  getAll(params: Partial<GetAllNotesReqParams> = {}) {
    return axiosInstance.get<Pageable<Note>>(notesEndpoint.getAll, {
      params,
    });
  }

  get(id: number) {
    return axiosInstance.get<Note>(notesEndpoint.get(id));
  }

  update(data: Note[]) {
    return axiosInstance.put<Note[]>(notesEndpoint.update, data);
  }

  create(data: CreateNote) {
    return axiosInstance.post<Note>(notesEndpoint.create, data);
  }

  scheduleCalendar(data: Pick<NotePlanner, 'id' | 'companyId'>[]) {
    return axiosInstance.post<NotePlanner[]>(
      notesEndpoint.scheduleCalendar,
      data,
    );
  }

  scheduleTask(data: NotePlanner[]) {
    return axiosInstance.post<RawTask[]>(notesEndpoint.scheduleTask, data);
  }

  scheduleWorkspace(data: Pick<NotePlanner, 'id'>[]) {
    return axiosInstance.post<NotePlanner[]>(
      notesEndpoint.scheduleWorkspace,
      data,
    );
  }
}
