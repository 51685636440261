<template>
  <el-dropdown
    :teleported="false"
    trigger="click"
    type="primary"
    :placement="$attrs.placement || 'bottom'"
    ref="dropdownElem"
    id="test"
  >
    <span
      class="el-dropdown-link"
      :class="{ 'el-dropdown-link__priority': modelValue && priorityLinks }"
    >
      <slot
        name="customModelValue"
        v-if="$slots.customModelValue"
        :modelValue="modelValue"
      />
      <span v-else> {{ modelValue.title }}</span>
      <icon-chevron
        direction="bottom"
        class="el-dropdown-link-icon"
        :class="{
          'el-dropdown-link-icon__priority': modelValue && priorityLinks,
        }"
      />
    </span>
    <template #dropdown>
      <div class="dropdown">
        <div
          class="dropdown__item"
          @click="setValue(item)"
          v-for="item in items"
          :key="item.value"
        >
          <slot :item="item" />
        </div>
      </div>
    </template>
  </el-dropdown>
</template>

<script setup lang="ts">
import { PropType, ref } from 'vue';
import IconChevron from '@/components/icon/IconChevron.vue';

export interface IItemDropdown {
  value: string;
  title: string;
}

const dropdownElem = ref<{ handleClose: () => void }>();

defineProps({
  modelValue: {
    type: Object as PropType<IItemDropdown>,
    required: true,
  },
  items: {
    type: Array as PropType<IItemDropdown[]>,
    required: true,
  },
  priorityLinks: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const setValue = (item: IItemDropdown) => {
  emit('update:modelValue', item);
  if (!dropdownElem.value) return;
  dropdownElem.value.handleClose();
};
</script>

<style scoped lang="scss">
.el-dropdown {
  .el-dropdown-link {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__priority {
      padding: 0.6rem;
    }

    &-icon {
      &__priority {
        position: absolute;
        right: 1rem;
      }
    }
  }

  &-label {
    position: absolute;
    top: 0;
    left: 1.6rem;
    line-height: 0.2rem;
    font-size: 1.2rem;
    color: #1a2a39;
  }
}
</style>
