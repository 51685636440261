export const POPPER_MODIFIERS = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['left', 'right'],
      },
    },
  ],
};
