<template>
  <div class="support-sub__container">
    <span
      class="support-sub__item"
      v-for="item in itemsList"
      :key="item.id"
      @click="$emit('openModal', item.action)"
    >
      {{ item.title }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const itemsList = computed(() => {
  return [
    {
      id: 1,
      title: t('userDropDownMenu.sub_text'),
      action: 'contact',
    },
    {
      id: 2,
      title: t('support_modals.contact_us.feedback'),
      action: 'feedback',
    },
  ];
});
</script>
<style lang="scss" scoped>
.support-sub {
  &__container {
    @include flex-column(0.4rem);
  }

  &__item {
    @include f12;
    color: #b8b8d2;
    word-break: break-word;
    cursor: pointer;
    padding: 0.5rem;

    &:hover {
      @include gray-bg-hover;
    }
  }
}
</style>
