import { parents, DNDPlugin } from '@formkit/drag-and-drop';

export const dragChosenPlugin =
  ({
    handleEnd,
    handleStart,
  }: {
    handleEnd?: () => void;
    handleStart?: (id: number) => void;
  } = {}): DNDPlugin =>
  (parent) => {
    const parentData = parents.get(parent);

    if (!parentData) return;

    const entry = new Set<number>();
    let isDragging = false;

    function mousedown(
      this: HTMLElement,
      event: MouseEvent | TouchEvent | DragEvent,
    ) {
      if ('button' in event && event.button === 2) return;
      isDragging = true;
      const el = this as HTMLElement;
      const id = setTimeout(() => {
        el.classList.add('drag-chosen');
        const dataId = el.dataset['select'];
        if (dataId) handleStart?.(+dataId);
        entry.delete(id);
      }, 300);
      el.dataset['entry'] = `${id}`;
      entry.add(id);
    }

    function drop(
      this: HTMLElement,
      event: MouseEvent | TouchEvent | DragEvent,
    ) {
      if (event instanceof MouseEvent && !isDragging) return;
      const el = this as HTMLElement;
      const id = Number(el.dataset['entry']);
      if (entry.has(id)) {
        clearTimeout(id);
        entry.delete(id);
      }
      isDragging = false;
      setTimeout(() => {
        el.classList.remove('drag-chosen');
        handleEnd?.();
      });
    }

    return {
      setupNode(data) {
        data.node.addEventListener('mousedown', mousedown);
        data.node.addEventListener('dragstart', mousedown);
        data.node.addEventListener('touchstart', mousedown);

        data.node.addEventListener('dragend', drop);
        data.node.addEventListener('touchend', drop);
        data.node.addEventListener('mouseup', drop);
      },

      tearDownNode(data) {
        data.node.removeEventListener('mousedown', mousedown);
        data.node.removeEventListener('dragstart', mousedown);
        data.node.removeEventListener('touchstart', mousedown);

        data.node.removeEventListener('dragend', drop);
        data.node.removeEventListener('touchend', drop);
        data.node.removeEventListener('mouseup', drop);
      },
    };
  };
