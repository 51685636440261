<template>
  <modal-notification
    v-bind="{ ...props, ...$attrs }"
    class="notification-modal"
    @on-close="$emit('close')"
    width="45rem"
  >
    <slot />
    <template #footer>
      <slot name="footer" />
    </template>
  </modal-notification>
</template>

<script setup lang="ts">
import ModalNotification from '@/components/modals/ModalNotification.vue';

const props = defineProps<{
  isOpen: boolean;
  message?: string;
  title?: string;
}>();
defineEmits(['close']);
</script>

<style scoped lang="scss"></style>
