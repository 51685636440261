import { GoogleCalendar } from '@/types/integrations';
import { ref } from 'vue';

export type GoogleCalendarWithDirty = GoogleCalendar & {
  dirty?: boolean;
};

export const useCalendarsState = () => {
  const state = ref<GoogleCalendarWithDirty[]>([]);

  const setInitState = async (calendars: GoogleCalendarWithDirty[]) =>
    (state.value = calendars);

  const onChangeState = async (update: Partial<GoogleCalendarWithDirty>) => {
    if (!update.id) return;
    const id = state.value.findIndex((c) => c.id === update.id);
    if (id > -1)
      state.value.splice(id, 1, { ...state.value[id], ...update, dirty: true });
  };

  const resetState = () => (state.value = []);

  return { state, setInitState, onChangeState, resetState };
};
