import { createUrl } from '@/utils/url';

const commentUrl = createUrl('comment');

const getComments = commentUrl('getTaskComments');

const createComment = (id: number) => commentUrl(`add/${id || ''}`);

export const commentEndpoint = {
  getComments,
  createComment,
};
