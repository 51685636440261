<script setup lang="ts">
import { computed, ref } from 'vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import IconChevron from '@/components/icon/IconChevron.vue';
import IconMarker from '@/components/icon/IconMarker.vue';
import UiDropdownExpandable from '@/components/ui/UiDropdown/UiDropdownExpandable.vue';
import WorkspaceItem from '@/components/workspaces/WorkspaceItem.vue';
import { useWorkspaceById } from '@/hooks/useWorkspaceById';

interface Props {
  companyId?: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'select', v: number): void;
}>();

const isOpen = ref(false);

const workspacesStore = useWorkspaceStore();
const { list, ownWorkspacesList, externalList, guestWorkspacesList } =
  storeToRefs(workspacesStore);

const workspacesOptions = computed(() =>
  list.value.map((w) => ({ label: w.name, value: w.id, color: w.color })),
);

const currentWorkspace = computed(() =>
  workspacesOptions.value.find((o) => o.value === props.companyId),
);

const onSelect = (v: number) => {
  emit('select', v);
  isOpen.value = false;
};

const { externalGoogle } = useWorkspaceById(computed(() => props.companyId));
</script>

<template>
  <el-popover
    trigger="click"
    placement="bottom"
    width="30.5rem"
    :disabled="externalGoogle?.isSingleSync"
    :popper-class="$style.popper"
    v-model:visible="isOpen"
  >
    <div :class="$style.group">
      <div :class="$style.heading">Your workspaces</div>
      <div v-if="ownWorkspacesList?.length">
        <div :class="$style.listLabel">
          <img src="@images/integrations/google/voiset-logo.webp" alt="" />
          Voiset
        </div>
        <div :class="[$style.list, 'with-scroll']">
          <div v-for="owner in ownWorkspacesList" :key="owner.id">
            <WorkspaceItem
              :key="owner.id"
              :item="owner"
              :is-active="companyId === owner.id"
              @click="onSelect(owner.id)"
            />
          </div>
        </div>
      </div>
      <UiDropdownExpandable v-if="externalList.GOOGLE" static>
        <template #label>
          <div :class="[$style.listLabel]">
            <img
              alt=""
              :src="externalList.GOOGLE.config.icon"
              v-if="externalList.GOOGLE.config?.icon"
            />
            {{ externalList.GOOGLE.config?.label }}
          </div>
        </template>
        <div v-for="group in externalList.GOOGLE.content" :key="group.account">
          <UiDropdownExpandable
            :static="externalList.GOOGLE.content.length === 1"
            :is-initial-open="
              !!companyId && group.list.map((w) => w.id).includes(companyId)
            "
          >
            <template
              #label="{ isOpen }"
              v-if="externalList.GOOGLE.content.length > 1"
            >
              <div :class="[$style.listLabel, $style.sub]">
                <IconChevron :direction="isOpen ? 'bottom' : 'right'" />
                <span>{{ group.account }}</span>
              </div>
            </template>
            <div
              :style="{
                overflow: 'hidden',
                marginLeft:
                  externalList.GOOGLE.content.length > 1 ? '1rem' : '',
              }"
            >
              <div :class="[$style.list, 'with-scroll']">
                <WorkspaceItem
                  v-for="item in group.list"
                  :key="item.id"
                  :item="item"
                  :is-active="companyId === item.id"
                  :is-disabled="
                    item.externalCalendarItem?.syncCalendarMode === 'SINGLE'
                  "
                  ignore-external
                  @click="
                    item.externalCalendarItem?.syncCalendarMode !== 'SINGLE' &&
                      onSelect(item.id)
                  "
                />
              </div>
            </div>
          </UiDropdownExpandable>
        </div>
      </UiDropdownExpandable>
    </div>
    <div
      v-if="guestWorkspacesList.length"
      :class="[$style.group, $style.guest]"
    >
      <div :class="$style.heading">{{ $t('workspaces.guest') }}</div>
      <div :class="[$style.list, 'with-scroll']">
        <WorkspaceItem
          v-for="item in guestWorkspacesList"
          :key="item.id"
          :item="item"
          :is-active="companyId === item.id"
          @click="onSelect(item.id)"
        />
      </div>
    </div>
    <template #reference>
      <div :class="$style.wrapper" v-skeleton.large="!currentWorkspace">
        Workspace
        <button
          :class="[
            $style.label,
            {
              [$style.active]: isOpen,
              [$style.disabled]: externalGoogle?.isSingleSync,
            },
          ]"
          :disabled="externalGoogle?.isSingleSync"
        >
          <IconMarker :color="currentWorkspace?.color" size="0.8rem" />
          <span>{{ currentWorkspace?.label }}</span>
          <IconChevron :direction="isOpen ? 'top' : 'bottom'" size="1.6rem" />
        </button>
      </div>
    </template>
  </el-popover>
</template>

<style lang="scss" module>
.wrapper {
  @include flex-row(1rem);
  align-items: center;
  @include f14;
  transition: all 0.15s ease-in-out;
  white-space: nowrap;
}

.label {
  @include flex-row(1rem);
  align-items: center;
  padding: 0.8rem;
  @include gray-bg-hover;

  &.active {
    background: #f2f7f7;
  }

  & > span {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 17.5rem;
  }

  & > svg {
    flex-shrink: 0;
  }

  &.disabled {
    &,
    svg {
      cursor: not-allowed !important;
    }
  }
}

.popper {
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.listLabel {
  @include flex-row(0.5rem);
  align-items: center;
  @include f16;
  font-weight: 600;
  width: 100%;
  margin-bottom: 1rem;

  &.sub {
    @include f14;
    font-weight: 600;
    margin-left: 1rem;

    & > svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    & > div {
      overflow: hidden;
    }
  }

  & > img {
    width: 3rem;
    height: 3rem;
    box-shadow: 0 0 4px 0 rgba(164, 164, 164, 0.25);
    border-radius: 0.8rem;
  }
}
.list {
  @include flex-column;
  opacity: 1;
  gap: 0.6rem;
  max-height: 21.8vh;
  overflow: auto;
}

.heading {
  @include f14;
  color: #cecede;
}
.group {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &.guest {
    gap: 1rem;
  }
}
</style>
