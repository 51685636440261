import format from 'date-fns/format';
import { ISubordinate } from '@/types/subordinate';
import { concatName } from '@/utils/text';

export type SubordinateViewType = ISubordinate &
  ReturnType<typeof subordinatesMappingToView>;

export const subordinatesMappingToView = (user: ISubordinate) => ({
  ...user,
  //TODO Избавиться от переопределения createDateTime
  createDateTime: format(new Date(user.createDateTime), 'dd MMM'),
  rawCreateDateTime: user.createDateTime,
  name: {
    firstName: user.firstName,
    lastName: user.lastName,
    avatar: user.avatar,
  },
  fullName: concatName(user.firstName, user.lastName),
  positionName: user.position.name,
});
