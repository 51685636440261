<template>
  <el-dialog
    :model-value="isOpen"
    :class="[
      `modal-view modal`,
      isShare && 'modal__short',
      isShowComments && 'modal__full',
    ]"
    :modal-class="['el-overlay', isShare && 'short'].join(' ')"
    :close-on-click-modal="!isShare"
    :close-on-press-escape="!isShare"
    :fullscreen="fullscreen"
    :modal="!isShare"
    @close="isOpen && emit('onClose')"
  >
    <template #header>
      <ModalHeaderViewTask
        :is-open="isOpen"
        :is-share="isShare"
        @close="$emit('onClose')"
      />
    </template>
    <template #default>
      <ModalBodyViewTask :is-share="isShare" />
    </template>
    <template #footer>
      <ModalFooterViewTask :is-share="isShare" :is-readonly="isReadonly" />
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ref, toRef, watch } from 'vue';

import ModalBodyViewTask from '@/components/modals/ModalViewTask/ModalBodyViewTask.vue';
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';
import { storeToRefs } from 'pinia';
import { ITaskStatusItem } from '@/consts';
import ModalFooterViewTask from './ModalFooterViewTask.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { CoachName } from '@/types/coaches';
import ModalHeaderViewTask from './header/ModalHeaderViewTask.vue';

type Props = {
  fullscreen?: boolean;
  isOpen?: boolean;
  isShare?: boolean;
  status?: ITaskStatusItem;
};

const props = withDefaults(defineProps<Props>(), { status: 'CREATED' });

const isOpen = toRef(props, 'isOpen');

const modalViewTaskStore = useModalViewTaskStore();

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach } = storeToRefs(coachesStore);
const cashedCoach = ref<CoachName | null>(null);

const emit = defineEmits(['onClose']);
const { isShowComments, isReadonly } = storeToRefs(modalViewTaskStore);

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      cashedCoach.value = activeCoach.value.name;
      setCoach(props.isShare ? 'sharedTask' : 'viewTask');
    } else {
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);
</script>

<style lang="scss" scoped>
.modal-view {
  &__form {
    &-group {
      margin-bottom: 2.4rem;
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 0 3rem;
    }

    &-col {
      width: 50%;
    }

    &-input {
      cursor: pointer;
    }

    &-autocomplete {
      position: relative;
    }
  }

  &__picker {
    width: 100%;

    &-input {
      width: 100%;
    }
  }

  &__area {
    height: 20rem;
  }

  &__priority {
    background-color: #fcfcfc;
    display: flex;
    align-items: center;
    padding: 0.7rem 1.2rem;
    max-width: 27.5rem;
    border-radius: 0.8rem;

    &-title {
      margin-right: 1.2rem;
      @include f14;
    }
  }

  &__footer {
    display: flex;
    gap: 0 3.5rem;

    &-col {
      width: calc(50% - 3.5rem);
      flex-grow: 1;
    }
  }

  &__submit {
    width: 100%;
  }
}

.modal {
  height: max-content;

  &__minimize {
    width: 2.4rem;
    height: 2.4rem;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 3.2rem;

    &-line {
      display: inline-block;
      border-radius: 0.8rem;
      width: 1.75rem;
      height: 0.2rem;
      background: #1a2a39;
    }
  }

  &__body {
    overflow-y: scroll;
    flex-grow: 1;
    padding: 3rem 3rem;
  }
}
</style>
