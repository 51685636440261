import { DirectiveBinding } from 'vue';

const setSkeletonClass = (
  el: HTMLElement,
  binding: DirectiveBinding<boolean>,
) => {
  if (binding.value) {
    el.classList.add('skeleton');
    el.classList.remove('skeleton-fading');
  } else if (el.classList.contains('skeleton')) {
    el.classList.add('skeleton-fading', 'skeleton');
    setTimeout(() => el.classList.remove('skeleton-fading', 'skeleton'), 250);
  }
};

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding<boolean>) {
    if (binding.modifiers.circle) {
      el.classList.add('skeleton-circle');
    }
    if (binding.modifiers.large) {
      el.classList.add('skeleton-large');
    }
    setSkeletonClass(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding<boolean>) {
    setSkeletonClass(el, binding);
  },
  unmounted(el: HTMLElement) {
    el.classList.remove(
      'skeleton',
      'skeleton-fading',
      'skeleton-circle',
      'skeleton-large',
    );
  },
};
