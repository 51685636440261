<template>
  <div
    class="layout-app"
    :style="{ background: $route.meta.background as string }"
  >
    <LayoutHeader />
    <div class="page">
      <LayoutAside :class="['page__aside', { 'has-aside': hasAside }]" />
      <ModalCreateTask
        :is-open="modalCreateTaskStore.isModalTaskCreate"
        @on-close="
          modalCreateTaskStore.isModalTaskCreate = false;
          modalCreateTaskStore.resetTask();
        "
      />
      <ModalViewTask
        :is-open="modalTaskViewStore.isOpenModal"
        @on-close="modalTaskViewStore.clearTask"
      />
      <ModalAcceptWorkspace />
      <ModalOnboarding />
      <ModalPlanBuy
        @close="isPlansModalVisible = false"
        :is-open="isPlansModalVisible"
      />
      <ModalPlanBuy
        :title="$t('card_tariff.no_workspaces_label')"
        @close="isNoMoreWorkspacesModalVisible = false"
        :is-open="isNoMoreWorkspacesModalVisible"
      />
      <ModalCreateWorkspace
        :is-open="isCreateOpen"
        @close="isCreateOpen = false"
      />
      <CreateNoteModal v-model="createNoteStore.isStarted" />
      <NoteCardModal v-model="noteStore.isStarted" />
      <CoachActive />
      <Teleport to="body">
        <NoteTransformSteps />
      </Teleport>
      <main :class="['page__content', { 'has-aside': hasAside, padded }]">
        <slot />
      </main>
    </div>
  </div>
  <div class="layout-mobile">
    <div class="layout-mobile__content">
      <h1 class="layout-mobile__title">
        To easily use Voiset on your device, download the mobile app from the
        marketplace
      </h1>
      <div class="layout-mobile__btns">
        <a href="https://apps.apple.com/us/app/voiset/id6446641085">
          <img src="@images/app-markets/apple-btn.webp" alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.unionsmarttechnology.voiset"
        >
          <img src="@images/app-markets/google-btn.webp" alt="" />
        </a>
      </div>
      <img src="@images/phones.webp" class="layout-mobile__img" alt="" />
    </div>
    <img src="@images/bg.webp" class="layout-mobile__bg" alt="" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, provide } from 'vue';
import LayoutHeader from '@/components/layout/app/LayoutHeader.vue';
import LayoutAside from '@/components/layout/app/LayoutAside.vue';
import ModalCreateTask from '@/components/modals/CreateTask/ModalCreateTask/ModalCreateTask.vue';
import ModalViewTask from '@/components/modals/ModalViewTask/ModalViewTask.vue';
import ModalAcceptWorkspace from '@/components/modals/Workspaces/ModalAcceptWorkspace.vue';
import { useModalCreateTaskStore } from '@/stores/modalTaskCreate';
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';
import { useSettingsStore } from '@/stores/settings/settings';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import ModalPlanBuy from '@/components/modals/Billing/ModalPlanBuy.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import ModalOnboarding from '@/components/modals/ModalOnboarding.vue';
import { useCreateNoteStore } from '@/stores/notes/useCreateNoteStore';
import CreateNoteModal from '@/components/notes/create/CreateNoteModal.vue';
import NoteCardModal from '@/components/notes/card/NoteCardModal.vue';
import { useNoteStore } from '@/stores/notes/useNote';
import NoteTransformSteps from '@/components/notes/transform/NoteTransformSteps.vue';
import { useWindowSize } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useWorkspaceStore } from '@/stores/workspaces';
import { useGoogleAccounts } from '@/stores/integrations/google';
import ModalCreateWorkspace from '@/components/modals/Workspaces/Create/ModalCreateWorkspace.vue';
import CoachActive from '@/components/coaches/CoachActive.vue';

const route = useRoute();

const modalCreateTaskStore = useModalCreateTaskStore();
const modalTaskViewStore = useModalViewTaskStore();
const settingsStore = useSettingsStore();
const invitationsStore = useInvitationsStore();
const createNoteStore = useCreateNoteStore();
const noteStore = useNoteStore();
const workspacesStore = useWorkspaceStore();
const { fetchList } = workspacesStore;
const { list, isCreateOpen, isNoMoreWorkspacesModalVisible } =
  storeToRefs(workspacesStore);
const googleAccounts = useGoogleAccounts();

const tariffStore = useTariffStore();
const { isPlansModalVisible } = storeToRefs(tariffStore);

const { width: windowWidth } = useWindowSize();
provide('windowWidth', windowWidth);

const isMobileResolution = computed(() => windowWidth.value <= 1100);
provide('isMobileResolution', isMobileResolution);

const hasAside = computed(() => windowWidth.value < 1100 || route.meta.noAside);
const padded = computed(() => route.meta.padded);

onMounted(() => {
  Promise.allSettled([
    invitationsStore.fetchInternalInvitations(),
    settingsStore.getLocaleUiList(),
    !list.value.length && fetchList(),
    googleAccounts.fetchAccountsWithCalendars(),
  ]);
});
</script>

<style lang="scss" scoped>
.layout-app {
  height: inherit;
  @include flex-column;

  @include break-point(850px) {
    display: none;
  }
}

.layout-mobile {
  display: none;
  position: relative;
  overflow: hidden;
  height: 100vh;

  @include break-point(850px) {
    display: block;
  }

  &__bg {
    position: absolute;
    top: 0;
    object-fit: fill;
    width: 100%;
    height: inherit;
    z-index: -1;
  }

  &__content {
    width: 100%;
    @include flex-column;
    align-items: center;
    margin-top: 15rem;
    padding: 0 5rem;
    height: 100%;

    @include break-point(640px) {
      margin-top: 10rem;
    }
  }

  &__title {
    font-family: 'Unbounded', sans-serif;
    @include f32;
    text-align: center;
    width: inherit;
    max-width: 58.2rem;

    @include break-point(640px) {
      @include f20;
    }
  }

  &__btns {
    @include flex-row(2.4rem);
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    flex: 1;

    & > a {
      > img {
        max-width: 22rem;
        width: 100%;
        box-shadow: 0 1rem 1.6rem 0 rgba(97, 97, 97, 0.06);
        transition: all 0.15s ease-in-out;

        &:hover {
          box-shadow: none;
        }
      }
    }

    @include break-point(640px) {
      @include flex-column(2.4rem);
    }
  }

  &__img {
    width: 100%;
    margin-top: 2rem;
  }
}

.page {
  height: inherit;

  &__content {
    @include flex-column;
    position: relative;
    height: inherit;
    padding: 9rem 2.4rem 0 12.8rem;
    transition: all 0.3s ease;

    &.has-aside {
      padding-left: 2.4rem;
    }

    &.padded {
      padding: 5.8rem 0 0 0;
    }
  }

  &__aside {
    &.has-aside {
      display: none;
    }
  }
}
</style>
