import { RouteRecordRaw } from 'vue-router';
const WelcomeViewIndex = () => import('@/views/welcome.vue');

export default {
  path: '/welcome',
  name: 'Welcome',
  component: WelcomeViewIndex,
  meta: {
    layout: 'auth',
    public: true,
    image: 'welcome.webp',
    slogan: 'auth.welcome_slogan',
  },
} as RouteRecordRaw;
