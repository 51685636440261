import { createUrl } from '@/utils/url';

const purchaseLink = createUrl('/purchase/details');
const getClientSecret = purchaseLink('setupIntent');
const getTrialSubscription = purchaseLink('subscriptionTrial');
const getSubscription = purchaseLink('subscription');
const cancelSubscription = purchaseLink('cancel/subscription');
const getInvoiceHistory = purchaseLink('getInvoiceHistory');
const changeAutoSubscription = purchaseLink('changeAutoSubscription');
const getPaymentCard = purchaseLink('card');

export const purchaseEndpoint = {
  getClientSecret,
  getInvoiceHistory,
  changeAutoSubscription,
  getTrialSubscription,
  getSubscription,
  cancelSubscription,
  getPaymentCard,
};
