<template>
  <div :class="['workspace-item', { active: isActive, disabled: isDisabled }]">
    <IconMarker :color="item.color" />
    <span>{{ item.name }}</span>
    <Fade>
      <component
        v-if="externalItem?.syncCalendarMode && !ignoreExternal"
        :key="externalItem?.syncCalendarMode"
        :sync="externalItem?.syncCalendarMode"
        class="workspace-item__sync"
        :is="
          externalItem?.syncCalendarMode === 'SINGLE'
            ? OneWaySyncSmall
            : TwoWaySyncSmall
        "
        @click.stop="
          $emit('onGoogleCalendarModeChange', {
            syncCalendarMode:
              externalItem.syncCalendarMode === 'SINGLE' ? 'DUAL' : 'SINGLE',
            id: externalItem.id,
          })
        "
      />
    </Fade>
    <el-radio-group :model-value="isActive" :disabled="isDisabled">
      <el-radio :value="true" />
    </el-radio-group>
  </div>
</template>

<script setup lang="ts">
import { Workspace } from '@/types/workspaces';
import IconMarker from '@/components/icon/IconMarker.vue';
import OneWaySyncSmall from '@/components/integrations/google/settings/heplers/OneWaySyncSmall.vue';
import TwoWaySyncSmall from '@/components/integrations/google/settings/heplers/TwoWaySyncSmall.vue';
import Fade from '@/components/animation/Fade.vue';
import { computed } from 'vue';

const props = defineProps<{
  item: Workspace;
  isActive?: boolean;
  isDisabled?: boolean;
  ignoreExternal?: boolean;
}>();
defineEmits(['onGoogleCalendarModeChange']);
const externalItem = computed(() => props.item.externalCalendarItem);
</script>

<style scoped lang="scss">
.workspace-item {
  cursor: pointer;
  @include flex-row(1rem);
  align-items: center;
  padding: 0.3rem 1rem;
  @include gray-bg-hover;

  & > svg {
    flex: 0 0 auto;
  }

  & > span {
    @include text-ellipsis;
    margin-right: auto;
  }

  &.active {
    background: #f2f7f7;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  &__sync {
    margin-left: auto;
    @include gray-bg-hover(#e9eefc);
  }
}
</style>
