import { createUrl } from '@/utils/url';

const positionUrl = createUrl('web/position');

const position = positionUrl('');
const getSubordinates = positionUrl('getSubordinates');
const getUsers = positionUrl('company/users');

export const positions = {
  position,
  getSubordinates,
  getUsers,
};
