import { ref } from 'vue';
import services from '@/api/services';

export const useAudioFile = () => {
  const isAudioLoading = ref(false);

  const audioFile = ref<string | null>(null);

  const getAudioFile = async (id: number) => {
    const { data } = await services.files.getFileById(id);
    audioFile.value = URL.createObjectURL(data);
  };

  return {
    isAudioLoading,
    getAudioFile,
    audioFile,
  };
};
