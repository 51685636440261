import { createUrl } from '@/utils/url';

const url = createUrl('web/invitation');

const findAll = url('findAll');
const finishInviteRegistration = url('finishInviteRegistration');
const revokeInvite = url('revoke');
const createInvite = url('create');
const confirmLink = url('confirm');
const userInvitations = url('user/invitation');

export const invitation = {
  finishInviteRegistration,
  confirmLink,
  findAll,
  revokeInvite,
  createInvite,
  userInvitations,
};
