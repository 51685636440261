<script setup lang="ts"></script>

<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.06688 16.9009L4.40741 18.2414C4.83073 18.6647 5.43044 18.9117 5.99487 18.9117C6.55931 18.9117 7.15902 18.6647 7.58234 18.2414L15.8958 9.92793L16.3191 5.90631C16.3897 5.41242 16.0017 4.9891 15.5078 4.9891C15.4725 4.9891 15.4372 4.9891 15.4019 4.9891L11.3804 5.41242L3.06688 13.7259C2.18495 14.6078 2.18495 16.0189 3.06688 16.9009ZM12.1565 7.03518L14.52 6.78824L14.2731 9.15183L10.2634 13.1967L9.16981 14.2903L7.01791 12.1384L8.1115 11.0448L12.1565 7.03518ZM4.2663 14.9253L5.81849 13.3379L7.97039 15.4898L6.38292 17.042C6.24181 17.1831 6.06543 17.2184 5.99487 17.2184C5.92432 17.2184 5.74793 17.1831 5.60683 17.042L4.2663 15.7014C4.05464 15.4898 4.05464 15.137 4.2663 14.9253Z"
      fill="currentColor"
    />
    <path
      d="M3.62249 1.76111C3.76078 1.1908 4.5719 1.1908 4.71019 1.76111L5.09071 3.33036C5.14009 3.53398 5.29907 3.69296 5.50269 3.74234L7.07194 4.12286C7.64225 4.26115 7.64225 5.07226 7.07194 5.21056L5.50269 5.59108C5.29907 5.64045 5.14009 5.79943 5.09071 6.00305L4.71019 7.57231C4.5719 8.14262 3.76078 8.14262 3.62249 7.57231L3.24197 6.00305C3.1926 5.79943 3.03361 5.64045 2.83 5.59108L1.26074 5.21056C0.69043 5.07226 0.690429 4.26115 1.26074 4.12286L2.83 3.74234C3.03361 3.69296 3.1926 3.53398 3.24197 3.33036L3.62249 1.76111Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16634 2.36792L3.89459 3.48861C3.78597 3.93657 3.43621 4.28633 2.98825 4.39496L1.86756 4.66671L2.98825 4.93846C3.43621 5.04708 3.78597 5.39684 3.89459 5.8448L4.16634 6.96549L4.43809 5.8448C4.54672 5.39684 4.89647 5.04708 5.34444 4.93846L6.46513 4.66671L5.34444 4.39496C4.89647 4.28633 4.54672 3.93657 4.43809 3.48861L4.16634 2.36792ZM4.71019 1.76111C4.5719 1.1908 3.76078 1.1908 3.62249 1.76111L3.24197 3.33036C3.1926 3.53398 3.03361 3.69296 2.83 3.74234L1.26074 4.12286C0.690429 4.26115 0.69043 5.07226 1.26074 5.21056L2.83 5.59108C3.03361 5.64045 3.1926 5.79943 3.24197 6.00305L3.62249 7.57231C3.76078 8.14262 4.5719 8.14262 4.71019 7.57231L5.09071 6.00305C5.14009 5.79943 5.29907 5.64045 5.50269 5.59108L7.07194 5.21056C7.64225 5.07226 7.64225 4.26115 7.07194 4.12286L5.50269 3.74234C5.29907 3.69296 5.14009 3.53398 5.09071 3.33036L4.71019 1.76111Z"
      fill="currentColor"
    />
    <path
      d="M16.2585 14.9875C16.3622 14.5598 16.9705 14.5598 17.0742 14.9875L17.3596 16.1644C17.3967 16.3172 17.5159 16.4364 17.6686 16.4734L18.8455 16.7588C19.2733 16.8625 19.2733 17.4709 18.8455 17.5746L17.6686 17.86C17.5159 17.897 17.3967 18.0163 17.3596 18.169L17.0742 19.3459C16.9705 19.7736 16.3622 19.7736 16.2585 19.3459L15.9731 18.169C15.936 18.0163 15.8168 17.897 15.6641 17.86L14.4871 17.5746C14.0594 17.4709 14.0594 16.8625 14.4871 16.7588L15.6641 16.4734C15.8168 16.4364 15.936 16.3172 15.9731 16.1644L16.2585 14.9875Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6663 15.4426L16.4625 16.2831C16.3811 16.6191 16.1187 16.8814 15.7828 16.9629L14.9423 17.1667L15.7828 17.3705C16.1187 17.452 16.3811 17.7143 16.4625 18.0503L16.6663 18.8908L16.8702 18.0503C16.9516 17.7143 17.2139 17.452 17.5499 17.3705L18.3904 17.1667L17.5499 16.9629C17.2139 16.8814 16.9516 16.6191 16.8702 16.2831L16.6663 15.4426ZM17.0742 14.9875C16.9705 14.5598 16.3622 14.5598 16.2585 14.9875L15.9731 16.1644C15.936 16.3172 15.8168 16.4364 15.6641 16.4734L14.4871 16.7588C14.0594 16.8625 14.0594 17.4709 14.4871 17.5746L15.6641 17.86C15.8168 17.897 15.936 18.0163 15.9731 18.169L16.2585 19.3459C16.3622 19.7736 16.9705 19.7736 17.0742 19.3459L17.3596 18.169C17.3967 18.0163 17.5159 17.897 17.6686 17.86L18.8455 17.5746C19.2733 17.4709 19.2733 16.8625 18.8455 16.7588L17.6686 16.4734C17.5159 16.4364 17.3967 16.3172 17.3596 16.1644L17.0742 14.9875Z"
      fill="currentColor"
    />
    <path
      d="M19.1663 2.16671C19.1663 2.62694 18.7932 3.00004 18.333 3.00004C17.8728 3.00004 17.4997 2.62694 17.4997 2.16671C17.4997 1.70647 17.8728 1.33337 18.333 1.33337C18.7932 1.33337 19.1663 1.70647 19.1663 2.16671Z"
      fill="currentColor"
    />
    <path
      d="M10.833 2.16671C10.833 2.62694 10.4599 3.00004 9.99967 3.00004C9.53944 3.00004 9.16634 2.62694 9.16634 2.16671C9.16634 1.70647 9.53944 1.33337 9.99967 1.33337C10.4599 1.33337 10.833 1.70647 10.833 2.16671Z"
      fill="currentColor"
    />
    <path
      d="M3.33301 10.5C3.33301 10.9603 2.95991 11.3334 2.49967 11.3334C2.03944 11.3334 1.66634 10.9603 1.66634 10.5C1.66634 10.0398 2.03944 9.66671 2.49967 9.66671C2.95991 9.66671 3.33301 10.0398 3.33301 10.5Z"
      fill="currentColor"
    />
  </svg>
</template>
