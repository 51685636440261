<template>
  <ui-modal
    :is-open="$props.isOpen"
    :fullscreen="fullscreen"
    @on-close-click="handleCloseConfirm"
    :title="$t('create_modal_task.title')"
    class="modal-create"
    :no-scroll="isCoachesEnabled"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :modal="modal"
    :close-disabled="!audio.isRecording"
  >
    <template #headerTitle>
      <slot name="headerTitle">
        <HeaderWorkspaceSelect
          :company-id="modalTaskCreateData.companyId"
          @select="modalTaskCreateData.companyId = $event"
        />
      </slot>
    </template>
    <template #headerActions>
      <div
        :class="['modal-create__smart', { disabled: isDisabledSmartPlanning }]"
        @click="modalCreateTaskStore.parseDescription"
      >
        {{ $t('create_modal_task.smart_planning') }}<icon-smart />
      </div>
      <el-tooltip
        :content="$t('clear_all')"
        effect="customized"
        placement="top"
      >
        <button
          class="modal-create__reset"
          @click="modalCreateTaskStore.resetTask"
          :disabled="audio.isRecording"
        >
          <icon-trash />
        </button>
      </el-tooltip>
      <widget-coach />
    </template>
    <template #body>
      <ModalNotification
        :is-open="isConfirmClosingOpen"
        :title="$t('confirm_close.title')"
        :message="$t('confirm_close.description')"
        @on-close="isConfirmClosingOpen = false"
        @on-confirm="handleClose"
      />
      <form class="modal-create__form" @submit.prevent="createTask">
        <div class="modal-create__form-row" id="task-assigment">
          <div
            class="modal-create__form-col modal-create__form-autocomplete"
            v-click-outside="hideAutocomplete"
          >
            <ui-input
              :is-smart-value="dirtyState.executor"
              @input="dirtyState.executor = false"
              :disabled="isDisabledField"
              :label="$t('modal_task.to')"
              class="modal-create__form-input"
              :model-value="modalTaskCreateData.executor?.fullName"
              :icon="modalTaskCreateData.executor ? 'user-change' : 'contact'"
              icon-align="right"
              readonly
              @click="isOpenAutoComplete = true"
            />
            <ui-autocomplete-users
              v-if="subordinates?.length"
              :items="subordinates"
              :is-open="isOpenAutoComplete"
              v-model:search="searchUsers"
              @select-item="selectUser"
            />
          </div>
          <div class="modal-create__form-col">
            <popup-task-date-picker
              :model-value="modalTaskCreateData.deadline"
              :endTime="modalTaskCreateData.deadlineLast"
              :reminder="modalTaskCreateData.notifyTime"
              :date-view="getDateView"
              :executor="modalTaskCreateData.executor"
              :disabled="isDisabledField || !modalTaskCreateData.executor?.id"
              :is-smart-value="dirtyState.deadline"
              @update:time="
                setTime($event);
                dirtyState.deadline = false;
              "
              @update:modelValue="
                setDate($event);
                dirtyState.deadline = false;
              "
            />
          </div>
        </div>
        <div class="modal-create__form-row">
          <ui-input
            :is-smart-value="dirtyState.name"
            @input="dirtyState.name = false"
            :disabled="isDisabledField"
            :class="[
              'modal-create__wide-input',
              { error: modalTaskCreateData.name.length > 75 },
            ]"
            v-model="modalTaskCreateData.name"
            :label="$t('modal_task.task_name_placeholder')"
          />
          <ui-dropdown-priority
            class="priority-dropdown modal-create__dropdown"
            v-model="modalTaskCreateData.taskPriority"
            :items="priorityOptions"
            :is-smart-value="dirtyState.taskPriority"
            @update:model-value="dirtyState.taskPriority = false"
          />
        </div>
        <!--        <PopupTaskScheduleCall :isCreateOpen="isOpen" />-->
        <ui-input
          :highlighted="!modalTaskCreateData.taskContent.length"
          :class="[
            'modal-create__area',
            { error: modalTaskCreateData.taskContent.length > 10_000 },
          ]"
          @update:model-value="modalTaskCreateData.taskContent = $event"
          :model-value="taskContentValue"
          type="textarea"
          :placeholder="$t('modal_task.description_placeholder')"
          rows="8"
          style="resize: vertical"
          ref="contentInput"
        />
        <ui-uploader-files v-model="modalTaskCreateData.files" />
        <input type="submit" hidden />
      </form>
    </template>
    <template #footer>
      <modal-create-task-footer
        @click-submit="createTask"
        :isDisabledSubmit="isDisabledSubmit"
        :isOpenModal="isOpen"
      />
    </template>
  </ui-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, computed } from 'vue';

import UiModal from '@/components/ui/UiModal.vue';
import UiAutocompleteUsers from '@/components/ui/UiAutocomplete/UiAutocompleteUsers.vue';
import UiDropdownPriority from '@/components/ui/UiDropdown/UiDropdownPriority.vue';
import UiUploaderFiles from '@/components/ui/UiUploaderFiles.vue';

import { useModalCreateTaskStore } from '@/stores/modalTaskCreate';
import ModalCreateTaskFooter from './ModalCreateTaskFooter.vue';
import { storeToRefs } from 'pinia';
import { SubordinateViewType } from '@/utils/subordinates';
import { priorityOptions } from '@/consts';
import { useTasksStore } from '@/stores/tasks/tasks';
import { CoachName } from '@/types/coaches';
import { useCoachesStore } from '@/stores/coaches/coaches';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import PopupTaskDatePicker from '@/components/popups/PopupTaskDatePicker.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import IconTrash from '@/components/icon/IconTrash.vue';
import UiInput from '@/components/control/UiInput.vue';
import IconSmart from '@/components/icon/IconSmart.vue';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { watchPausable } from '@vueuse/core';
// import PopupTaskScheduleCall from '@/components/popups/scheduleCall/PopupTaskScheduleCall.vue';
import { useUserStore } from '@/stores/user';
import { onBeforeMountAndWorkspaceChange } from '@/hooks/onBeforeMountAndWorkspaceChange';
import HeaderWorkspaceSelect from '@/components/modals/ModalViewTask/header/HeaderWorkspaceSelect.vue';

const props = withDefaults(
  defineProps<{
    fullscreen?: boolean;
    isOpen: boolean;
    modal?: boolean;
  }>(),
  { fullscreen: false, isOpen: false, modal: true },
);
const emit = defineEmits(['onClose']);

const coachesStore = useCoachesStore();
const { setCoach } = coachesStore;
const { activeCoach, isCoachesEnabled } = storeToRefs(coachesStore);

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const modalCreateTaskStore = useModalCreateTaskStore();
const { hasSmartResponse, dirtyState, pendingSmartResponse, audio, isChanged } =
  storeToRefs(modalCreateTaskStore);
const tasksStore = useTasksStore();

const workspacesStore = useWorkspaceStore();
const { currentWorkspace } = storeToRefs(workspacesStore);

const isOpen = toRef(props, 'isOpen');
const cashedCoach = ref<CoachName | null>(null);
const searchUsers = ref('');
const isDisabledSubmit = ref(false);
const isOpenAutoComplete = ref(false);

const isDisabledField = computed(
  () =>
    !hasSmartResponse.value && !modalTaskCreateData.value.taskContent.length,
);

const isDisabledSmartPlanning = computed(
  () =>
    hasSmartResponse.value ||
    !modalTaskCreateData.value.taskContent.length ||
    audio.value.isRecording ||
    pendingSmartResponse.value,
);

const isConfirmClosingOpen = ref(false);

const handleCloseConfirm = () => {
  if (!isChanged.value) {
    handleClose(true);
    return;
  }
  isConfirmClosingOpen.value = true;
};

const handleClose = (value: boolean) => {
  isConfirmClosingOpen.value = false;
  emit('onClose', value);
};

const selectUser = (user: SubordinateViewType) => {
  modalTaskCreateData.value.executor = user;
  isOpenAutoComplete.value = false;
};
const hideAutocomplete = () => {
  isOpenAutoComplete.value = false;
};

const createTask = async () => {
  if (isDisabledSubmit.value) return;
  isDisabledSubmit.value = true;
  await modalCreateTaskStore.createTask();
  emit('onClose');
  isDisabledSubmit.value = false;

  await tasksStore.getTodoCount();
};

const { setDate, setTime, getSubordinates } = modalCreateTaskStore;
const {
  getDateView,
  modalTaskCreateData,
  subordinates,
  isSubordinatesFetching,
} = storeToRefs(modalCreateTaskStore);

const taskContentValue = computed(() =>
  audio.value.isRecording
    ? `${modalTaskCreateData.value.taskContent} ${
        audio.value.runtimeSpeech || ''
      }`.trim()
    : modalTaskCreateData.value.taskContent,
);

const { pause, resume } = watchPausable(
  () => [currentWorkspace.value?.id, modalTaskCreateData?.value?.companyId],
  () => !isSubordinatesFetching.value && getSubordinates(),
);
pause();

const contentInput = ref<InstanceType<typeof UiInput> | null>(null);

watch(
  () => contentInput.value?.input,
  () => contentInput.value?.input?.focus(),
);

watch(
  () => isOpen.value,
  (value) => {
    if (value) {
      getSubordinates().then(() => {
        modalCreateTaskStore.initStateChanged();
        resume();
      });
      cashedCoach.value = activeCoach.value.name;
      setCoach('createTask');
    } else {
      pause();
      modalCreateTaskStore.resetStateChanged();
      cashedCoach.value && setCoach(cashedCoach.value);
    }
  },
);

onBeforeMountAndWorkspaceChange(async () => {
  userData.value?.currentCompany &&
    (modalTaskCreateData.value.companyId = userData.value?.currentCompany);
});
</script>

<style scoped lang="scss">
.modal-create {
  &__form {
    @include flex-column(2rem);
    margin-bottom: 2rem;

    &-row {
      @include flex-row(2rem);
      align-items: center;
    }

    &-col {
      width: 50%;
    }
    &-input {
      cursor: pointer;
    }
    &-autocomplete {
      position: relative;
    }
  }

  &__dropdown {
    max-width: 14rem;
  }

  &__wide-input {
    width: 100%;
  }

  &__reset {
    @include flex-row;
    padding: 1rem;
    @include gray-bg-hover;
    border-radius: 50%;
    cursor: pointer;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }

    & > svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &__smart {
    @include flex-row(1rem);
    justify-content: center;
    align-items: center;
    padding: 0.8rem 1.6rem;
    @include f12;
    border-radius: 0.8rem;
    border: 0.1rem solid #a3c0f9;
    background: #fcfcfc;
    box-shadow: 0 0 5px 0 var(--color-primary);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    margin-right: 1rem;

    & > svg {
      color: #a3c0f9;
    }

    &:hover {
      border-color: var(--color-primary);
      box-shadow: 0 0 5px 0 var(--color-primary);

      & > svg {
        color: var(--color-primary);
      }
    }

    &.disabled {
      pointer-events: none;
      border-color: #cecede;
      color: #cecede;
      box-shadow: unset;

      & > svg {
        color: #cecede;
      }
    }
  }
}
</style>
