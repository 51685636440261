<template>
  <div id="comments_list" ref="comments" class="modal-view-task-list">
    <el-skeleton
      v-if="isLoadingComment"
      animated
      class="modal-view-task-list__skeleton"
    >
      <template #template>
        <el-skeleton-item variant="text" />
        <el-skeleton-item variant="text" />
      </template>
    </el-skeleton>
    <transition appear mode="out-in" name="fade">
      <div
        v-if="commentsList && commentsList?.length"
        class="modal-view-task-list__container"
      >
        <div
          v-for="item in commentsList"
          :key="item.block"
          class="modal-view-task-list__block"
        >
          <div class="modal-view-task-list__date">{{ item.date }}</div>
          <ModalViewCommentsItem
            v-for="{
              createTime,
              avatar,
              message,
              creatorFullName,
              creatorId,
            } in item.messages"
            :key="createTime"
            :avatar="avatar"
            :create-time="createTime"
            :creator-full-name="creatorFullName"
            :is-my-comment="creatorId === userData?.id"
            :message="message"
          />
        </div>
      </div>
      <div v-else-if="!commentsList && !isLoadingComment">
        {{ $t('modal_task.empty_comments') }}
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup>
import { watchOnce } from '@vueuse/core';
import { useModalViewTaskCommentsStore } from '@/stores/modalTaskView/modalViewTaskComments';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { nextTick, onBeforeUnmount, ref } from 'vue';

import ModalViewCommentsItem from '@/components/modals/ModalViewTask/ModalViewCommentsItem.vue';

const comments = ref();
const modalViewTaskCommentsStore = useModalViewTaskCommentsStore();

const { commentsList, isLoadingComment } = storeToRefs(
  modalViewTaskCommentsStore,
);
const { userData } = storeToRefs(useUserStore());

const nextLoading = () => {
  if (comments.value.scrollTop < 200) {
    modalViewTaskCommentsStore.loadNextPage();
  }
};

watchOnce(
  () => commentsList.value,
  () => {
    if (commentsList.value) {
      nextTick(() => {
        comments.value.scrollBy({
          top: comments.value.scrollHeight,
          behavior: 'smooth',
        });

        setTimeout(() => {
          comments.value.addEventListener('scroll', nextLoading);
        }, 2000);
      });
    }
  },
);

onBeforeUnmount(() => {
  comments.value.removeEventListener('scroll', nextLoading);
});
</script>
<style lang="scss" scoped>
.modal-view-task-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 2rem 0 13rem;
  max-height: calc(88vh - 130px);
  min-width: 30rem;
  height: auto;

  &__skeleton {
    padding: 0 1.8rem;

    > * {
      height: 5rem;
      width: 100%;
    }
  }

  &__container {
    width: 100%;
  }

  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__date {
    @include f12;
    padding: 0.4rem 1.2rem;
    min-width: 6.4rem;
    min-height: 2.6rem;
    background: rgba(184, 184, 210, 0.2);
    border-radius: 16px;
    color: #1a2a39;
    margin-bottom: 2rem;
    position: sticky;
    top: 0;
  }
}
</style>
