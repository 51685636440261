import { useNoteCalendarGrouping } from '@/hooks/notes/useNoteCalendarGrouping';
import { defineStore } from 'pinia';
import { Note, NotePlanner } from '@/types/notes';
import { useAsyncState } from '@vueuse/core';
import { Workspace } from '@/types/workspaces';
import { useWorkspaceStore } from '@/stores/workspaces';
import { groupBy } from '@/utils/object';
import { Task } from '@/types/tasks';
import { computed, ref } from 'vue';
import services from '@/api/services';
import { useUserStore } from '@/stores/user';

export interface NotesByCalendarDay {
  date: string;
  items: (Note | Task)[];
}

export interface NotesByCalendar {
  workspace: Workspace;
  days: NotesByCalendarDay[];
}

export interface NotesByWorkspace {
  workspace: Workspace;
  notes: Note[];
}

export const useNotesByCalendar = defineStore('notes-by-calendar', () => {
  const workspacesStore = useWorkspaceStore();
  const {
    state: groups,
    isLoading,
    execute,
  } = useAsyncState<
    NotesByWorkspace[],
    [planners: NotePlanner[], notes: Note[]],
    false
  >(
    async (planners, notes) => {
      const { data } = await services.notes.scheduleCalendar(
        planners.map(({ companyId, id }) => ({ companyId, id })),
      );

      const groupedByWorkspace = groupBy(
        data,
        ({ companyId }) => companyId as number,
      );

      const groups = Object.entries(groupedByWorkspace).map(
        ([workspaceId, planners]) => {
          const workspace: Workspace | undefined = workspacesStore.list.find(
            ({ id }) => id === +workspaceId,
          );
          if (!workspace) throw new Error('Workspace not found');

          return {
            workspace,
            notes: planners
              .map((p) => {
                const note = notes.find((note) => p.id === note.id)!;
                note.planner = p;
                return note;
              })
              .filter((item) => !!item),
          };
        },
      );

      return groups;
    },
    [],
    { immediate: false, shallow: false },
  );

  const {
    currentGroup,
    nextWorkspace,
    isLastWorkspace,
    calendar,
    isGroupLoading,
  } = useNoteCalendarGrouping(groups);

  const isAllLoading = computed(() => isLoading.value || isGroupLoading.value);

  const initialWorkspace = ref<number>(-1);
  const workspaceStore = useWorkspaceStore();
  const userStore = useUserStore();
  const start = async (planners: NotePlanner[], notes: Note[]) => {
    if (!userStore.userData) return;
    initialWorkspace.value = userStore.userData.currentCompany;
    await execute(0, planners, notes);
    nextWorkspace();
  };

  const end = async (): Promise<NotePlanner[]> => {
    if (initialWorkspace.value !== -1) {
      await workspaceStore.setCurrent(initialWorkspace.value);
      await userStore.getUserData();
    }
    const payload = groups.value
      .map((g) => g.notes.map((n) => n.planner))
      .flat(2)
      .filter((p) => !!p) as NotePlanner[];
    groups.value = [];
    currentGroup.value = null;
    initialWorkspace.value = -1;
    return payload;
  };

  return {
    groups,
    isLoading,
    isGroupLoading,
    isAllLoading,
    currentGroup,
    isLastWorkspace,
    calendar,

    nextWorkspace,
    start,
    end,
  };
});
