<template>
  <modal-notification @on-close="$emit('close')" width="34rem" is-open>
    <template #header>
      <slot name="header">
        <h3 class="workspaces-create-switch__header">
          {{ $t('workspaces.switch.title') }}
        </h3>
      </slot>
    </template>
    <template #default v-if="$slots.default">
      <slot name="default" />
    </template>
    <template #footer>
      <div class="workspaces-create-switch__footer">
        <ui-button plain @click="$emit('close')">
          {{ $t('workspaces.switch.stay') }}
        </ui-button>
        <ui-button @click="onSwitch" :is-loading="pending">
          {{ $t('workspaces.switch.switch') }}
        </ui-button>
      </div>
    </template>
  </modal-notification>
</template>

<script setup lang="ts">
import { Workspace } from '@/types/workspaces';
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import UiButton from '@/components/control/UiButton.vue';
import { useUserStore } from '@/stores/user';
import { ref } from 'vue';

type Props = {
  workspace?: Workspace;
};

const props = defineProps<Props>();
const emits = defineEmits(['close']);

const pending = ref(false);

const workspacesStore = useWorkspaceStore();
const { setCurrent } = workspacesStore;

const userStore = useUserStore();
const { getUserData } = userStore;

const onSwitch = async () => {
  if (!props.workspace) return;
  try {
    pending.value = true;
    await setCurrent(props.workspace.id);
    await getUserData();
    emits('close');
  } catch (e) {
    console.error(e);
  } finally {
    pending.value = false;
  }
};
</script>

<style scoped lang="scss">
.workspaces-create-switch {
  &__header {
    @include f16;
  }

  &__footer {
    @include flex-row(1.2rem);
    width: 100%;

    & > * {
      flex: 1;
    }
  }
}
</style>
