<script setup lang="ts">
import { computed, inject, nextTick, provide, Ref, ref, watch } from 'vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import { templateRef } from '@vueuse/core';
import {
  autoPlacement,
  autoUpdate,
  offset,
  shift,
  useFloating,
} from '@floating-ui/vue';
import { scrollToSelector } from '@/utils/scroll';

const coachesStore = useCoachesStore();
const { activeCard, isCoachesVisible } = storeToRefs(coachesStore);

const card = templateRef<HTMLElement>('card');
const reference = ref<Element | null>(null);

const middleware = ref([
  offset(25),
  autoPlacement(),
  shift({ padding: 5, crossAxis: true }),
]);

const { floatingStyles, placement } = useFloating(reference, card, {
  middleware,
  whileElementsMounted: autoUpdate,
});

provide(
  'position',
  computed(() => placement.value),
);

const HIGHLIGHT_CLASS = 'coach-highlight';
watch(
  [() => activeCard.value, () => isCoachesVisible.value],
  async ([current, enabled], [prev]) => {
    await prev?.onAfter?.();
    if (prev?.attachTo) {
      const prevRef = document.querySelector(`#${prev.attachTo}`);
      prevRef?.classList.remove(HIGHLIGHT_CLASS);
    }

    if (!current) return;
    await current?.onBefore?.();
    const currentRef = document.querySelector(`#${current.attachTo}`);
    if (!enabled) return currentRef?.classList.remove(HIGHLIGHT_CLASS);

    await nextTick(() => scrollToSelector(`#${current.attachTo}`));
    currentRef?.classList.add(HIGHLIGHT_CLASS);
    reference.value = currentRef;
  },
  { immediate: true, deep: true },
);

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');
watch(
  () => isMobileResolution?.value,
  () => {
    if (!activeCard.value?.attachTo) return;
    coachesStore.disableCoaches();
    document
      .querySelector(`#${activeCard.value.attachTo}`)
      ?.classList.remove(HIGHLIGHT_CLASS);
  },
);
</script>

<template>
  <Component
    :is="activeCard.component"
    :style="floatingStyles"
    v-if="isCoachesVisible"
    ref="card"
  />
</template>

<style scoped lang="scss"></style>
