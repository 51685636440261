import { computed, ref, Ref, shallowRef } from 'vue';
import services from '@/api/services';
import { Note } from '@/types/notes';

export const useNoteStateGenerator = ({
  state,
}: {
  state: Ref<Pick<Note, 'title' | 'description'>>;
}) => {
  const generatorState = ref<Pick<Note, 'title' | 'description'>>({
    title: '',
    description: '',
  });
  const isGenerating = shallowRef(false);
  const isGenerated = shallowRef(false);

  const fieldIsGenerated = computed(
    () => (field: keyof Pick<Note, 'title' | 'description'>) => {
      if (field === 'title') {
        const value = generatorState.value.title;
        return value.length > 0 && state.value.title === value;
      }
      if (field === 'description') {
        const value = generatorState.value.description;
        return value.length > 0 && state.value.description === value;
      }
    },
  );

  const generate = async (description: string) => {
    isGenerating.value = true;
    try {
      const { data } = await services.tasks.sendDescription(description);
      generatorState.value.title = data.titleRequest;
      state.value.title = data.titleRequest;
    } catch (e) {
      console.error(e);
    } finally {
      isGenerated.value = true;
      isGenerating.value = false;
    }
  };

  const resetGenerator = () => {
    generatorState.value = { title: '', description: '' };
    isGenerating.value = false;
    isGenerated.value = false;
  };

  return {
    generatorState,
    isGenerating,
    isGenerated,
    fieldIsGenerated,
    generate,
    resetGenerator,
  };
};
