<template>
  <div class="modal-body-view" :class="{ divided: isShowComments }">
    <div class="modal-body-view__container">
      <div class="modal-body-view__group modal-body-view__row">
        <div
          v-click-outside="hideAutocomplete"
          v-skeleton.large="isTaskLoading"
          style="position: relative"
        >
          <ui-input
            :label="$t('modal_task.to')"
            icon="user-change"
            icon-align="right"
            :model-value="executor"
            readonly
            @click="isOpenAutoComplete = true"
          />
          <ui-autocomplete-users
            v-if="subordinates && !isShare && !isReadonly"
            :items="subordinates"
            :is-open="isOpenAutoComplete"
            v-model:search="searchUsers"
            @select-item="selectUser"
          />
        </div>
        <div class="modal-body-view__col" v-skeleton.large="isTaskLoading">
          <popup-task-date-picker
            :model-value="activeTask.deadline || ''"
            :endTime="activeTask.deadlineLast"
            :reminder="activeTask.timeNotification"
            :date-view="getDateView"
            :executor="activeTask?.executor"
            :is-share="isShare"
            :is-readonly="isReadonly"
            @update:time="
              setTime($event);
              hasChanges = true;
            "
            @update:modelValue="
              setDate($event);
              hasChanges = true;
            "
          />
        </div>
      </div>
      <div class="modal-body-view__group modal-body-view__row">
        <ui-input
          v-skeleton.large="isTaskLoading"
          :model-value="activeTask.name"
          @update:model-value="setActiveTaskFields('name', $event)"
          maxLength="75"
          :label="$t('modal_task.task_name')"
          :readonly="isReadonly"
        />
        <ui-dropdown-priority
          v-skeleton.large="isTaskLoading"
          class="priority-dropdown"
          style="max-width: 14rem"
          :disabled="isReadonly"
          :model-value="{
            title: activeTask.taskPriority,
            value: activeTask.taskPriority,
          }"
          @update:model-value="
            setActiveTaskFields('taskPriority', $event.value)
          "
          :items="priorityOptions"
        />
      </div>
      <div class="modal-body-view__group" id="view-share">
        <ui-input
          class="modal-body-view__description"
          v-skeleton.large="isTaskLoading"
          :model-value="activeTask.taskContent"
          @update:model-value="setActiveTaskFields('taskContent', $event)"
          type="textarea"
          :readonly="isReadonly"
          maxLength="10000"
          style="resize: vertical"
          rows="8"
        />
      </div>
      <modal-view-task-files
        v-skeleton="isFileLoading"
        :is-readonly="isReadonly"
        class="modal-body-view__group modal-body-view__upload"
      />
    </div>
    <div class="modal-body-view__comments">
      <modal-view-comments-list />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, toRef, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';
import ModalViewTaskFiles from '@/components/modals/ModalViewTask/ModalViewTaskFiles.vue';
import ModalViewCommentsList from '@/components/modals/ModalViewTask/ModalViewCommentsList.vue';
import UiInput from '@/components/control/UiInput.vue';
import UiAutocompleteUsers from '@/components/ui/UiAutocomplete/UiAutocompleteUsers.vue';
import { useModalCreateTaskStore } from '@/stores/modalTaskCreate';
import { SubordinateViewType } from '@/utils/subordinates';
import { concatName } from '@/utils/text';
import UiDropdownPriority from '@/components/ui/UiDropdown/UiDropdownPriority.vue';
import { priorityOptions } from '@/consts';
import PopupTaskDatePicker from '@/components/popups/PopupTaskDatePicker.vue';

const props = defineProps<{ isShare?: boolean }>();
const isShare = toRef(props, 'isShare');

const runtimeValue = ref('');

const modalViewTaskStore = useModalViewTaskStore();
const {
  isReadonly,
  activeTask,
  isTaskLoading,
  isFileLoading,
  isShowComments,
  hasChanges,
  getDateView,
} = storeToRefs(modalViewTaskStore);

const { setActiveTaskFields, setDate, setTime } = modalViewTaskStore;

const modalCreateTaskStore = useModalCreateTaskStore();
const { getSubordinates } = modalCreateTaskStore;
const { subordinates, isSubordinatesFetching } =
  storeToRefs(modalCreateTaskStore);

const executor = computed(() =>
  concatName(
    activeTask.value.executor?.firstName,
    activeTask.value.executor?.lastName,
  ),
);
const isOpenAutoComplete = ref(false);
const searchUsers = ref('');

const selectUser = (user: SubordinateViewType) => {
  if (user.id === activeTask.value.executor?.id)
    return (isOpenAutoComplete.value = false);
  setActiveTaskFields('executor', {
    ...user,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
  });
  isOpenAutoComplete.value = false;
};

const hideAutocomplete = () => {
  isOpenAutoComplete.value = false;
};

watch(
  () => activeTask.value.id,
  () => {
    runtimeValue.value = activeTask.value.taskContent || '';
  },
);

onMounted(
  () => !isShare.value && !isSubordinatesFetching.value && getSubordinates(),
);
</script>
<style lang="scss" scoped>
.modal-body-view {
  display: grid;
  grid-template-columns: 1fr 0fr;
  height: 100%;
  transition: all 0.5s ease-in-out;
  overflow-x: auto;

  &.divided {
    grid-template-columns: 64fr 39fr;

    @media (max-width: 850px) {
      grid-template-columns: 0fr 1fr;
    }
  }

  &__container {
    display: grid;
    grid-auto-rows: min-content;
    gap: 2rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 3rem 0 4rem;
    margin-bottom: 7rem;
    background: #fcfcfc;
  }

  &__skeleton {
    > * {
      height: 5rem;
      width: 100%;
    }
  }

  &__comments {
    display: grid;
    overflow: hidden;
    height: 100%;
    border-left: 0.2rem solid transparent;
    transition: border-left-color 0.5s ease-in-out;

    .divided & {
      border-left: 0.2rem solid #dde3ec;
    }
  }

  &__group {
    margin: 0 3rem;

    @media (max-width: 640px) {
      margin: 0 1.6rem;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    column-gap: 2rem;

    @media (max-width: 640px) {
      flex-direction: column;
      row-gap: 2rem;
    }

    > * {
      flex: 1;
      min-width: fit-content;

      @media (max-width: 640px) {
        min-width: 100%;
      }
    }
  }

  &__col {
    width: 50%;
  }

  &__upload {
    min-height: 18.5rem;
  }
}
</style>
