<template>
  <div class="lang-recognition">
    <h3 class="lang-recognition__header">
      {{ $t('create_modal_task.change_lang') }}
    </h3>
    <ui-input v-model="searchLang" :label="$t('search')" icon="search" />
    <transition name="fade" appear mode="out-in">
      <div class="lang-recognition__list" v-if="!setLangPending">
        <div
          v-for="lang in availableList"
          :key="lang.id"
          class="lang-recognition__list-item"
          @click="selectVoiceRecognition(lang.name)"
        >
          {{ lang.name }}
        </div>
      </div>
      <ui-spinner v-else />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const settingsStore = useSettingsStore();

const { voiceRecognitionList } = storeToRefs(settingsStore);

const searchLang = ref('');
const setLangPending = ref(false);

const emit = defineEmits(['close']);

const availableList = computed(() =>
  voiceRecognitionList.value.filter((lang) =>
    lang.name.toLowerCase().includes(searchLang.value),
  ),
);

const selectVoiceRecognition = async (lang: string) => {
  setLangPending.value = true;
  try {
    settingsStore.selectVoiceRecognition = lang;
    await settingsStore.saveVoiceRecognition();
    emit('close');
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    setLangPending.value = false;
  }
};

onMounted(async () => {
  await settingsStore.getLocaleVoiceData();
  settingsStore.finishLoad = true;
});
</script>
<style lang="scss" scoped>
.lang-recognition {
  @include flex-column(1rem);
  width: 100%;
  height: 25rem;

  &__header {
    @include f14;
  }

  &__list {
    @include flex-column;
    overflow-y: scroll;
    height: 100%;
    max-height: 22rem;

    &-item {
      @include f14;
      @include flex-row;
      align-items: center;
      height: 4rem;
      width: 100%;
      padding: 1rem 0.8rem;
      border-radius: 0.8rem;
      @include gray-bg-hover;
      cursor: pointer;
    }
  }
}
</style>
