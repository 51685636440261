<template>
  <div class="sign-up" v-if="windowWidth > 990">
    <div class="sign-up__left">
      <img class="sign-up__logo" src="@images/logo.svg" alt="" />
      <h2
        class="sign-up__description"
        v-html="$t($route.meta?.slogan || 'auth.slogan')"
      />
      <transition name="blur" appear mode="out-in">
        <img
          class="sign-up__img"
          :key="$route.meta?.image as (string | undefined)"
          :src="
            require(`@images/sign-up/${$route.meta?.image || 'ai-head.webp'}`)
          "
          alt=""
        />
      </transition>
    </div>
    <div class="sign-up__content">
      <slot />
    </div>
  </div>
  <div class="sign-up__mobile" v-else>
    <img class="sign-up__logo" src="@images/logo.svg" alt="" />
    <div class="sign-up__content"><slot /></div>
    <h2
      class="sign-up__description"
      v-html="$t($route.meta?.slogan || 'auth.slogan')"
    />
    <img
      class="sign-up__img"
      :src="require(`@images/sign-up/${$route.meta?.image || 'ai-head.webp'}`)"
      alt=""
    />
  </div>
</template>

<script setup lang="ts">
import { ElNotification } from 'element-plus';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { onMounted } from 'vue';
import { useWindowSize } from '@vueuse/core';

const route = useRoute();
const { t } = useI18n();

const { width: windowWidth } = useWindowSize();

onMounted(() => {
  type MessageKeyType = 'error' | 'used' | 'done';
  type NotificationsType = Extract<
    keyof typeof ElNotification,
    'info' | 'error' | 'success'
  >;

  const messageMapping: Record<MessageKeyType, NotificationsType> = {
    error: 'error',
    used: 'info',
    done: 'success',
  };

  const messageKey = route.query.message as MessageKeyType;
  const messageType = messageMapping[messageKey || 'error'];
  if (messageKey) {
    ElNotification[messageType]({
      message: t('messages.' + messageKey),
    });
  }
});
</script>

<style lang="scss" scoped>
.sign-up {
  display: flex;
  height: 100vh;
  min-height: 60rem;
  overflow-y: hidden;

  > * {
    flex: 0 0 50%;
  }

  &__logo {
    padding-left: 4rem;
    width: 13.2rem;
  }

  &__description {
    @include medium-font;
    font-family: 'Unbounded', sans-serif;
    text-align: center;
    max-width: 95%;
    margin-top: 3rem;
    padding: 0 2rem;

    @media (max-width: 1200px) {
      @include f18;
    }
  }

  &__img {
    width: 90%;
    margin-top: -10%;
    z-index: -1;

    @media (min-width: 991px) {
      padding-right: 5rem;
      width: 100%;
    }
  }

  &__left {
    @include flex-column;
    padding: 4rem 0 0 0;
  }

  &__content {
    background: #fcfcfc;
  }

  &__mobile {
    @include flex-column;
    align-items: center;
    padding: 0;

    .sign-up {
      &__logo {
        align-self: flex-start;
        margin: 2.3rem 3rem;
      }

      &__description {
        text-align: center;
        margin-bottom: 4rem;
      }

      &__content {
        max-width: 52rem;
        width: 95%;
        border-radius: 2.4rem;
      }

      &__img {
        width: 100%;
        position: relative;
      }
    }
  }
}
</style>
