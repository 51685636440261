<script setup lang="ts"></script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="currentColor" />
    <path
      d="M7.0055 11C6.86543 11 6.73237 10.9442 6.63432 10.8465L4.65233 8.87234C4.44922 8.67004 4.44922 8.33519 4.65233 8.13289C4.85543 7.93059 5.1916 7.93059 5.3947 8.13289L7.0055 9.73736L10.6053 6.15173C10.8084 5.94942 11.1446 5.94942 11.3477 6.15173C11.5508 6.35403 11.5508 6.68887 11.3477 6.89118L7.37669 10.8465C7.27864 10.9442 7.14557 11 7.0055 11Z"
      fill="#FCFCFC"
    />
  </svg>
</template>
