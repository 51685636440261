<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_17901_8184)">
      <circle
        cx="10"
        cy="10"
        r="9.4"
        stroke="currentColor"
        stroke-width="1.2"
      />
      <path
        d="M9.10204 12.6702V12.6128C9.10884 12.0042 9.17687 11.5198 9.30613 11.1597C9.43537 10.7996 9.61905 10.508 9.85714 10.2849C10.0952 10.0618 10.381 9.85629 10.7143 9.66827C10.915 9.55356 11.0952 9.41811 11.2551 9.26197C11.415 9.10263 11.5408 8.91939 11.6326 8.71225C11.7279 8.50511 11.7755 8.27566 11.7755 8.02391C11.7755 7.71161 11.6973 7.44073 11.5408 7.21129C11.3844 6.98184 11.1752 6.80498 10.9133 6.68069C10.6514 6.55641 10.3605 6.49427 10.0408 6.49427C9.76191 6.49427 9.4932 6.54844 9.2347 6.65679C8.97619 6.76514 8.76021 6.93563 8.58673 7.16827C8.41327 7.4009 8.31292 7.70524 8.28572 8.08128H7C7.02721 7.53953 7.17687 7.07585 7.44898 6.69025C7.72449 6.30465 8.08674 6.00988 8.53572 5.80593C8.98809 5.60198 9.48979 5.5 10.0408 5.5C10.6395 5.5 11.1599 5.61154 11.602 5.83461C12.0476 6.05769 12.3912 6.36361 12.6326 6.75239C12.8775 7.14118 13 7.58414 13 8.08128C13 8.43182 12.9422 8.7489 12.8265 9.03252C12.7143 9.31614 12.551 9.56949 12.3367 9.79257C12.1259 10.0156 11.8707 10.2132 11.5714 10.3853C11.2721 10.5606 11.0323 10.7454 10.852 10.9398C10.6718 11.131 10.5408 11.3589 10.4592 11.6234C10.3775 11.8879 10.3333 12.2177 10.3265 12.6128V12.6702H9.10204ZM9.7551 15.5C9.5034 15.5 9.28742 15.4156 9.10715 15.2467C8.92687 15.0778 8.83673 14.8754 8.83673 14.6397C8.83673 14.4038 8.92687 14.2014 9.10715 14.0325C9.28742 13.8636 9.5034 13.7792 9.7551 13.7792C10.0068 13.7792 10.2228 13.8636 10.4031 14.0325C10.5833 14.2014 10.6735 14.4038 10.6735 14.6397C10.6735 14.7958 10.6309 14.9392 10.5459 15.0698C10.4643 15.2005 10.3537 15.3057 10.2143 15.3853C10.0782 15.4618 9.92517 15.5 9.7551 15.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_17901_8184">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>
