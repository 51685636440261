import { RouteRecordRaw } from 'vue-router';
const FilesIndex = () => import('@/views/files/index.vue');
const FilesAll = () => import('@/views/files/all.vue');
const FilesMy = () => import('@/views/files/my.vue');

export default {
  path: '/files',
  component: FilesIndex,
  meta: {
    layout: 'app',
  },
  redirect: { name: 'FilesAll' },
  children: [
    { path: 'all', name: 'FilesAll', component: FilesAll },
    { path: 'my', name: 'FilesMy', component: FilesMy },
  ],
} as RouteRecordRaw;
