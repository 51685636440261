import { RouteRecordRaw } from 'vue-router';

const SignUpView = () => import('@/views/sign-up/sign-up.vue');
const SignUpViewIndex = () => import('@/views/sign-up/index.vue');
const SignUpConfirmViewIndex = () => import('@/views/sign-up/confirm.vue');

export default {
  path: '/sign-up',
  name: 'SignUpView',
  redirect: { name: 'SignUpViewIndex' },
  component: SignUpView,
  meta: {
    public: true,
  },
  children: [
    {
      path: '',
      name: 'SignUpViewIndex',
      component: SignUpViewIndex,
      meta: {
        layout: 'auth',
      },
    },
    {
      path: 'confirm',
      name: 'SignUpConfirmViewIndex',
      component: SignUpConfirmViewIndex,
      meta: {
        layout: 'auth',
      },
    },
  ],
} as RouteRecordRaw;
