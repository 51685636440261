import { RawTask } from '@/types/notes';
import { ICreateTaskRequest } from '@/types/tasks';
import services from '@/api/services';
import { ref } from 'vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { isAllDayDate, stringDateToUTC } from '@/utils/time';
import { useWorkspaceStore } from '@/stores/workspaces';
import { useUserStore } from '@/stores/user';
import { CURRENT_TIME_ZONE } from '@/consts/timeFormats';

export const useRawTasks = () => {
  const { t } = useI18n();

  const isCreating = ref(false);
  const userStore = useUserStore();
  const workspaceStores = useWorkspaceStore();
  const createTasks = async (tasks: ICreateTaskRequest[]) => {
    isCreating.value = true;
    const initialCompanyId = userStore.userData?.currentCompany;
    let prevCompanyId: number | null = null;
    try {
      for (const task of tasks) {
        if (prevCompanyId !== task.companyId) {
          await workspaceStores.setCurrent(task.companyId);
          prevCompanyId = task.companyId;
        }
        const isAllDayTask = isAllDayDate(task.deadline);
        await services.tasks.createTask({
          ...task,
          deadline: (isAllDayTask
            ? task.deadline
            : stringDateToUTC(task.deadline)) as string,
          deadlineLast: stringDateToUTC(task.deadlineLast ?? undefined),
          notifyTime: stringDateToUTC(task.notifyTime ?? undefined),
        });
      }
      if (initialCompanyId && initialCompanyId !== prevCompanyId)
        await workspaceStores.setCurrent(initialCompanyId);
      await userStore.getUserData();
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
    } finally {
      isCreating.value = false;
    }
  };

  const convertRawTask = ({
    audioFileId,
    companyId,
    deadline,
    executorId,
    name,
    taskContent,
    taskPriority,
    deadlineLast,
    notifyTime,
  }: RawTask): ICreateTaskRequest => {
    const isAllDayTask = isAllDayDate(deadline);

    return {
      audioFileId: audioFileId || 0,
      companyId,
      deadline: isAllDayTask
        ? deadline
        : stringDateToUTC(deadline, {
            initialDateUTC0: true,
            timeZone: CURRENT_TIME_ZONE,
          }) || '',
      executorId,
      filesIds: [],
      name,
      taskContent,
      taskPriority,
      deadlineLast: stringDateToUTC(deadlineLast ?? undefined, {
        initialDateUTC0: true,
        timeZone: CURRENT_TIME_ZONE,
      }),
      notifyTime: stringDateToUTC(notifyTime ?? undefined, {
        initialDateUTC0: true,
        timeZone: CURRENT_TIME_ZONE,
      }),
    };
  };

  return {
    createTasks,
    convertRawTask,
  };
};
