import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { INotification } from '@/types/notification';

import { Pageable } from '@/types/pagination';
import { NotificationHistory } from '@/types/common';

type NotificationSetup = {
  isActive: boolean;
  notificationTypeId: number;
};

type NotificationParams = {
  filter?: string;
  onlyUnread: boolean;
  notificationTypeIds?: Array<number>;
  pageNum?: number;
  pageSize?: number;
};

export class NotificationsService extends ApiService {
  notificationHistory(params: NotificationParams) {
    return axiosInstance.get<Pageable<NotificationHistory>>(
      ENDPOINTS.notifications.history,
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      },
    );
  }

  notificationData() {
    return axiosInstance.get<INotification[]>(
      ENDPOINTS.notifications.notification,
    );
  }

  notificationSetup(payload: NotificationSetup) {
    return axiosInstance.post(ENDPOINTS.notifications.setup, payload);
  }

  readNotification(id: number) {
    return axiosInstance.post(
      ENDPOINTS.notifications.read,
      {},
      {
        params: {
          notificationId: id,
        },
      },
    );
  }

  countIsNotRead() {
    return axiosInstance.get<number>(ENDPOINTS.notifications.notRead);
  }

  readAllNotifications() {
    return axiosInstance.post(ENDPOINTS.notifications.readAll);
  }

  deleteAllNotifications() {
    return axiosInstance.get(ENDPOINTS.notifications.deleteAll);
  }
}
