import { RouteRecordRaw } from 'vue-router';

const CalendarView = () => import('@/views/calendar/calendar.vue');
const CalendarIndex = () => import('@/views/calendar/index.vue');
const TaskSharingHistory = () =>
  import('@/views/calendar/taskSharingHistory.vue');

export default {
  path: '/calendar',
  component: CalendarView,
  meta: {
    layout: 'app',
  },
  children: [
    { path: '', name: 'Calendar', component: CalendarIndex },
    {
      path: 'task-sharing-history',
      name: 'TaskSharingHistory',
      component: TaskSharingHistory,
    },
  ],
} as RouteRecordRaw;
