<template>
  <modal-notification
    class="feedback-modal notification-modal"
    :model-value="isOpen"
    v-bind="$attrs"
    @close="$emit('onClose')"
    width="100%"
  >
    <template #header>
      <div class="feedback-modal__header">
        <p class="feedback-modal__title">
          {{ $t('support_modals.feedback.title') }}
        </p>
        <span class="feedback-modal__description">
          {{ $t('support_modals.feedback.description') }}
        </span>
      </div>
    </template>
    <template #default>
      <div class="feedback-modal__content">
        <el-select
          v-model="form.category"
          value-key="name"
          class="feedback-modal__select error"
          :placeholder="$t('support_modals.feedback.form.category')"
        >
          <el-option
            v-for="(item, i) in selectList"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <ui-input
          class="feedback-modal__textarea"
          type="textarea"
          rows="7"
          v-model="form.description"
          :label="$t('support_modals.feedback.form.subject')"
          :class="{ error: form.errorDetail }"
          @input="form.errorDetail = false"
        />
        <ui-uploader-files v-model="form.files" />
      </div>
    </template>
    <template #footer>
      <div class="feedback-modal__btns">
        <ui-button
          @click="sendFeedback"
          :is-disabled="isLoading"
          :is-loading="isLoading"
        >
          {{ $t('send') }}
        </ui-button>
        <ui-button plain @click="$emit('openContact')">
          {{ $t('support_modals.feedback.problem') }}
        </ui-button>
      </div>
    </template>
  </modal-notification>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import UiUploaderFiles from '@/components/ui/UiUploaderFiles.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import services from '@/api/services';
import { UserTypeFeedback } from '@/types/user';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { filesTypes } from '@/consts';
import { createAvatarLink } from '@/utils/url';
import ModalNotification from '@/components/modals/ModalNotification.vue';

const { t } = useI18n();
const { userData } = storeToRefs(useUserStore());

defineProps<{ isOpen: boolean }>();
const emit = defineEmits(['onClose', 'openContact']);

const isLoading = ref(false);

const selectList = computed(() => {
  return [
    {
      value: 'Speech recognition',
      label: 'Speech recognition',
    },
    {
      value: 'Auto-translation',
      label: 'Auto-translation',
    },
    {
      value: 'Body Task',
      label: 'Body Task',
    },
    {
      value: 'Task table',
      label: 'Task table',
    },
    {
      value: 'Dashboard',
      label: 'Dashboard',
    },
    {
      value: 'Analytics tab',
      label: 'Analytics tab',
    },
    {
      value: 'Billing tab',
      label: 'Billing tab',
    },
    {
      value: 'Sharing tasks',
      label: 'Sharing tasks',
    },
    {
      value: 'Calendar',
      label: 'Calendar',
    },
    {
      value: 'Filters',
      label: 'Filters',
    },
    {
      value: 'Chat',
      label: 'Chat',
    },
    {
      value: 'Workload',
      label: 'Workload',
    },
    {
      value: 'Notifications',
      label: 'Notifications',
    },
    {
      value: 'Forms',
      label: 'Forms',
    },
  ];
});

const form = ref({
  category: selectList.value[0].value,
  files: [],
  description: '',
  errorSubject: false,
  errorDetail: false,
});

const sendFeedback = async () => {
  if (!form.value.description) {
    form.value.errorDetail = true;
    return;
  }
  isLoading.value = true;
  const { description, files, category } = form.value;
  let body = {
    description,
    email: userData.value?.email || '',
    subject: category,
  };

  try {
    if (files.length) {
      const data = await createMediaFile(files);
      body.description += ` ${data.join(', ')}`;
    }
    await services.users.setFeedback(body, UserTypeFeedback.FEEDBACK);
    form.value.files = [];
    form.value.description = '';
    form.value.category = selectList.value[0].value;
    ElNotification.success(t('email_sent'));
    isLoading.value = false;
    emit('onClose');
  } catch (e) {
    ElNotification.error(t('some_error'));
    isLoading.value = false;
  }
};

const createMediaFile = async (files: File[]) => {
  const filesLink: string[] = [];
  const filesTypesMapping = {
    application: filesTypes['ATTACHMENT'],
    audio: filesTypes['AUDIO'],
    image: filesTypes['PHOTO'],
  } as const;

  const groupedFiles = files.reduce(
    (groupedFiles: Record<string, File[]>, file) => {
      const type =
        filesTypesMapping[
          file.type.split('/')[0] as keyof typeof filesTypesMapping
        ] || 'ATTACHMENT';
      if (!groupedFiles[type]) groupedFiles[type] = [];
      groupedFiles[type].push(file);
      return groupedFiles;
    },
    {},
  );

  for await (const group of Object.keys(groupedFiles)) {
    const formData = new FormData();
    formData.append('fileType', group);

    groupedFiles[group].forEach((file) => {
      formData.append('files', file);
    });

    try {
      const resFiles = await services.files.add(formData);
      resFiles.data.forEach((file) => {
        filesLink.push(createAvatarLink(file.uuidLink) || '');
      });
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
      console.error(e);
    }
  }

  return filesLink;
};
</script>
<style lang="scss">
.feedback-modal {
  max-width: 53rem;
  width: 100%;

  &__header {
    @include flex-column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    @include f16;
    color: #1a2a39;
    text-align: center;
    font-family: 'Unbounded', sans-serif;
    line-height: 2.6rem;
    margin-bottom: 1.2rem;
  }

  &__description {
    @include f14;
    color: #1a2a39;
    text-align: center;
    line-height: 2.2rem;
    word-break: break-word;
    max-width: 33rem;
  }

  &__select {
    .el-input__wrapper {
      padding: 1rem 1.6rem;
      border-radius: 8px;
      background: #fcfcfc;
    }
  }

  &__content {
    @include flex-column(2rem);
  }

  &__btns {
    @include flex-row(2rem);
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;

    & > * {
      width: 100%;
    }
  }
}

.feedback-modal__textarea {
  .ui-input__control + label {
    top: 20%;
  }
}
</style>
