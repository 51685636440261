import { createUrl } from '@/utils/url';

const companiesUrl = createUrl('web/companies');
const usersUrl = createUrl('web/users');

const removeUser = companiesUrl('removeUser');
const addUser = companiesUrl('addUser');
const list = companiesUrl('list');
const deleteCompany = companiesUrl('delete');
const create = companiesUrl('create');
const modify = companiesUrl('modify');
const setCurrent = usersUrl('setCurrentCompany');

export const companiesEndpoint = {
  removeUser,
  addUser,
  list,
  deleteCompany,
  create,
  modify,
  setCurrent,
};
