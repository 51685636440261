import { ApiService } from '@/api/services/core';
import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { Pageable } from '@/types/pagination';
import { ITariff, ITariffItemResponse } from '@/types/tariffs';
import { TariffHistoryFilters } from '@/types/tariff';

export class TariffsService extends ApiService {
  getUserTariff() {
    return axiosInstance.get(ENDPOINTS.tariffs.getUserTariff);
  }

  getAllTariffHistory(params: TariffHistoryFilters) {
    return axiosInstance.get<Pageable<ITariff>>(
      ENDPOINTS.tariffs.getAllTariffHistory,
      {
        params,
      },
    );
  }

  getTariffList() {
    return axiosInstance.get<ITariffItemResponse[]>(
      ENDPOINTS.tariffs.getTariffList,
    );
  }
}
