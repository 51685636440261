<template>
  <div class="player__container">
    <button @click="handleEvent" class="player__button">
      <IconPlay class="player__icon" v-if="!isPlaying" />
      <IconPause class="player__icon" v-else />
    </button>
    <div>
      <audio ref="player" class="hidden">
        <source :src="audio" type="audio/mp3" />
      </audio>
      <canvas ref="canvas" style="width: 100%" />
    </div>
    <span class="player__time"> {{ timeValue }} </span>
  </div>
</template>
<script setup lang="ts">
import { ref, nextTick } from 'vue';
import { useAVWaveform } from 'vue-audio-visual';
import { formatSeconds } from '@/utils/formatSeconds';
import IconPlay from '@/components/icon/IconPlay.vue';
import IconPause from '@/components/icon/IconPause.vue';

const props = defineProps<{
  audio: any;
}>();

const isPlaying = ref(false);

const player = ref<HTMLAudioElement>();
const canvas = ref(null);
const timeValue = ref('0');

const handleEvent = () => {
  if (isPlaying.value) {
    pause();
  } else {
    play();
  }
};

const play = () => {
  timeValue.value = formatSeconds(player.value?.currentTime);
  player.value?.play();
  isPlaying.value = true;
};

const pause = () => {
  player.value?.pause();
  isPlaying.value = false;
};

useAVWaveform(player, canvas, {
  src: props.audio,
  canvHeight: 40,
  canvWidth: 250,
  playtime: false,
  noplayedLineColor: 'grey',
});

nextTick(() => {
  player.value?.load();
  player.value?.addEventListener('ended', () => {
    pause();
    player.value?.load();
    timeValue.value = formatSeconds(player.value?.duration);
  });

  player.value?.addEventListener('timeupdate', () => {
    timeValue.value = formatSeconds(player.value?.currentTime);
  });

  player.value?.addEventListener('canplay', () => {
    //Dirty hack for Chrome later check another
    // const _player = new Audio(props.audio);
    const _player = document.createElement('audio');
    const sourceElement = document.createElement('source');

    _player.append(sourceElement);

    sourceElement.src = props.audio;
    sourceElement.type = 'audio/mp3';
    _player.addEventListener(
      'durationchange',
      function (e) {
        if (this.duration != Infinity) {
          const duration = this.duration;
          _player.remove();
          timeValue.value = formatSeconds(duration);
        }
      },
      false,
    );
    _player.load();
    _player.currentTime = 24 * 60 * 60;
    _player.volume = 0;
    _player.play();
  });
});
</script>
<style lang="scss" scoped>
.player {
  &__container {
    display: flex;
    align-items: center;
    background: #fcfcfc;
    box-shadow: 0px 10px 30px -5px rgba(87, 87, 87, 0.07);
    border-radius: 8px;
    padding: 10px 12px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4rem;
    min-height: 4rem;
    background: var(--color-primary);
    border-radius: 6px;
    margin-right: 1.5rem;
  }

  &__time {
    @include f14;
    text-align: center;
    line-height: 1;
    margin-left: 1.5rem;
  }
}

:deep(.player__icon) {
  margin: auto;
  width: 16px;
  height: 16px;
}
</style>
