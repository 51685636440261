<template>
  <div class="card-tariff__popular">
    <img :src="require(`@images/tariff-card/heart-${colors.img}.svg`)" alt="" />
    {{ $t('card_tariff.popular') }}
    <img :src="require(`@images/tariff-card/like-${colors.img}.svg`)" alt="" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { TARIFF_POPULAR_TAB_THEME } from '@/consts/themes';

const props = defineProps<{ theme: 'active' | 'default' }>();
const colors = computed(() => TARIFF_POPULAR_TAB_THEME[props.theme]);
</script>

<style scoped lang="scss">
.card-tariff__popular {
  position: absolute;
  inset: 0;
  @include flex-row(0.6rem);
  align-items: center;
  justify-content: center;
  background: v-bind('colors.background');
  border-bottom: 0.1rem solid #fff;
  height: 4rem;
  border-radius: 1.6rem 1.6rem 0 0;
  color: v-bind('colors.color');
  font-weight: 600;
}
</style>
