<template>
  <modal-notification
    width="120rem"
    :is-open="isOpen"
    @on-close="$emit('close')"
  >
    <template #header>
      <h1 class="modal-buy__title">
        {{ title || $t('card_tariff.label') }}
      </h1>
    </template>
    <template #default>
      <div class="modal-buy__cards">
        <card-tariff
          v-for="item in tariffsList"
          :item="item"
          :key="item.id"
          @change-subscription="onConfirmChangeSubscription"
        />
      </div>
    </template>
    <template #footer><div /></template>
  </modal-notification>
  <modal-plan-change
    :payment-id="currentPaymentId"
    :is-open="isChangeSubscriptionVisible"
    @close="isChangeSubscriptionVisible = false"
    @close-with-success="
      $emit('close');
      isChangeSubscriptionVisible = false;
    "
    @close-with-fail="
      $router.push({
        name: 'PurchaseTariffId',
        params: { tariffId: currentTariffId },
        query: { isAnnually: String(isAnnually), paymentFailed: 'true' },
      })
    "
  />
</template>
<script setup lang="ts">
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';
import CardTariff from '@/components/cards/CardTariff.vue';
import { onMounted, ref } from 'vue';
import ModalPlanChange from '@/components/modals/Billing/ModalPlanChange.vue';
import { TranslateResult } from 'vue-i18n';

type Props = { isOpen?: boolean; title?: string | TranslateResult };
defineProps<Props>();
defineEmits(['close']);

const isChangeSubscriptionVisible = ref(false);
const currentTariffId = ref<number>();
const currentPaymentId = ref<string>();
const isAnnually = ref(false);

const onConfirmChangeSubscription = (
  tariffId: number,
  annuallyPeriod: boolean,
  paymentId: string,
) => {
  currentTariffId.value = tariffId;
  currentPaymentId.value = paymentId;
  isAnnually.value = annuallyPeriod;
  isChangeSubscriptionVisible.value = true;
};

const billingStore = useTariffStore();
const { tariffsList, isTariffsListLoading } = storeToRefs(billingStore);

onMounted(() => !isTariffsListLoading.value && billingStore.fetchTariffList());
</script>

<style scoped lang="scss">
.modal-buy {
  &__title {
    padding-top: 3rem;
    @include f20;
    text-align: center;
  }

  &__cards {
    @include flex-row(1rem);
    gap: 4rem;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4rem 3rem 3rem;
  }
}
</style>
