<template>
  <slot name="label">
    <div class="card-tariff__features-label" v-if="!noLabel">
      {{ $t(`card_tariff.services.advantages.${item.name}.label`) }}
    </div>
  </slot>
  <ul class="card-tariff__list" :style="styles">
    <ui-plan-feature
      v-if="item.amountSeconds"
      prop="recognition"
      :name="item.name"
      :count="secondsToMinutes(Number(item.amountSeconds))"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      v-if="item.workSpace"
      prop="workspaces"
      :name="item.name"
      :count="item.workSpace"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      v-if="item.amountStorageBytes"
      prop="storage"
      :name="item.name"
      :count="formatBytes(item.amountStorageBytes ?? 0)"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      v-if="item.amountPersonal"
      prop="invitations"
      :name="item.name"
      :count="item.amountPersonal"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      v-if="item.amountSeconds"
      prop="translate"
      :name="item.name"
      :count="secondsToMinutes(Number(item.amountSeconds))"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      prop="web_app"
      :name="item.name"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      prop="support"
      :name="item.name"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      prop="mobile_app"
      :name="item.name"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      prop="unlimited_notes"
      :name="item.name"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
    <ui-plan-feature
      prop="ai_autoplanning"
      :name="item.name"
      :accent="themeColors.accent"
      :marker-color="themeColors.markerColor"
    />
  </ul>
</template>

<script setup lang="ts">
import { formatBytes } from '@/utils/convert';
import { secondsToMinutes } from 'date-fns';
import { ITariffItemResponse } from '@/types/tariffs';
import { computed } from 'vue';
import UiPlanFeature from '@/components/ui/UiPlan/UiPlanFeature.vue';

const props = withDefaults(
  defineProps<{
    item: ITariffItemResponse;
    theme: 'active' | 'default';
    noLabel?: boolean;
    markersColor?: string;
    fontSize?: string;
    gap?: string;
    size?: 'large' | 'default';
  }>(),
  { fontSize: '1.2rem', gap: '1.2rem', size: 'default' },
);

const styles = computed(() => {
  if (props.size === 'large') {
    return {
      gap: '1.6rem',
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    };
  }
  return {
    gap: '1.2rem',
    fontSize: '1.2rem',
  };
});

const themeColors = computed(() => {
  const colors = {
    default: {
      textColor: '#1A2A39',
      accentColor: '#9BC95E',
      markerColor: props.markersColor || '#9BC95E',
      accent: 'green',
    },
    active: {
      textColor: '#1A2A39',
      accentColor: '#778BEE',
      markerColor: props.markersColor || '#FCFCFC',
      accent: 'purple',
    },
  };

  return colors[props.theme];
});
</script>

<style scoped lang="scss">
.card-tariff {
  &__list {
    @include flex-column;
    text-align: left;
    list-style: none;
    padding: 0;
  }

  &__features-label {
    @include f12;
    font-weight: 600;
  }
}
</style>
