import axios, {
  AxiosError,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from 'axios';
import { useUserStore } from '@/stores/user';
import { AuthData } from '@/types/common';
import router from '@/router';

type ExtendedAxiosRequestConfig =
  | (AxiosRequestConfig & {
      isRetry: boolean;
    })
  | undefined;

const isProduction = process.env.NODE_ENV === 'production';
export const baseURL = isProduction
  ? process.env.VUE_APP_API_URL
  : process.env.VUE_APP_DEV_API_URL;

const axiosConfig: CreateAxiosDefaults = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const axiosInstance = axios.create(axiosConfig);
export const axiosRefreshInstance = axios.create(axiosConfig);
export const getBearer = (token?: string) => `Bearer ${token || ''}`;

const refresh = async () => {
  const userStore = useUserStore();
  const response = await userStore.refresh();
  return response.data;
};

let isRefreshing: Promise<AuthData> | null = null;

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const userStore = useUserStore();
      config.headers['Authorization'] = getBearer(userStore.accessToken);
    }
    return config;
  },
  (error) => error,
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    const userStore = useUserStore();
    console.log(error);
    const config = error.config as ExtendedAxiosRequestConfig;
    if (error?.response?.status === 401 && config && !config.isRetry) {
      config.isRetry = true;
      try {
        isRefreshing = isRefreshing ? isRefreshing : refresh();
        const data = await isRefreshing;
        isRefreshing = null;
        userStore.setAuthData(data);
        return axiosInstance.request(config);
      } catch (error) {
        console.error(error);
        await router.push({ name: 'Logout' });
      }
    }
    return Promise.reject(error);
  },
);

export default { axiosInstance };
