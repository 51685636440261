<template>
  <modal-notification
    v-if="step === 1"
    @on-close="onClose"
    width="38rem"
    :title="$t('workspaces.join.title')"
    :is-open="isOpen"
  >
    <template #default>
      <div class="workspaces-join__content">
        <span>{{ $t('workspaces.join.message') }}</span>
        <v-otp-input
          input-classes="large"
          ref="otpInput"
          inputType="number"
          :num-inputs="8"
          v-model:value="invitationCode"
          :should-auto-focus="true"
          :should-focus-order="true"
        />
      </div>
    </template>
    <template #footer>
      <div class="workspaces-join__footer">
        <ui-button
          @click="onJoin"
          :is-loading="pending"
          :is-disabled="invitationCode.length !== 8"
        >
          {{ $t('workspaces.join.confirm') }}
        </ui-button>
      </div>
    </template>
  </modal-notification>
  <modal-switch-workspace
    v-else-if="step === 2"
    @close="onClose"
    :workspace="joinedWorkspace"
    width="35rem"
    is-open
  >
    <template #header>
      <span class="workspaces-join__switch-header">
        {{ $t('workspaces.join.success', { name: joinedWorkspace?.name }) }}
      </span>
    </template>
    <span>{{ $t('workspaces.join.switch') }}</span>
    <img
      class="workspaces-join__switch-img"
      src="@images/workspaces/switch.webp"
      alt=""
    />
  </modal-switch-workspace>
</template>

<script setup lang="ts">
import ModalNotification from '@/components/modals/ModalNotification.vue';
import { ref } from 'vue';
import VOtpInput from 'vue3-otp-input';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { ElNotification } from 'element-plus';
import ModalSwitchWorkspace from '@/components/modals/Workspaces/ModalSwitchWorkspace.vue';
import { Workspace } from '@/types/workspaces';
import { useI18n } from 'vue-i18n';

defineProps<{ isOpen?: boolean }>();
const emits = defineEmits(['close']);

const { t } = useI18n();

const invitationCode = ref('');
const joinedWorkspace = ref<Workspace>();
const step = ref(1);
const pending = ref(false);

const invitationsStore = useInvitationsStore();
const { setInvitationStatus } = invitationsStore;

const onJoin = async () => {
  if (!invitationCode.value) return;
  try {
    pending.value = true;
    joinedWorkspace.value = (
      await setInvitationStatus({
        invitationCode: invitationCode.value,
        result: true,
      })
    ).data;
    ElNotification.success({
      message: t('workspaces.join.joined_msg'),
      offset: 80,
    });
    step.value = 2;
  } catch (e) {
    console.error(e);
    ElNotification.error({
      message: t('workspaces.join.no_code_msg'),
      offset: 80,
    });
  } finally {
    pending.value = false;
  }
};

const onClose = () => {
  setTimeout(() => (step.value = 1), 1000);
  emits('close');
};
</script>

<style scoped lang="scss">
.workspaces-join {
  &__content {
    @include flex-column(1rem);
    color: #1a2a39;
  }

  &__footer {
    @include flex-row;
    justify-content: flex-end;
    margin-top: 2rem;
  }

  &__switch {
    &-header {
      @include f16;
    }

    &-img {
      margin: 2rem -2rem;
      width: inherit;
    }
  }
}
</style>
