import { defineStore } from 'pinia';
import { ref } from 'vue';
import { NoteStatus } from '@/types/notes';

export const useNotesFilters = defineStore('notes-filters', () => {
  const search = ref('');
  const status = ref<NoteStatus>('ACTIVE');

  return {
    search,
    status,
  };
});
