<script setup lang="ts">
import NoteTransformStepCard from '@/components/notes/transform/steps/NoteTransformStepCard.vue';
import IconAI2 from '@/components/icon/IconAI2.vue';
import NoteTransformCalendar from '@/components/notes/transform/calendar/NoteTransformCalendar.vue';
import { useNotesTransformStore } from '@/stores/notes/useNotesTransform';
import { storeToRefs } from 'pinia';
import { useNotesByCalendar } from '@/stores/notes/useNotesByCalendar';
import { onBeforeMount, onUnmounted, ref } from 'vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';

const notesTransformStore = useNotesTransformStore();
const { initialNotes, planners, mode } = storeToRefs(notesTransformStore);
const notesByCalendarStore = useNotesByCalendar();
const { isLastWorkspace, currentGroup, isAllLoading } =
  storeToRefs(notesByCalendarStore);

onBeforeMount(() => {
  notesByCalendarStore.start(planners.value, initialNotes.value);
});

onUnmounted(() => {
  notesByCalendarStore.end();
});

const isEnding = ref(false);

const handleEnd = async () => {
  if (isLastWorkspace.value) {
    isEnding.value = true;
    notesTransformStore.planners = await notesByCalendarStore.end();
    isEnding.value = false;
    notesTransformStore.goToNextStep();
  } else {
    notesByCalendarStore.nextWorkspace();
  }
};
</script>

<template>
  <NoteTransformStepCard stretch>
    <template #header>
      <p>{{ $t(`notes.transform.calendar.title.${mode}`) }}</p>
      <p class="notes-transform-step-calendar__workspace">
        Workspace:
        <span
          class="notes-transform-step-calendar__workspace-badge"
          :style="{
            'background-color': currentGroup?.workspace?.color || 'gray',
          }"
        ></span>
        <span class="notes-transform-step-calendar__workspace-name">
          {{ currentGroup?.workspace.name }}
        </span>
      </p>
      <small>{{ $t(`notes.transform.calendar.description.${mode}`) }}</small>
    </template>
    <div class="notes-transform-step-calendar">
      <Transition name="fade">
        <NoteTransformCalendar v-if="!isAllLoading && !isEnding" />
        <UiSpinner v-else />
      </Transition>
    </div>
    <template #footer>
      <div class="notes-transform-step-calendar__controls">
        <ui-button @click="handleEnd" :is-disabled="isAllLoading || isEnding">
          <span v-if="isLastWorkspace">
            <IconAI2 />
            {{ $t('notes.schedule.many') }}
          </span>
          <span v-else> {{ $t('notes.transform.calendar.next') }}</span>
        </ui-button>
      </div>
    </template>
  </NoteTransformStepCard>
</template>

<style scoped lang="scss">
.notes-transform-step-calendar {
  margin-left: -3rem;
  width: calc(100% + 6rem);
  overflow-y: scroll;
  height: 100%;

  &__workspace {
    &-badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      margin: 0 0.6rem;
    }

    &-name {
      font-weight: 400;
    }
  }

  &__controls {
    @include flex-row;
    justify-content: end;
    button {
      min-width: 110px;

      span {
        @include flex-row(1rem);
        align-items: center;
      }
    }
  }
}
</style>
