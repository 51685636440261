<template>
  <div class="ui-avatar" :style="size && { width: size, height: size }">
    <div
      v-if="fallbackName && !avatarLink"
      class="ui-avatar__fallback-label"
      :style="{ background: computedFallbackName?.color }"
    >
      <span>{{ computedFallbackName.label }}</span>
    </div>
    <img
      v-else
      ref="image"
      :src="
        computedAvatarLink ||
        (isImageLoaded ? require(`@images/default-avatar.svg`) : '')
      "
      alt="avatar"
      class="ui-avatar__image"
      :class="{ 'ui-avatar__image-loading': !isImageLoaded }"
    />
    <transition name="fade" mode="out-in" appear>
      <icon-spinner
        v-if="avatarLink && !isImageLoaded"
        circle-color="#ffffff"
        color="#ffffff"
        class="ui-avatar__loading"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { createAvatarLink } from '@/utils/url';
import { computed, ref } from 'vue';
import IconSpinner from '@/components/icon/IconSpinner.vue';
import { stringToColor } from '@/utils/common';
import { whenever } from '@vueuse/core';

const props = defineProps<{
  avatarLink?: string | null;
  size?: string;
  fallbackName?: string;
}>();

const isImageLoaded = ref(false);

const image = ref<HTMLImageElement | null>(null);
whenever(
  () => image.value,
  () => {
    if (!image.value) return;
    image.value.onload = () => (isImageLoaded.value = true);
    image.value.onerror = () => (isImageLoaded.value = true);
  },
);

whenever(
  () => props.avatarLink,
  () => (isImageLoaded.value = false),
);

const computedAvatarLink = computed(() => createAvatarLink(props.avatarLink));
const computedFallbackName = computed(() => {
  if (!props.fallbackName) return;
  const [firstName, lastName] = props.fallbackName.split(' ');
  const label = `${firstName?.[0] || ''}${lastName?.[0] || ''}`
    .trim()
    .toUpperCase();
  const color = stringToColor(props.fallbackName);

  return { label, color };
});
</script>

<style scoped lang="scss">
.ui-avatar {
  position: relative;
  @include avatar;
  transition: opacity 0.2s ease-in-out;

  &__image {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;

    &-loading {
      opacity: 0;
    }
  }

  &__fallback-label {
    display: grid;
    place-content: center;
    height: 100%;
    font-size: calc(v-bind(size) * 0.5);
    line-height: 0;
    color: #fff;
  }

  &__loading {
    position: absolute;
    inset: 0;
    color: #ffffff;
    animation: spin 1.2s linear infinite;
    height: 100%;
    width: 100%;
  }
}
</style>
