<template>
  <svg
    :style="rotateStyle"
    style="cursor: pointer; flex: 0 0 auto"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';

const props = defineProps({
  color: {
    type: String,
    required: false,
    default: '#1A2A39',
  },
  direction: {
    type: String as PropType<'top' | 'left' | 'right' | 'bottom'>,
    required: false,
    default: 'right',
  },
  size: {
    type: String,
    required: false,
    default: '24',
  },
});

const directionMapping = {
  left: '90',
  right: '-90',
  top: '180',
  bottom: '0',
};

const rotateStyle = computed(
  () => `transform: rotate(${directionMapping[props.direction]}deg)`,
);
</script>

<style lang="scss" scoped></style>
