import { axiosInstance } from '@/api/axios';
import { ENDPOINTS } from '@/api/endpoints';
import { ApiService } from '@/api/services/core';
import {
  AgendaDayOfMonthData,
  AgendaMonthByPeriodParams,
} from '@/types/agenda';

export class AgendaService extends ApiService {
  getMonthAgendaByPeriod(payload: AgendaMonthByPeriodParams) {
    const { companiesIds, ...params } = payload;
    return axiosInstance.post<Record<string, AgendaDayOfMonthData>>(
      ENDPOINTS.tasks.getMonthAgendaByPeriod,
      companiesIds,
      { params },
    );
  }
}
