<template>
  <div
    :class="[
      'notification__row',
      {
        'notification__row--new-user': currentTypeNotification.isUser,
      },
    ]"
    @click="readNotification"
    v-if="
      !currentTypeNotification.isUser ||
      (currentTypeNotification.isUser && userTypeNotificationData)
    "
  >
    <icon-marker
      class="notification__marker"
      v-if="!item?.notificationHistory.isRead"
    />
    <div class="notification__info">
      <div class="modal__title" v-if="currentTypeNotification.isTask">
        <div class="modal__icon">
          <img
            :src="
              require(`@images/statuses/${
                item?.notificationHistory?.taskStatus?.toLowerCase() ||
                'created'
              }.svg`)
            "
            class="modal-view__icon"
          />
        </div>
        <span v-if="item?.notificationHistory?.taskStatus">
          {{ TITLES_MAP[item.notificationHistory.taskStatus] }}
        </span>
      </div>
      <div
        v-if="currentTypeNotification.isTaskExpired"
        class="notification__expired-text"
      >
        {{ $t('notifications.expired_text') }}
      </div>
      <div class="notification__message">
        <template v-if="currentTypeNotification.isUser">
          <ui-avatar
            size="3rem"
            :avatar-link="userTypeNotificationData?.avatar?.uuidLink"
            :fallback-name="userTypeNotificationData?.fullName"
          />
          {{ userTypeNotificationData?.fullName }}
          <span class="notification__link">
            {{ $t('notifications.joined') }}
          </span>
        </template>
      </div>
      <div
        v-if="!currentTypeNotification.isUser"
        class="notification__description"
      >
        {{ item.notificationHistory.message }}
      </div>
    </div>
    <div class="notification__date">
      <div
        v-if="item?.count && currentTypeNotification.isMessage"
        class="notification__count"
      >
        <ui-colorfilled-text
          color="#FCFCFC"
          style="justify-content: flex-end"
          type="NOTIFICATION"
        >
          {{ item.count }}
        </ui-colorfilled-text>
      </div>
      <div
        v-if="currentTypeNotification.isTaskExpired"
        class="notification__count"
      >
        <IconBadge color="#EC7975" />
      </div>
      <span class="notification__date-date">
        {{ formattedDate }}
      </span>
    </div>
  </div>
  <modal-user-view
    :is-open="!!(userTypeNotificationData && isUserProfileOpen)"
    @on-close="onCloseUserProfile"
    :user="userTypeNotificationData"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import UiColorfilledText from '@/components/text/UiColorfilledText.vue';
import IconMarker from '@/components/icon/IconMarker.vue';
import { format } from 'date-fns';
import { NotificationHistory } from '@/types/common';
import { TITLES_MAP } from '@/consts';
import IconBadge from '@/components/icon/IconBadge.vue';
import { useNotificationsStore } from '@/stores/notifications/notifications';
import { useRouter } from 'vue-router';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { storeToRefs } from 'pinia';
import ModalUserView from '@/components/modals/UserView/ModalUserView.vue';

const router = useRouter();

const isUserProfileOpen = ref(false);

const notificationsStore = useNotificationsStore();
const subordinatesStore = useSubordinatesStore();
const { subordinatesView } = storeToRefs(subordinatesStore);

const usersStore = useSubordinatesStore();
const { fetch: usersFetch } = usersStore;

const props = defineProps<{ item: NotificationHistory }>();

const formattedDate = computed(() => {
  if (props.item?.notificationHistory.createdAt) {
    return format(
      new Date(props.item?.notificationHistory.createdAt),
      'dd.MM · hh:mm',
    );
  }

  return '';
});

const currentTypeNotification = computed(() => {
  const isUser = !!props.item?.notificationHistory.userIdInviteConfirm;
  const isMessage = props.item?.notificationHistory.notificationType.id === 2;
  const isTaskExpired =
    props.item?.notificationHistory.notificationType.id === 7;
  return {
    isUser,
    isMessage,
    isTaskExpired,
    isTask: !(isUser || isMessage || isTaskExpired),
  };
});

const userTypeNotificationData = computed(() => {
  if (!currentTypeNotification.value.isUser) return;
  return subordinatesView?.value?.content?.find(
    (s) => s?.id === props.item.notificationHistory.userIdInviteConfirm,
  );
});

const onCloseUserProfile = async () => {
  await notificationsStore.readNotifications(
    props.item?.notificationHistory.id,
    props.item?.notificationHistory.taskId,
  );

  isUserProfileOpen.value = false;
  await usersFetch();
};

const readNotification = async () => {
  if (!props.item?.notificationHistory.id) return;

  if (currentTypeNotification.value.isMessage) {
    await router.replace({ query: { showComments: 'true' } });
  }

  if (currentTypeNotification.value.isUser) {
    return (isUserProfileOpen.value = true);
  }

  await notificationsStore.readNotifications(
    props.item?.notificationHistory.id,
    props.item?.notificationHistory.taskId,
  );
};
</script>

<style lang="scss" scoped>
.notification {
  &__row {
    display: grid;
    grid-template-columns: 0.8rem 1fr 10rem;
    column-gap: 1.2rem;
    padding: 1.3rem 1.6rem;
    background: #fcfcfc;
    border: 0.1rem solid #fafafa;
    border-radius: 1.6rem;
    @include shadow;
    transition: all 0.2s ease-in-out;

    &--new-user {
      .notification__message {
        @include f14;
        line-height: 1.2;
      }
    }

    &:hover {
      background-color: #f2f7f7;
      cursor: pointer;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    @include f16;
    line-height: 1.2;
  }

  &__expired-text {
    @include f16;
  }

  &__avatar {
    @include avatar(3rem);
  }

  &__marker {
    align-self: center;
    justify-self: center;
  }

  &__info {
    grid-column-start: 2;
    @include flex-column(0.4rem);
    justify-content: center;
    overflow-x: hidden;
  }

  &__link {
    color: var(--color-success);
  }

  &__confirm {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    display: flex;
    column-gap: 0.9rem;
    color: var(--color-success);
    align-items: center;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__description,
  &__date {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #b8b8d2;

    &-date {
      margin-top: 0.6rem;
      display: inline-block;
      word-break: keep-all;
      text-align: end;
    }
  }

  &__date {
    align-self: end;
    text-align: end;
  }
}
</style>
