import { createUrl } from '@/utils/url';

const shareLinkUrl = createUrl('link');
const create = shareLinkUrl('share');
const getTask = shareLinkUrl('getTask');
const updateTask = shareLinkUrl('editTask');
const getFiles = shareLinkUrl('getFile');
const uploadFiles = shareLinkUrl('uploadFiles');
const getTaskComments = shareLinkUrl('getTaskComments');
const addComment = shareLinkUrl('addComment');
const changeStatus = shareLinkUrl('changeStatus');
const list = shareLinkUrl('list');
const visible = shareLinkUrl('visible');
export const share = {
  create,
  getTask,
  updateTask,
  getFiles,
  uploadFiles,
  getTaskComments,
  addComment,
  changeStatus,
  list,
  visible,
};
