import { defineStore } from 'pinia';
import { Note, NotePlanner } from '@/types/notes';
import { useAsyncState } from '@vueuse/core';
import services from '@/api/services';
import { Workspace } from '@/types/workspaces';
import { useWorkspaceStore } from '@/stores/workspaces';

export interface NotesByWorkspace {
  workspace: Workspace;
  notes: Note[];
}

export const useNotesByWorkspacesStore = defineStore(
  'notes-by-workspaces',
  () => {
    const workspaceStore = useWorkspaceStore();
    const {
      state: groups,
      isLoading,
      execute,
    } = useAsyncState<NotesByWorkspace[], [notes: Note[]], false>(
      async (notes) => {
        const { data } = await services.notes.scheduleWorkspace(
          notes.map((note) => ({ id: note.id })),
        );

        const groups: NotesByWorkspace[] = workspaceStore.list.map(
          (workspace) => ({
            workspace,
            notes: [],
          }),
        );

        data.forEach((planner) => {
          const group = groups.find(
            (group) => group.workspace.id === planner.companyId,
          );
          const note = notes.find((note) => note.id === planner.id);
          if (group && note) group.notes.push(note);
        });

        groups.sort((a, b) => b.notes.length - a.notes.length);

        return groups;
      },
      [],
      { immediate: false, shallow: false },
    );

    const start = async (notes: Note[]) => {
      await execute(0, notes);
    };

    const end = (): NotePlanner[] => {
      return groups.value
        .filter((group) => group.notes.length)
        .reduce((acc, { workspace, notes }) => {
          acc.push(
            ...notes.map((note) => ({
              companyId: workspace.id,
              id: note.id,
              selectedDate: '',
            })),
          );
          return acc;
        }, [] as NotePlanner[]);
    };

    const $reset = () => {
      groups.value = [];
    };

    return {
      groups,
      isLoading,

      start,
      end,
      $reset,
    };
  },
);
