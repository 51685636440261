import { createUrl } from '@/utils/url';

const notificationsUrl = createUrl('web/notification');

const notification = notificationsUrl('');
const setup = notificationsUrl('setup');
const history = notificationsUrl('history');
const read = notificationsUrl('read');
const notRead = notificationsUrl('countIsNotRead');
const readAll = notificationsUrl('readAll');
const deleteAll = notificationsUrl('deleteAll');

export const notificationsEndpoint = {
  notification,
  history,
  setup,
  read,
  notRead,
  readAll,
  deleteAll,
};
