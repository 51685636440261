import { useI18n } from 'vue-i18n';

export const useCurrencyConvert = () => {
  const { locale } = useI18n();

  const convertPriceWithLanguage = (
    amount: number | string = 0,
    currency = 'USD',
  ) => {
    return Intl.NumberFormat(locale.value, {
      style: 'currency',
      currency,
    }).format(Number(amount) / 100);
  };

  const convertPriceWithLanguagePartials = (
    amount: number | string = 0,
    currency = 'USD',
  ) => {
    return Intl.NumberFormat(locale.value, {
      style: 'currency',
      currency,
    }).formatToParts(Number(amount) / 100);
  };

  const convertPrice = (priceVal: number, currency: string) => {
    const price = convertPriceWithLanguagePartials(priceVal, currency);
    const integer = price.find((item) => item.type === 'integer')?.value;
    const decimal = price.find((item) => item.type === 'decimal')?.value;
    const fraction = price.find((item) => item.type === 'fraction')?.value;

    return {
      price: `${integer}${decimal}${fraction}`,
      currency: price.find((item) => item.type === 'currency')?.value || '',
    };
  };

  return {
    convertPriceWithLanguage,
    convertPriceWithLanguagePartials,
    convertPrice,
  };
};
