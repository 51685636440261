<template>
  <div class="comments-item" :class="{ 'other-comment-item': !isMyComment }">
    <ui-avatar
      :avatar-link="avatar?.uuidLink"
      :fallback-name="creatorFullName"
      class="comments-item__avatar"
      size="3rem"
    />
    <div class="comments-item__message">
      <div class="comments-item__message-content">
        <span class="comments-item__message-name"> {{ creatorFullName }} </span>
        <span class="comments-item__message-text" v-html="messageWithUrls" />
      </div>
      <div class="comments-item__message-controls-block">
        <span class="comments-item__message-date" v-if="parseDate">
          {{ parseDate }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { IFile } from '@/types/common';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { mapFnTextToHtml } from '@/utils/string';
const props = withDefaults(
  defineProps<{
    isMyComment?: boolean;
    createTime?: string;
    avatar?: IFile;
    message?: string;
    creatorFullName?: string;
  }>(),
  { isMyComment: true },
);

const { formatWithLocale } = useCurrentLanguage();

const parseDate = computed(() => {
  if (props.createTime) {
    return formatWithLocale(new Date(props.createTime), 'hh:mmaa');
  }
  return null;
});

const messageWithUrls = computed(() => {
  const words = props.message?.split(' ');
  return words
    ?.map(mapFnTextToHtml)
    .filter(Boolean)
    .join(' ')
    .replace(/"/g, '');
});
</script>
<style lang="scss" scoped>
.comments-item {
  width: 100%;
  align-self: flex-start;
  display: flex;
  gap: 1.2rem;
  margin-bottom: 2rem;
  padding: 0 1.8rem;

  &__avatar {
    @media (max-width: 1000px) and (min-width: 851px) {
      display: none;
    }
  }

  &__message {
    flex: 1;
    background: #e8ffc9;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    @include flex-column(0.3rem);
    align-items: flex-start;
    justify-content: space-between;

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-controls-block {
      margin-left: auto;
      @include flex-column;
      height: 100%;
      justify-content: flex-end;
      align-items: flex-end;
    }

    &-name {
      text-align: left;
      @include f12;
      color: var(--color-success);
    }

    &-text {
      @include f12;
      word-break: break-word;
      text-align: left;
      color: #1a2a39;

      :deep(a) {
        color: var(--color-primary);
        text-decoration: underline;
      }

      @media (max-width: 1000px) {
        @include f12;
      }
    }

    &-controls {
      flex: 1;
    }

    &-date {
      @include f12;
      color: var(--color-success);
    }
  }
}

.other-comment-item {
  flex-direction: row-reverse;

  .comments-item {
    &__message {
      background: #edf2fe;

      &-name {
        color: #a3c0f9;
      }

      &-date {
        color: #a3c0f9;
      }
    }
  }
}
</style>
