<template>
  <transition name="fade">
    <div class="autocomplete" v-if="props.isOpen">
      <slot name="header" class="autocomplete__header" />
      <ui-input
        :label="$t('search')"
        class="autocomplete__control"
        v-model="model"
        icon="search"
      />
      <div class="autocomplete__drop">
        <div
          class="autocomplete__drop-item"
          v-for="item in items"
          :key="item.id"
          @click="onSelectItem(item)"
        >
          <slot :item="item"></slot>
        </div>
        <div class="autocomplete__no-matches" v-if="!items.length && model">
          {{ $t('no_matches') }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SubordinateViewType } from '@/utils/subordinates';

const props = defineProps<{
  items: SubordinateViewType[];
  modelValue: string;
  isOpen: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'selectItem']);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    return emit('update:modelValue', value);
  },
});

const onSelectItem = (item: (typeof props.items)[number]) => {
  emit('selectItem', item);
};
</script>

<style scoped lang="scss">
.autocomplete {
  background: #fcfcfc;
  top: calc(100% + 1rem);
  padding: 1.6rem 1.6rem;
  position: absolute;
  box-shadow: 0 1rem 3rem -0.5rem rgba(87, 87, 87, 0.07);
  border-radius: 0.8rem;
  left: 0;
  z-index: 15;
  width: 100%;

  &__drop {
    width: 100%;
    overflow-y: auto;
    max-height: 20.8rem;
    margin-top: 1rem;

    &::-webkit-scrollbar {
      width: 0.6rem;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.8rem;
      border: 0.4rem solid transparent;
      background-color: #e2e2ec;
    }

    &-item {
      cursor: pointer;
    }
  }

  &__no-matches {
    text-align: center;
  }
}
</style>
