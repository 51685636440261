import { createUrl } from '@/utils/url';

const voiceTokenUrl = createUrl('voiceToken');

const getApiKey = voiceTokenUrl('get');
const finishRecord = voiceTokenUrl('finishRecord');
const getSpeechToken = 'https://mp.speechmatics.com/v1/api_keys?type=rt';

const speechmatics = 'wss://eu2.rt.speechmatics.com/v2';

export const voice = {
  getApiKey,
  finishRecord,
  getSpeechToken,
  speechmatics,
};
