import { RouteRecordRaw } from 'vue-router';
const HomeView = () => import('@/views/home/home.vue');
const HomeIndex = () => import('@/views/home/index.vue');

export default {
  path: '/home',
  component: HomeView,
  meta: {
    layout: 'app',
  },
  children: [{ path: '', name: 'Home', component: HomeIndex }],
} as RouteRecordRaw;
