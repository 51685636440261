import { createUrl } from '@/utils/url';

const billingUrl = createUrl('tariff');

const getUserTariff = billingUrl('getUserTariff');
const getAllTariffHistory = billingUrl('getAllTariffHistory');
const getTariffList = billingUrl('list');

export const tariffs = {
  getUserTariff,
  getAllTariffHistory,
  getTariffList,
};
