<template>
  <transition name="blur" appear mode="out-in">
    <ui-spinner style="height: 17rem" v-if="pending" />
    <div class="workspaces-create-title" v-else>
      <h3 class="workspaces-create-title__header">
        {{ $t('workspaces.create.title') }}
      </h3>
      <ui-input-underscore
        :class="['workspaces-create-title__input', { error: isErrorState }]"
        label="Workspace name"
        v-model="newWorkspaceName"
      />

      <div class="workspaces-create-title__footer">
        <ui-button
          @click="onCreateWorkspace"
          :is-disabled="pending || !newWorkspaceName || isErrorState"
        >
          {{ $t('workspaces.create.create_btn') }}
        </ui-button>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useWorkspaceStore } from '@/stores/workspaces';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTariffStore } from '@/stores/tariffs/tariffs';

const emits = defineEmits(['create', 'setStep']);

const { t } = useI18n();

const billingStore = useTariffStore();

const workspacesStore = useWorkspaceStore();
const { fetchList, create } = workspacesStore;

const pending = ref(false);
const newWorkspaceName = ref('');

const isErrorState = computed(() => newWorkspaceName.value.length > 30);

const onCreateWorkspace = async () => {
  try {
    pending.value = true;
    const createdWorkspace = (await create(newWorkspaceName.value)).data;
    emits('create', createdWorkspace);
    emits('setStep', 2);
    await fetchList();
    await billingStore.fetchUserTariff();
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};
</script>

<style scoped lang="scss">
.workspaces-create-title {
  @include flex-column(2.4rem);
  align-items: center;
  justify-content: center;
  padding: 2.5rem 3rem;
  min-width: 40rem;
  width: 100%;
  color: #1a2a39;

  &__header {
    @include f16;
  }

  &__input {
    width: inherit;
  }

  &__footer {
    margin-left: auto;
  }
}
</style>
