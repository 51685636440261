<script setup lang="ts">
import { NotesByCalendarDay } from '@/stores/notes/useNotesByCalendar';
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import NoteTransformCalendarTask from '@/components/notes/transform/calendar/NoteTransformCalendarTask.vue';
import NoteTransformCalendarNote from '@/components/notes/transform/calendar/NoteTransformCalendarNote.vue';
import { useNoteCalendarDrag } from '@/hooks/notes/useNoteCalendarDrag';
import { useVModel } from '@vueuse/core';
import { isNote } from '@/utils/note';

const props = defineProps<NotesByCalendarDay & { color?: string }>();

const emit = defineEmits(['update:items']);

const data = useVModel(props, 'items', emit);

const day = computed(() => format(new Date(props.date), 'd MMMM'));
const weekDay = computed(() => format(new Date(props.date), 'iiii'));
const workload = computed(
  () => Math.min((data.value.length || 0) / 10, 1) * 100,
);
const workloadColor = computed(() => {
  switch (true) {
    case workload.value >= 60:
      return '#EC7975';
    case workload.value >= 30:
      return '#F19F3C';
    default:
      return '#9BC95E';
  }
});

const removeItem = (id: number) => {
  const index = data.value.findIndex((v) => v.id === id);
  if (index !== -1) data.value.splice(index, 1);
};

const listRef = ref<HTMLDivElement>();

useNoteCalendarDrag({
  el: listRef,
  list: data,
});
</script>

<template>
  <div class="note-calendar-day">
    <div class="note-calendar-day__info">
      <p>
        {{ day }}
      </p>
      <p>
        {{ weekDay }}
      </p>
      <p :style="{ color: workloadColor }">
        {{ $t('notes.workload.current', { percentage: workload }) }}
      </p>
    </div>
    <div ref="listRef" class="note-calendar-day__items">
      <TransitionGroup name="note-item">
        <template v-for="item in data">
          <NoteTransformCalendarNote
            v-if="isNote(item)"
            :key="item.id + item.title"
            :data="item"
            :color="color"
            @close="removeItem"
          />
          <NoteTransformCalendarTask
            v-else
            :color="color"
            :key="item.id + item.name"
            :data="item"
          />
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<style scoped lang="scss">
.note-calendar-day {
  padding: 1rem;
  box-shadow: 0 0 4px 0 #9a9a9a40;
  border-radius: 8px;
  @include flex-row(1.2rem);
  overflow-x: auto;
  position: relative;

  &__info {
    @include flex-column(0.4rem);
    @include f12;
    background: #fcfcfc;
    line-height: 1.8rem;
    flex: 0 0 120px;
    position: sticky;
    left: 0;
    z-index: 10;
  }

  &__items {
    width: 100%;
    gap: 1rem;
    display: flex;

    & > * {
      flex: 0 1 30%;
    }
  }
}
</style>
