<script setup lang="ts"></script>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 14.6667C12.6667 15.0349 12.3683 15.3334 12.0001 15.3334C11.6319 15.3334 11.3334 15.0349 11.3334 14.6667C11.3334 14.2986 11.6319 14.0001 12.0001 14.0001C12.3683 14.0001 12.6667 14.2986 12.6667 14.6667Z"
      fill="currentColor"
    />
    <path
      d="M15.3334 12.0001C15.3334 12.3683 15.0349 12.6667 14.6667 12.6667C14.2986 12.6667 14.0001 12.3683 14.0001 12.0001C14.0001 11.6319 14.2986 11.3334 14.6667 11.3334C15.0349 11.3334 15.3334 11.6319 15.3334 12.0001Z"
      fill="currentColor"
    />
    <path
      d="M2.00008 4.00008C2.00008 4.36827 1.7016 4.66675 1.33341 4.66675C0.965225 4.66675 0.666748 4.36827 0.666748 4.00008C0.666748 3.63189 0.965225 3.33341 1.33341 3.33341C1.7016 3.33341 2.00008 3.63189 2.00008 4.00008Z"
      fill="currentColor"
    />
    <path
      d="M4.66675 1.33341C4.66675 1.7016 4.36827 2.00008 4.00008 2.00008C3.63189 2.00008 3.33341 1.7016 3.33341 1.33341C3.33341 0.965225 3.63189 0.666748 4.00008 0.666748C4.36827 0.666748 4.66675 0.965225 4.66675 1.33341Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00008 4.32203L7.56528 6.11513C7.39148 6.83187 6.83187 7.39148 6.11513 7.56528L4.32203 8.00008L6.11513 8.43488C6.83187 8.60868 7.39148 9.16829 7.56528 9.88503L8.00008 11.6781L8.43488 9.88503C8.60868 9.16829 9.16829 8.60868 9.88503 8.43488L11.6781 8.00008L9.88503 7.56528C9.16829 7.39148 8.60868 6.83187 8.43488 6.11513L8.00008 4.32203ZM8.87024 3.35112C8.64897 2.43862 7.35119 2.43862 7.12992 3.35112L6.52109 5.86193C6.44209 6.18772 6.18772 6.44209 5.86193 6.52109L3.35112 7.12992C2.43862 7.35119 2.43862 8.64897 3.35112 8.87024L5.86193 9.47908C6.18772 9.55807 6.44209 9.81244 6.52109 10.1382L7.12992 12.649C7.35119 13.5615 8.64897 13.5615 8.87024 12.649L9.47908 10.1382C9.55807 9.81244 9.81244 9.55807 10.1382 9.47907L12.649 8.87024C13.5615 8.64897 13.5615 7.35119 12.649 7.12992L10.1382 6.52109C9.81244 6.44209 9.55807 6.18772 9.47907 5.86193L8.87024 3.35112Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00316 14.8765C5.88729 15.187 5.54169 15.3447 5.23124 15.2289C3.22028 14.4783 1.60496 12.9202 0.779904 10.9486C0.65198 10.643 0.796081 10.2914 1.10176 10.1635C1.40745 10.0356 1.75896 10.1797 1.88688 10.4854C2.58422 12.1517 3.95111 13.4702 5.65085 14.1046C5.9613 14.2205 6.11904 14.5661 6.00316 14.8765Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.877 6.01393C14.5666 6.13004 14.2209 5.97257 14.1048 5.66221C13.4654 3.95311 12.1315 2.58056 10.4463 1.88842C10.1398 1.76252 9.99335 1.41198 10.1192 1.10545C10.2451 0.798929 10.5957 0.652503 10.9022 0.7784C12.8952 1.59697 14.4718 3.21873 15.2287 5.24172C15.3448 5.55209 15.1873 5.89781 14.877 6.01393Z"
      fill="currentColor"
    />
  </svg>
</template>
