<script setup lang="ts">
import { ref } from 'vue';
import { Note } from '@/types/notes';
import NoteItem from '@/components/notes/item/NoteItem.vue';
import { useSelection } from '@/stores/notes/useNotesList';
import { useNoteToTaskDrag } from '@/hooks/notes/useNoteToTaskDrag';
import NotesSelection from '@/components/notes/NotesSelection.vue';
import { Workspace } from '@/types/workspaces';
import { useVModel } from '@vueuse/core';
import { NoteTransformType } from '@/stores/notes/useNotesTransform';

const props = defineProps<{
  workspace: Workspace;
  modelValue: Note[];
  mode: NoteTransformType;
}>();
const emit = defineEmits(['update:modelValue']);
const notes = useVModel(props, 'modelValue', emit);

const listRef = ref<HTMLDivElement>();
const { selection, isSelected } = useSelection();
useNoteToTaskDrag({
  el: listRef,
  list: notes,
  isSelected,
  selection,
  group: 'notes-workspaces',
});

const removeNote = (id: number) => {
  const index = notes.value.findIndex((v) => v.id === id);
  if (index !== -1) notes.value.splice(index, 1);
};
</script>

<template>
  <div class="notes-list-workspace">
    <NotesSelection v-model="selection" />
    <div class="notes-list-workspace__header">
      <span :style="{ backgroundColor: workspace.color ?? 'gray' }"></span>
      <p>{{ workspace.name }}</p>
    </div>
    <div ref="listRef" class="notes-list-workspace__body">
      <TransitionGroup name="note-item">
        <NoteItem
          v-for="item in notes"
          :key="item.id"
          :data="item"
          :is-selected="isSelected(item.id)"
          hide-controls
          uncheckable
          hide-number
          :closable="mode === 'many'"
          disable-open
          @close="removeNote"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notes-list-workspace {
  position: relative;
  @include flex-column;
  height: 100%;
  box-shadow: 4px 4px 10px 0px #a6a6a640;
  background: #f2f7f7;
  border-radius: 8px;
  overflow-y: auto;

  &__header {
    padding: 1rem 1rem 0.6rem;
    position: sticky;
    z-index: 10;
    background: #f2f7f7;
    top: 0;
    @include f14;
    @include flex-row(1rem);
    align-items: center;

    span {
      flex: 0 0 auto;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  }

  &__body {
    padding: 0.6rem 1rem 1rem;
    flex: 1 0 auto;
    @include flex-column(1.6rem);
  }
}
</style>
