<template>
  <el-dialog :model-value="isOpen" @close="onClose" width="46rem">
    <modal-create-workspace-title
      v-if="step === 1"
      @create="createdWorkspace = $event"
      @set-step="step = $event"
    />
    <modal-create-workspace-invitations
      v-else-if="step === 2"
      :workspace="createdWorkspace"
      :users="subordinatesView"
      @next-step="step++"
    />
    <modal-switch-workspace
      v-else-if="step === 3"
      :workspace="createdWorkspace"
      @close="onClose"
    />
  </el-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import ModalCreateWorkspaceInvitations from '@/components/modals/Workspaces/Create/ModalCreateWorkspaceInvitations.vue';
import ModalCreateWorkspaceTitle from '@/components/modals/Workspaces/Create/ModalCreateWorkspaceTitle.vue';
import ModalSwitchWorkspace from '@/components/modals/Workspaces/ModalSwitchWorkspace.vue';
import { Workspace } from '@/types/workspaces';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { useModalCreateTaskStore } from '@/stores/modalTaskCreate';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

type Props = {
  isOpen?: boolean;
};

defineProps<Props>();
const emits = defineEmits(['close']);
const createdWorkspace = ref<Workspace>();

const invitationsStore = useInvitationsStore();

const userStore = useUserStore();
const { userData } = storeToRefs(userStore);

const modalCreateTaskStore = useModalCreateTaskStore();
const { getSubordinates } = modalCreateTaskStore;
const { subordinates, isSubordinatesFetching } =
  storeToRefs(modalCreateTaskStore);

const step = ref(1);

const subordinatesView = computed(
  () => subordinates.value?.filter((s) => s.id !== userData.value?.id) || [],
);

const onClose = async () => {
  await invitationsStore.fetchInvitations();
  setTimeout(() => (step.value = 1), 1000);
  emits('close');
};

watch(
  () => step.value,
  () => step.value === 2 && !isSubordinatesFetching.value && getSubordinates(),
);
</script>

<style scoped lang="scss"></style>
