<script lang="ts" setup>
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';
import HeaderActions from './HeaderActions.vue';
import HeaderInfo from './HeaderInfo.vue';
import { storeToRefs } from 'pinia';
import { useDebounceFn } from '@vueuse/core';
import HeaderWorkspaceSelect from './HeaderWorkspaceSelect.vue';

interface Props {
  isOpen?: boolean;
  isShare?: boolean;
}

defineProps<Props>();
defineEmits(['close']);

const store = useModalViewTaskStore();
const { isShowComments, activeTask, isTaskLoading, isReadonly } =
  storeToRefs(store);

const toggleComments = () => {
  if (isShowComments.value) {
    store.closeComments();
    useDebounceFn(() => {
      store.clearComments();
    }, 500)();
  } else {
    store.openComments();
    store.getComments();
  }
};
</script>

<template>
  <HeaderInfo
    :is-loading="isTaskLoading"
    :is-readonly="isReadonly"
    :number="activeTask.itemNumber"
    :status="activeTask.taskStatus"
  >
    <HeaderWorkspaceSelect
      :company-id="activeTask.companyId"
      @select="activeTask.companyId = $event"
    />
  </HeaderInfo>
  <HeaderActions
    :is-loading="isTaskLoading"
    :is-open="isOpen"
    :is-share="isShare"
    :is-show-comments="isShowComments"
    @toggle-comments="toggleComments"
    @close="$emit('close')"
  />
</template>
