import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { TASK_DAY_FORMAT, TASK_TIME_FORMAT } from '@/consts/timeFormats';
import { SetTaskTimeParams } from '@/types/tasks';
import { getTimeByParts } from '@/utils/time';
import { format, isValid, setHours, setMinutes } from 'date-fns';
import { Ref, computed } from 'vue';

export const useTaskDate = (
  data: Ref<{
    deadline: string;
    deadlineLast?: string | null;
    notifyTime?: string | null;
  }>,
) => {
  const { formatWithLocale } = useCurrentLanguage();

  const dateView = computed(() => {
    const date = new Date(data.value.deadline);
    return isValid(date) ? formatWithLocale(date, 'dd MMMM yyyy') : '';
  });

  function setDate(date: Date) {
    data.value.deadline = format(new Date(date), TASK_DAY_FORMAT);

    data.value.deadlineLast = undefined;
  }

  function setTime({ startTime, endTime, reminderTime }: SetTaskTimeParams) {
    const currentDate = new Date(data.value.deadline);

    if (startTime) {
      const [startHour, startMinutes] = getTimeByParts(startTime);

      data.value.deadline = format(
        setMinutes(setHours(currentDate, startHour), startMinutes),
        TASK_TIME_FORMAT,
      );
    } else {
      setDate(currentDate);
    }

    if (endTime) {
      const [endHour, endMinutes] = getTimeByParts(endTime);

      data.value.deadlineLast = format(
        setMinutes(setHours(currentDate, endHour), endMinutes),
        TASK_TIME_FORMAT,
      );
    } else {
      data.value.deadlineLast = undefined;
    }

    if (reminderTime) {
      const [reminderHour, reminderMinutes] = getTimeByParts(reminderTime);
      data.value.notifyTime = format(
        setMinutes(setHours(currentDate, reminderHour), reminderMinutes),
        TASK_TIME_FORMAT,
      );
    } else {
      data.value.notifyTime = undefined;
    }
  }

  return {
    dateView,
    setDate,
    setTime,
  };
};
