import { ENDPOINTS } from '@/api/endpoints';
import { AuthService } from '@/api/services/AuthService';
import { InvitationService } from '@/api/services/InvitationService';
import { TasksService } from '@/api/services/TasksService';
import { PositionService } from '@/api/services/PositionService';
import { SpeechMaticsService } from '@/api/services/SocketService/speechMatics';
import { VoiceService } from '@/api/services/VoiceService';
import { FilesService } from '@/api/services/FilesService';
import { TariffsService } from '@/api/services/TariffsService';
import { ShareLinkService } from '@/api/services/ShareLink';
import { CommentService } from '@/api/services/CommentService';
import { AnalyticsService } from '@/api/services/AnalyticsService';
import { NotificationsService } from '@/api/services/NotificationsService';
import { localeService } from '@/api/services/localeService';
import { PurchaseService } from '@/api/services/PurchaseService';
import { UsersService } from '@/api/services/UsersService';
import { ReportsService } from '@/api/services/ReportsService';
import { WorkspacesService } from '@/api/services/WorkspacesService';
import { NotesService } from '@/api/services/NotesService';
import { AgendaService } from '@/api/services/AgendaService';
import { IntegrationsService } from '@/api/services/IntegrationsService';

export default {
  auth: new AuthService(),
  invitation: new InvitationService(),
  tasks: new TasksService(),
  positions: new PositionService(),
  voice: new VoiceService(),
  files: new FilesService(),
  share: new ShareLinkService(),
  tariffs: new TariffsService(),
  comments: new CommentService(),
  analytics: new AnalyticsService(),
  notifications: new NotificationsService(),
  locale: new localeService(),
  purchase: new PurchaseService(),
  users: new UsersService(),
  reports: new ReportsService(),
  workspaces: new WorkspacesService(),
  notes: new NotesService(),
  agenda: new AgendaService(),
  integrations: new IntegrationsService(),
};

export const sockets = {
  speech: new SpeechMaticsService({
    url: ENDPOINTS.voice.speechmatics,
  }),
};
