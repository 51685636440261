<template>
  <div :class="['card-tariff', { popular }]">
    <ui-plan-popular v-if="popular" :theme="theme" />
    <div class="card-tariff__header">
      <ui-plan-title
        :color="themeColors.primaryColor"
        :type="itemView.title"
        class="card-tariff__title"
      />
      <div class="card-tariff__description">
        {{ t(`card_tariff.services.description.${item.title}`) }}
      </div>
      <div class="card-tariff__period" @click="isAnnually = !isAnnually">
        {{ $t(`card_tariff.${isAnnually ? 'annually' : 'monthly'}`) }}
        <el-switch :class="themeColors.controls" :model-value="isAnnually" />
        <span
          :class="['card-tariff__period-discount', { visible: isAnnually }]"
        >
          {{ $t('card_tariff.discount', { count: freeMonthsCount }) }}
        </span>
      </div>
    </div>
    <div class="card-tariff__body">
      <div class="card-tariff__price">
        <div :class="['card-tariff__price-container', { hidden: !isAnnually }]">
          <ui-price
            :period="$t('card_tariff.year')"
            :price="prices.initialAnnuallyRawDiscountPrice"
            :currency="itemView.currency"
            crossed
          />
        </div>
        <div class="card-tariff__price-container">
          <ui-price
            :period="$t(`card_tariff.${isAnnually ? 'year' : 'month'}`)"
            :price="prices.currentRawPrice.value"
            :currency="itemView.currency"
            :color="themeColors.primaryColor"
            :period-color="themeColors?.periodColor"
          />
        </div>
      </div>
      <ui-plan-features :item="itemView" :theme="theme" />
    </div>
    <div class="card-tariff__footer">
      <slot
        name="cardButton"
        :isAnnually="isAnnually"
        :isActiveTariff="isActiveTariff"
      >
        <ui-button
          class="card-tariff__button"
          :color="themeColors.controls"
          :is-loading="isLoadingGenerateLink"
          :is-disabled="isActiveTariff"
          @click="
            $emit('change-subscription', item.id, isAnnually, currentPaymentId)
          "
        >
          {{
            isActiveTariff
              ? $t('card_tariff.current_tariff')
              : `${$t('buy')} ${itemView.name}`
          }}
        </ui-button>
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import UiButton from '@/components/control/UiButton.vue';
import UiPlanTitle from '@/components/ui/UiPlan/UiPlanTitle.vue';
import UiPrice from '@/components/billing/UiPrice.vue';
import { useI18n } from 'vue-i18n';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { ITariffItemResponse } from '@/types/tariffs';
import { storeToRefs } from 'pinia';
import UiPlanFeatures from '@/components/ui/UiPlan/UiPlanFeatures.vue';
import UiPlanPopular from '@/components/ui/UiPlan/UiPlanPopular.vue';
import { usePrices } from '@/hooks/usePrices';
import { TARIFF_CARD_THEME } from '@/consts/themes';

interface Props {
  item: ITariffItemResponse;
  isActive?: boolean;
  freeMonthsCount?: number;
  popular?: boolean;
}

const props = withDefaults(defineProps<Props>(), { freeMonthsCount: 2 });

const isAnnually = ref(false);
const isLoadingGenerateLink = ref(false);

const { tariffsView } = storeToRefs(useTariffStore());
const { t } = useI18n();

const { prices } = usePrices(
  computed(() => props.item),
  isAnnually,
);

const isActiveTariff = computed(() => {
  return props.isActive || itemView.value.id === tariffsView?.value?.tariff?.id;
});

const itemView = computed(() =>
  isAnnually.value && props.item?.annualTariff
    ? props.item.annualTariff
    : props.item,
);

const theme = computed(() => {
  return isActiveTariff.value ? 'active' : 'default';
});

const themeColors = computed(() => {
  return TARIFF_CARD_THEME[theme.value];
});

const currentPaymentId = computed(
  () => `${itemView.value?.stripeTariffId}-${itemView.value?.stripePriceId}`,
);

onMounted(() => {
  if (
    tariffsView.value.tariff?.typeRefresh === 'annual' &&
    props.item.annualTariff?.id === tariffsView?.value?.tariff?.id
  )
    isAnnually.value = true;
});
</script>

<style lang="scss" scoped>
.card-tariff {
  position: relative;
  flex: 0;
  @include flex-column;
  padding: 2rem;
  box-shadow: 1rem 1rem 4rem 0 rgba(66, 66, 66, 0.25);
  border-radius: 1.6rem;
  background-color: v-bind('themeColors.background');
  transition: all 0.2s ease-in;
  border: transparent solid 0.1rem;
  min-width: 33.4rem;
  @include f14;

  @media (max-width: 360px) {
    min-width: 30rem;
  }

  &:hover {
    border-color: v-bind('themeColors.border');
    box-shadow: none;
  }

  &.popular {
    padding-top: 6rem;
  }

  &__header,
  &__title {
    text-align: center;
    margin-bottom: 1.2rem;
  }

  &__description,
  &__period {
    @include f12;
  }

  &__period {
    @include flex-row(1.2rem);
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-discount {
      font-weight: 600;
      overflow: hidden;
      max-width: 0;
      white-space: nowrap;
      transition: all 0.15s ease-in-out;

      &.visible {
        max-width: 100%;
      }
    }
  }

  &__body {
    text-align: center;
    flex-grow: 1;
  }

  &__price {
    margin-bottom: 1.2rem;
    @include flex-row(0.5rem);
    align-items: center;
    justify-content: center;

    @media (max-width: 360px) {
      flex-direction: column;
    }

    &-container {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      transition: all 0.15s ease-in-out;

      & > * {
        overflow: hidden;
      }

      &.hidden {
        overflow: hidden;
        grid-template-rows: 0fr;
        grid-template-columns: 0fr;
      }
    }
  }

  &__footer {
    display: grid;
    margin-top: 1.2rem;
  }
}
</style>
