import { ref } from 'vue';

export const useTouchDevice = () => {
  const isTouchDevice = ref(touchDevice());

  function touchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  return {
    isTouchDevice,
  };
};
