import { RouteRecordRaw } from 'vue-router';

const AnalyticsView = () => import('@/views/analytics/analytics.vue');
const AnalyticsIndex = () => import('@/views/analytics/index.vue');
const AnalyticsTeamIndex = () => import('@/views/analytics/TeamIndex.vue');

export default {
  path: '/analytics',
  component: AnalyticsView,
  meta: {
    layout: 'app',
  },
  children: [
    { path: '', name: 'Analytics', component: AnalyticsIndex },
    { path: '/team', name: 'TeamAnalytics', component: AnalyticsTeamIndex },
  ],
} as RouteRecordRaw;
