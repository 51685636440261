<template>
  <div class="ui-input">
    <component
      :is="type === 'textarea' ? 'textarea' : 'input'"
      v-bind="$attrs"
      :type="type"
      :value="modelValue"
      :class="['ui-input__control', { 'not-empty': modelValue?.length }]"
      :style="{
        'max-height': type === 'textarea' && 'unset',
        'padding-left': leftAlign,
        'padding-right': $slots.action && '4rem',
      }"
      @input="handleInput"
      ref="input"
    />
    <label
      class="ui-label"
      v-if="label"
      @click="input?.focus"
      :style="{ left: leftAlign }"
    >
      {{ label }}
    </label>
    <img
      class="hide-pass"
      v-if="$attrs.type === 'password'"
      src="@images/hide-pass.svg"
      :style="{ right: iconAlign === 'right' && icon ? '3.6rem' : '1.2rem' }"
      @click="type = type === 'password' ? 'text' : 'password'"
      alt=""
    />
    <transition name="fade" appear>
      <img
        :key="icon"
        class="ui-input__pictogram"
        v-if="icon"
        :style="{ [iconAlign]: '1.2rem' }"
        :src="require(`@images/pictogram/${icon}.svg`)"
        alt=""
      />
    </transition>
    <div class="ui-input__action" v-if="$slots.action">
      <slot name="action" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, toRefs, useAttrs } from 'vue';

const input = ref<null | HTMLInputElement>(null);
defineExpose({ input });

type Props = {
  label?: string;
  icon?: 'search' | 'calendar' | 'contact' | 'send' | 'user-change' | 'smart';
  iconAlign?: 'right' | 'left';
  modelValue?: string;
  debounce?: number | null;
  debouncedInput?: (v: string, meta: unknown) => void;
  debounceMeta?: unknown;
};

const props = withDefaults(defineProps<Props>(), {
  label: '',
  iconAlign: 'left',
  modelValue: '',
  debounce: null,
});

const { icon, iconAlign } = toRefs(props);

const attrs = useAttrs();
const type = ref(attrs.type);

const leftAlign = computed(() =>
  icon?.value && iconAlign.value === 'left' ? '3.6rem' : '1.2rem',
);

let debounceHandler: number | undefined = undefined;

const handleInput = (e: Event) => {
  const value = (e.target as HTMLInputElement).value;
  emits('update:modelValue', value);

  if (!props.debounce) return;
  clearTimeout(debounceHandler);
  const meta = props.debounceMeta;
  debounceHandler = setTimeout(
    () => props.debouncedInput && props.debouncedInput(value, meta),
    props.debounce,
  );
};

const emits = defineEmits(['update:modelValue']);
</script>

<style lang="scss" scoped>
.ui-input {
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;

  &[disabled]:not([disabled='false']) {
    pointer-events: none;
    opacity: 0.5;
  }

  &__control {
    max-height: 4rem;
    width: 100%;
    padding: 1.4rem 1.2rem;
    border: 0.1rem solid #a3c0f9;
    border-radius: 0.8rem;
    background: #fcfcfc;

    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;

    outline: none;
    transition: all 0.1s ease-in-out;

    &[highlighted]:not([highlighted='false']) {
      box-shadow: 0 0 0.54rem 0 #778bee;
      border-radius: 0.8rem;
    }

    &[is-smart-value]:not([is-smart-value='false']) {
      border-color: var(--color-primary);
      color: var(--color-primary);
    }

    &[disabled]:not([disabled='false']) {
      border-color: #cecede;
    }

    &:focus {
      border-color: var(--color-primary);
    }

    &:focus,
    &.not-empty {
      + label {
        top: 0;
        left: 1.6rem;
        line-height: 0.2rem;
        font-size: 1.2rem;
        color: #1a2a39;
      }
    }

    &.error {
      border-color: var(--color-danger);
    }

    &.success {
      border-color: var(--color-success);
    }
  }

  label {
    position: absolute;
    left: 1.2rem;
    top: 50%;
    background: #fcfcfc;
    border-radius: 0.2rem;
    transform: translateY(-50%);
    @include f14;
    color: #b8b8d2;
    cursor: text;
    transition: all 0.15s ease-in-out;
  }

  .hide-pass {
    position: absolute;
    right: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__pictogram {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__action {
    display: flex;
    align-items: center;
  }
}
</style>
