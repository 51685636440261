import { computed, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { Note } from '@/types/notes';
import { useAsyncState, watchDebounced } from '@vueuse/core';
import services from '@/api/services';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useNotesListStore } from '@/stores/notes/useNotesList';
import { deepEqual } from '@/utils/object';

export const useNoteStore = defineStore('note', () => {
  const isStarted = ref(false);

  const note = ref<Pick<Note, 'title' | 'description' | 'status'>>({
    title: '',
    description: '',
    status: 'ACTIVE',
  });
  const isNoteValid = computed(
    () => note.value?.title?.length > 0 || note.value?.description?.length > 0,
  );

  const {
    state: initialNote,
    execute: fetchNote,
    isLoading: isNoteLoading,
  } = useAsyncState<Note | null, [id: number], false>(
    async (id: number) => {
      const { data } = await services.notes.get(id);
      note.value = {
        title: data.title,
        description: data.description,
        status: data.status,
      };
      return data;
    },
    null,
    {
      immediate: false,
      shallow: false,
    },
  );

  const {
    state: audioFile,
    execute: fetchAudioFile,
    isLoading: isAudioLoading,
  } = useAsyncState<string | null, [id: number], false>(
    async (id: number) => {
      const { data } = await services.files.getFileById(id);
      return URL.createObjectURL(data);
    },
    null,
    {
      immediate: false,
      shallow: false,
    },
  );

  let stopWatch: (() => void) | null = null;

  const noteListStore = useNotesListStore();

  const createWatchers = () => {
    const stop = watchDebounced(
      () => [note.value.title, note.value.description],
      () => updateNote(),
      { debounce: 3000 },
    );
    const stopStatus = watch(
      () => [note.value.status],
      async () => {
        if (initialNote.value) {
          noteListStore.toggleStatus([initialNote.value.id]);
        }
      },
    );
    return () => {
      stop();
      stopStatus();
    };
  };

  const start = async (id: number) => {
    isStarted.value = true;
    const note = await fetchNote(0, id);
    stopWatch = createWatchers();
    if (note?.audioFileId) {
      await fetchAudioFile(0, note.audioFileId);
    }
  };

  const end = async () => {
    stopWatch?.();
    if (
      initialNote.value &&
      !deepEqual(
        {
          title: initialNote.value.title,
          description: initialNote.value.description,
          status: initialNote.value.status,
        },
        note.value,
      )
    ) {
      await updateNote(true);
      await Promise.all([
        noteListStore.fetchNotes(),
        noteListStore.fetchCompletedNotes(),
      ]);
    }
    $reset();
  };

  const { t } = useI18n();

  const updateNote = async (showSuccess = false) => {
    if (!initialNote.value) return;
    try {
      const payload: Note = {
        ...initialNote.value,
        ...note.value,
      };
      const {
        data: [value],
      } = await services.notes.update([payload]);
      initialNote.value = value;
      if (showSuccess)
        ElNotification.success({
          message: t('notes.update_message'),
          offset: 80,
        });
    } catch (e) {
      ElNotification.error({
        message: t('some_error'),
        offset: 80,
      });
      console.error(e);
    }
  };

  const $reset = () => {
    stopWatch?.();
    isStarted.value = false;
    note.value = {
      title: '',
      description: '',
      status: 'ACTIVE',
    };
    initialNote.value = null;
    audioFile.value = null;
    noteListStore.fetchNotes();
  };

  return {
    isStarted,
    note,
    isNoteValid,
    audioFile,
    isAudioLoading,
    isNoteLoading,
    initialNote,

    updateNote,
    start,
    end,
    $reset,
  };
});
