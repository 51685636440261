//TODO: add i18n support when it's ready

// import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';

// import { support_languages } from '/@src/plugins/i18/support_lang';
import { format, fromUnixTime } from 'date-fns';
import { i18n } from '@/plugins/i18n';

export type FormatFnType = (...args: Parameters<typeof format>) => string;

export function useCurrentLanguage() {
  const currentLanguage = computed(() => {
    // const currentLang = support_languages.find(
    //   (item) => item.navigatorLang === locale.value,
    // );
    // return currentLang?.locale || en;
    const langsArray = {
      en,
      ru,
    };

    const lang =
      (Object.keys(langsArray) as (keyof typeof langsArray)[]).find(
        (key) => i18n.global.locale.value === key,
      ) || 'en';
    return langsArray[lang];
  });

  const formatWithLocale: FormatFnType = (...[date, pattern, options]) => {
    if (typeof date === 'number') date = fromUnixTime(date);
    return format(date, pattern, {
      locale: currentLanguage.value,
      ...options,
    });
  };

  return { currentLanguage, formatWithLocale };
}
