<template>
  <el-popover
    trigger="click"
    width="fit-content"
    popper-class="calendar-popup"
    :disabled="isReadonly"
    :visible="isCalendarVisible"
    :popper-options="POPPER_MODIFIERS"
  >
    <div class="calendar-popup__layout" @click="isCalendarVisible = false" />
    <div class="calendar-popup__content">
      <div class="calendar-popup__date">
        <div class="calendar-popup__executor" v-if="executor">
          <ui-avatar
            size="3.5rem"
            :avatar-link="executor?.avatar?.uuidLink"
            :fallback-name="concatName(executor.firstName, executor.lastName)"
          />
          <span>{{ concatName(executor.firstName, executor.lastName) }}</span>
          <transition name="fade" appear mode="out-in">
            <div
              class="calendar-popup__workload"
              v-if="currentWorkloadLevel"
              :key="currentWorkloadLevel.color"
            >
              <icon-marker
                :color="currentWorkloadLevel.color"
                v-if="currentWorkloadLevel.color"
              />
              {{ currentWorkloadLevel.message }}
            </div>
          </transition>
        </div>
        <v-date-picker
          :key="isCalendarVisible"
          :locale="$i18n.locale"
          :model-value="modelValue"
          :attributes="attributes"
          @update:modelValue="onCalendarUpdate"
          @did-move="!isShare && updateCalendar($event)"
          :min-date="new Date()"
          is-required
          borderless
          ref="calendar"
          style="min-width: unset; --vc-width: 30rem; margin: auto"
        >
          <template #header-title="{ title }">
            <span style="font-size: 1.4rem; line-height: 1.6rem">
              {{ title }}
            </span>
          </template>
        </v-date-picker>
      </div>
      <div class="calendar-popup__time">
        <!--        <div class="calendar-popup__time-row">-->
        <!--          <el-checkbox>Repeat</el-checkbox>-->
        <!--        </div>-->
        <div class="calendar-popup__time-row">
          <el-checkbox
            :disabled="!date"
            :modelValue="isAllDay"
            @update:modelValue="onToggleAllDay"
            class="no-padding"
          >
            All day
          </el-checkbox>
        </div>
        <div :class="['calendar-popup__time-options', { open: !isAllDay }]">
          <div class="calendar-popup__time-container">
            <div class="calendar-popup__time-row">
              <TimeSelectRange
                :disabled="!date || isAllDay"
                v-model:startTime="start"
                v-model:endTime="end"
              />
            </div>
            <div class="calendar-popup__time-row">
              <TimeReminder
                :disabled="!date || isAllDay"
                :startTime="start"
                v-model="reminder"
              />
            </div>
          </div>
        </div>
        <!--        <div class="calendar-popup__time-row">-->
        <!--          <el-checkbox class="no-padding"> Reschedule deadlines </el-checkbox>-->
        <!--        </div>-->
        <div class="calendar-popup__time-controls">
          <ui-button
            plain
            @click="
              onDateChange();
              isCalendarVisible = false;
            "
          >
            {{ $t('cancel') }}
          </ui-button>
          <ui-button
            @click="
              onSave();
              isCalendarVisible = false;
            "
            :is-disabled="!date || (!isAllDay && !(start && end))"
          >
            {{ $t('save') }}
          </ui-button>
        </div>
      </div>
    </div>
    <template #reference>
      <div @click="isCalendarVisible = !isCalendarVisible">
        <ui-input
          v-bind="$attrs"
          ref="inputRef"
          :model-value="calendarDateView"
          :label="$t('modal_task.date_range')"
          icon-align="right"
          :icon="$attrs.isSmartValue ? 'smart' : 'calendar'"
          readonly
        />
      </div>
    </template>
  </el-popover>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue';
import TimeSelectRange from '@/components/time/TimeSelectRange.vue';
import UiButton from '@/components/control/UiButton.vue';
import { SetTaskTimeParams, Task } from '@/types/tasks';
import {
  addHours,
  addMinutes,
  endOfDay,
  getMonth,
  getYear,
  isSameDay,
  isValid,
  startOfHour,
} from 'date-fns';
import format from 'date-fns/format';
import { useCalendarWorkload } from '@/hooks/useCalendarWorkload';
import { useTasksStore } from '@/stores/tasks/tasks';
import { DatePicker } from 'v-calendar';
import { useI18n } from 'vue-i18n';
import { isAllDayDate } from '@/utils/time';
import { watchDebounced } from '@vueuse/core';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { SubordinateViewType } from '@/utils/subordinates';
import { concatName } from '@/utils/text';
import IconMarker from '@/components/icon/IconMarker.vue';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import TimeReminder from '@/components/time/TimeReminder.vue';
import { TASK_DAY_FORMAT } from '@/consts/timeFormats';
import UiInput from '@/components/control/UiInput.vue';
import { POPPER_MODIFIERS } from '@/consts/agenda/popperModifiers';

type Props = {
  modelValue: string;
  endTime?: string;
  dateView: string;
  executor?: Task['executor'] | SubordinateViewType | null;
  reminder?: string | false;
  isShare?: boolean;
  isReadonly?: boolean;
};

const props = defineProps<Props>();
const isShare = toRef(props, 'isShare');

const { t } = useI18n();
const { formatWithLocale } = useCurrentLanguage();

const calendar = ref<InstanceType<typeof DatePicker> | null>(null);
const executor = toRef(props, 'executor');
const tasksStore = useTasksStore();
const { getMonthTaskCountsByExecutor } = tasksStore;

const { attributes, updateCalendar } = useCalendarWorkload(
  getMonthTaskCountsByExecutor,
  computed(() => executor.value?.id),
);
const currentWorkloadLevel = computed(() => {
  const customColorMapping: Record<
    string,
    { color?: string; message: string }
  > = {
    'custom-green': { color: '#9bda4f', message: t('workload.low') },
    'custom-orange': { color: '#e98629', message: t('workload.average') },
    'custom-red': { color: '#ed4436', message: t('workload.high') },
    'custom-gray': { message: t('workload.empty') },
    empty: { message: t('workload.empty') },
  };
  const dayColor = attributes.value.find(
    (a) => a.dates.getDate() === new Date(date.value).getDate(),
  )?.highlight.color;
  return customColorMapping[dayColor || 'empty'];
});

const isCalendarVisible = ref(false);
const inputRef = ref<InstanceType<typeof UiInput>>();

const date = ref();
const start = ref<Date | undefined>();
const end = ref<Date | undefined>();
const reminder = ref<string | false>(false);
const isAllDay = ref(true);

const currentTimeView = computed(() => {
  let time = '';
  if (start.value) time += format(start.value, 'HH:mm');
  if (end.value && start.value) time += ` - ${format(end.value, 'HH:mm')}`;
  return time;
});

const calendarDateView = computed(() => {
  const computedDate =
    (isCalendarVisible.value &&
      isValid(date.value) &&
      formatWithLocale(date.value, 'dd MMMM yyyy')) ||
    props.dateView ||
    '';

  return (
    `${computedDate}${
      currentTimeView.value ? ' | ' + currentTimeView.value : ''
    }`.trim() || undefined
  );
});

const isCurrentDateValid = computed(() => isValid(new Date(props.modelValue)));

const onCalendarUpdate = (d: Date) => {
  // if (isEqual(d, new Date(props.modelValue))) return;
  date.value = d;
};

const onSaveTime = () => {
  emits('update:time', {
    startTime: start.value,
    endTime: end.value,
    reminderTime: reminder.value ? new Date(reminder.value) : false,
  } satisfies SetTaskTimeParams);
};

const onSave = () => {
  emits('update:modelValue', date.value);
  date.value = undefined;
  onSaveTime();
};

const onResetTime = () => {
  start.value = undefined;
  end.value = undefined;
  reminder.value = false;
};

const onExecutorChange = async () => {
  if (isShare.value) return;
  const date = isCurrentDateValid.value
    ? new Date(props.modelValue)
    : new Date();
  await updateCalendar([{ month: getMonth(date) + 1, year: getYear(date) }]);
  onDateChange();
};

const onDateChange = () => {
  if (!isCurrentDateValid.value) return onResetTime();
  date.value = props.modelValue || format(new Date(), TASK_DAY_FORMAT);
  end.value = props.endTime ? new Date(props.endTime) : undefined;
  isAllDay.value = !(start.value && end.value);
  start.value = isAllDayDate(props.modelValue)
    ? undefined
    : new Date(props.modelValue);
  reminder.value = props.reminder
    ? format(new Date(props.reminder), 'yyyy-MM-dd HH:mm')
    : false;
};

const onCalendarToggle = async () => {
  if (!isCalendarVisible.value) {
    date.value = undefined;
    isAllDay.value = true;
    queueMicrotask(() => inputRef.value?.input?.blur());
    return;
  }
  if (!isCurrentDateValid.value) return;
  onDateChange();
  await calendar.value?.move(props.modelValue);
};

const onToggleAllDay = (value: boolean) => {
  isAllDay.value = value;
  if (isAllDay.value) onResetTime();
  else {
    start.value = startOfHour(addHours(new Date(), 1));

    const endIntervalTime = addMinutes(start.value, 30);
    end.value = isSameDay(start.value, endIntervalTime)
      ? endIntervalTime
      : endOfDay(start.value);
  }
};

watch(() => isCalendarVisible.value, onCalendarToggle);
watch(() => executor.value?.id, onExecutorChange);
watch(() => props.modelValue, onDateChange, { immediate: true });
watchDebounced(
  () => props.modelValue,
  async () => await onExecutorChange(),
  { debounce: 300 },
);

const emits = defineEmits(['update:modelValue', 'update:time']);
</script>

<style scoped lang="scss">
.calendar-popup {
  &__layout {
    position: fixed;
    inset: 0;
  }

  &__content {
    @include flex-row;
    position: relative;
    transition: all 0.15s ease-in-out;
    padding: 1.6rem;
    background: #fcfcfc;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__executor {
    @include flex-row(1.4rem);
    align-items: center;
    justify-content: space-between;

    & > span {
      max-width: 8rem;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }

  &__workload {
    @include flex-row(1.2rem);
    @include f12;
    align-items: center;
    max-width: 50%;
    margin-left: auto;

    & > svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__date {
    @include flex-column;
    padding-right: 1.6rem;
    border-right: 0.1rem solid #dde3ec;

    @media (max-width: 768px) {
      padding-right: 0;
      border: none;
    }
  }

  &__time {
    padding-left: 1.6rem;
    @include flex-column(2.4rem);
    width: 27rem;

    &-switch {
      margin-right: 1.5rem;
    }

    &-row {
      @include flex-row(1rem);
      align-items: center;
      justify-content: space-between;

      .el-checkbox {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-container {
      @include flex-column(2.4rem);
      overflow: hidden;
    }

    &-options {
      display: grid;
      grid-template-rows: 0fr;
      transition: all 0.15s ease-in-out;

      &.open {
        grid-template-rows: 1fr;
      }
    }

    &-btn {
      @include flex-row(1rem);
      align-items: center;
      justify-content: center;
      justify-self: center;
      margin: 1rem auto;
      padding: 1rem;
      cursor: pointer;
      width: calc(100% - 2rem);
      user-select: none;
      @include gray-bg-hover;

      font-family: 'Nunito Sans', sans-serif;
      @include f14;
    }

    &-clear {
      color: #cecede;
      position: absolute;
      right: 2rem;
    }

    &-controls {
      @include flex-row(1rem);
      justify-content: flex-end;
      align-items: center;
      margin-top: auto;
    }
  }
}
</style>
