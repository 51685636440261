import { RouteRecordRaw } from 'vue-router';
const PurchaseView = () => import('@/views/purchase.vue');
const PurchaseIndex = () => import('@/views/purchase/index.vue');
const PurchaseTariffId = () => import('@/views/purchase/_tariffId.vue');

export default {
  path: '/purchase',
  redirect: { name: 'PurchaseIndex' },
  name: 'PurchaseView',
  component: PurchaseView,
  children: [
    {
      path: '',
      name: 'PurchaseIndex',
      component: PurchaseIndex,
    },
    {
      path: ':tariffId',
      name: 'PurchaseTariffId',
      component: PurchaseTariffId,
    },
  ],
  meta: {
    layout: 'default',
    header: true,
  },
} as RouteRecordRaw;
