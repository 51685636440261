import { createUrl } from '@/utils/url';

const analyticsUrl = createUrl('web/analytics');

const getSelf = analyticsUrl('getSelf');

const getTeam = analyticsUrl('getTeam');
const lineGraph = analyticsUrl('lineGraph/overdueAndDoneTasks');

export const analyticsEndpoint = {
  getSelf,
  getTeam,
  lineGraph,
};
