<template>
  <ui-modal
    :title="$t('popups.user_view.user_profile')"
    class="user-view-modal"
    :is-open="isOpen"
    @on-close="$emit('on-close')"
  >
    <template #body>
      <div class="user-view-modal__body" v-if="user">
        <div class="user-view-modal__row header-row">
          <ui-avatar
            :avatar-link="user.avatar?.uuidLink"
            size="6rem"
            :fallbackName="concatName(user.firstName, user.lastName)"
          />
          <span>{{ fullName }}</span>
        </div>
        <div
          class="user-view-modal__row"
          v-for="{ label, value } in cardRows"
          :key="label"
        >
          <span class="user-view-modal__label">{{ label }}</span>
          <span class="user-view-modal__value">{{ value }}</span>
        </div>
      </div>
      <div
        class="user-view-modal__delete-user"
        @click="isUserDeleteConfirmationOpen = true"
        v-if="isWorkspaceOwner && currentUser?.id !== user?.id"
      >
        <icon-close />
        {{ $t('popups.user_view.delete_user') }}
      </div>
      <modal-delete-user
        :user="user"
        :is-open="isUserDeleteConfirmationOpen"
        @on-close="isUserDeleteConfirmationOpen = false"
        @on-delete="
          isUserDeleteConfirmationOpen = false;
          $emit('on-close');
          usersFetch();
        "
      />
    </template>
  </ui-modal>
</template>

<script setup lang="ts">
import UiModal from '@/components/ui/UiModal.vue';
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { ISubordinate } from '@/types/subordinate';
import IconClose from '@/components/icon/IconClose.vue';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import ModalDeleteUser from '@/components/modals/UserView/ModalDeleteUser.vue';
import { format } from 'date-fns';
import { useSubordinatesStore } from '@/stores/subordinates/subordinates';
import { useWorkspaceStore } from '@/stores/workspaces';
import { concatName } from '@/utils/text';

const { t } = useI18n();

const workspacesStore = useWorkspaceStore();
const { isWorkspaceOwner } = storeToRefs(workspacesStore);

const userStore = useUserStore();
const { userData: currentUser } = storeToRefs(userStore);

const subordinatesStore = useSubordinatesStore();
const { fetch: usersFetch } = subordinatesStore;

const isUserDeleteConfirmationOpen = ref(false);

const props = defineProps<{
  isOpen: boolean;
  user?: (ISubordinate & Record<string, any>) | null;
}>();

const user = toRef(props, 'user');

const cardRows = computed(() => [
  {
    label: t('popups.user_view.email'),
    value: user.value?.email,
  },
  {
    label: t('popups.user_view.position'),
    value: user.value?.positionName,
  },
  {
    label: t('popups.user_view.registered'),
    value: user.value?.createDateTime
      ? format(new Date(user.value?.rawCreateDateTime), 'dd.MM.yyyy')
      : t('undefined'),
  },
]);

const fullName = computed(() => {
  return `${user.value?.firstName} ${user.value?.lastName}`;
});
</script>

<style lang="scss">
.user-view-modal {
  &.el-dialog.modal {
    min-height: unset;
    height: fit-content;

    .modal__body {
      padding-bottom: 3rem;
    }
  }

  &__body {
    @include flex-column(2rem);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background: #fcfcfc;
    border-radius: 0.8rem;

    &.header-row {
      @include f20;
      justify-content: flex-start;
      gap: 3rem;
    }
  }

  &__label,
  &__value {
    @include f16;
  }

  &__label {
    color: #b8b8d2;
  }

  &__delete-user {
    margin: 3rem auto 0;
    @include flex-row(1rem);
    justify-content: center;
    align-items: center;
    @include f16;
    cursor: pointer;

    > svg {
      width: 2.6rem;
      height: 2.6rem;
      stroke: var(--color-danger);
    }
  }
}
</style>
