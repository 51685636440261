<template>
  <layout-header v-if="$route.meta.header" />
  <ModalViewTask
    :is-share="true"
    :is-open="modalTaskViewStore.isOpenModal"
    @on-close="modalTaskViewStore.clearTask"
  />
  <div class="page">
    <slot />
  </div>
</template>

<script setup lang="ts">
import ModalViewTask from '@/components/modals/ModalViewTask/ModalViewTask.vue';
import { useModalViewTaskStore } from '@/stores/modalTaskView/modalTaskView';
import LayoutHeader from '@/components/layout/default/LayoutHeader.vue';
const modalTaskViewStore = useModalViewTaskStore();
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
}
</style>
