import { createUrl } from '@/utils/url';

const registrationUrl = createUrl('registration');
const authUrl = createUrl('auth');
const userUrl = createUrl('web/users');
const accountUrl = createUrl('auth/calendar');

const registrationEndpoint = (id?: string | number) =>
  registrationUrl(`${id || ''}`);
const sendVerificationEmail = registrationUrl('sendVerification');
const resetPassEndpoint = registrationUrl('resetPass');
const resetPassChange = registrationUrl('resetPass/change');
const changeUserInfo = registrationUrl('changeUserInfo');

const login = authUrl('');
const refresh = authUrl('refresh');
const thirdParties = authUrl('thirdParties');

const user = userUrl('');
const setUserAvatar = userUrl('setUserAvatar');

const accounts = accountUrl('account');
const accountCalendars = accountUrl('list');
const setCalendar = accountUrl('status');

export const auth = {
  registration: registrationEndpoint,
  sendVerificationEmail,
  setUserAvatar,
  resetPassChange,
  changeUserInfo,
  resetPassEndpoint,
  login,
  refresh,
  user,
  thirdParties,
  accounts,
  accountCalendars,
  setCalendar,
};
