<script setup lang="ts">
import UiSpinner from '@/components/ui/UiSpinner.vue';
import GoogleAccountSettingsWorkspace from '@/components/integrations/google/settings/GoogleAccountSettingsWorkspace.vue';
import { useWorkspaceStore } from '@/stores/workspaces';
import { storeToRefs } from 'pinia';
import { inject, ref, Ref } from 'vue';

const workspacesStore = useWorkspaceStore();
const { internalList } = storeToRefs(workspacesStore);

const startWorkspace = inject<Ref<string>>('startWorkspace', ref(''));
</script>

<template>
  <div>
    <div class="google-calendars__row">
      <span>
        <img src="@images/logo-icon.svg" alt="" />
        Voiset workspaces
      </span>
      <el-switch class="green" />
    </div>
    <h3 class="google-calendars__desc">
      Your Voiset workspaces to be displayed in Google Calendar
    </h3>
    <transition name="blur" appear mode="out-in">
      <div
        class="google-calendars__list with-scroll"
        v-if="internalList.content.list.length"
      >
        <GoogleAccountSettingsWorkspace
          v-if="startWorkspace"
          :workspace="{ ...internalList.content.list[0], name: startWorkspace }"
        />
        <template v-else>
          <GoogleAccountSettingsWorkspace
            v-for="w in internalList.content.list"
            :workspace="w"
            :key="w.id"
          />
        </template>
      </div>
      <UiSpinner v-else />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.google-calendars {
  &__row {
    @include flex-row(1rem);
    align-items: center;
    justify-content: space-between;
    @include f16;
    font-weight: 600;

    & > span {
      @include flex-row(1rem);
      align-items: center;
      justify-content: center;
    }

    img {
      width: 3rem;
    }
  }

  &__list {
    @include flex-column(1.5rem);
    max-height: 30rem;
    margin-top: 1rem;
    overflow: auto;
    padding-right: 0.5rem;
  }

  &__desc {
    @include flex-row(1rem);
    align-items: center;
    @include f14;
    color: #bdbdbd;

    & > img {
      width: 3rem;
      aspect-ratio: 1;
    }
  }
}
</style>
