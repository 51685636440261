<template>
  <div class="header__balance">
    <div class="header__balance-left">
      {{ t('header.balance') }}
      <div class="header__balance-item purple-text">
        {{ tariffsView.content.recognitionLeftHeader }}
        {{ t('header.min_left') }}
      </div>
    </div>
    <div
      class="header__balance-upgrade"
      @click="isPlansModalVisible = true"
      v-if="nextPlan"
    >
      {{ t('header.upgrade') }}
      <ui-plan-title
        :type="nextPlan"
        class="header__balance-plan"
        size="small"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import UiPlanTitle from '@/components/ui/UiPlan/UiPlanTitle.vue';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { useWorkspaceStore } from '@/stores/workspaces';
import { whenever } from '@vueuse/core';

const billingStore = useTariffStore();
const { tariffsView, isPlansModalVisible } = storeToRefs(billingStore);

const workspaceStore = useWorkspaceStore();
const { currentWorkspace } = storeToRefs(workspaceStore);

whenever(
  () => currentWorkspace.value?.id,
  (newId, oldId) => {
    if (!oldId || oldId === newId) return;
    billingStore.fetchUserTariff();
  },
);

const { t } = useI18n();

const plans = {
  basic: {
    next: 'advanced',
  },
  advanced: {
    next: 'premium',
  },
  premium: {
    next: '',
  },
};

const nextPlan = computed(() => {
  return (
    plans[
      tariffsView?.value?.content?.name?.toLowerCase() as keyof typeof plans
    ]?.next || undefined
  );
});
</script>

<style lang="scss" scoped>
.header__balance {
  min-height: 4.8rem;

  &-left {
    @include flex-row(1rem);
    @include f14;
    color: #b8b8d2;
  }

  &-upgrade {
    @include flex-row(0.5rem);
    align-items: center;

    @include f14;
    color: #1a2a39;

    cursor: pointer;
  }

  &-item {
    @include flex-row(1rem);
  }

  &-plan {
    flex-direction: row-reverse;
    :deep(.title) {
      line-height: 1.8rem;
    }
  }
}
</style>
