<template>
  <div :class="['layout__header header', { padded: padded }]">
    <transition name="fade" appear mode="out-in">
      <div class="layout-coaches" v-if="isCoachesVisible" />
    </transition>
    <UiBurger
      @toggle="isOpen = !isOpen"
      :is-open="isOpen"
      :class="['header__burger', { mobile: isMobile }]"
      :style="isOpen && 'display: flex'"
    />
    <img class="header__logo" src="@images/logo.svg" alt="" />
    <img class="header__logo-mobile" src="@images/logo-icon.svg" alt="" />
    <WidgetHeaderBalance
      class="header__widget-balance"
      v-if="!$route.meta.isAgenda"
    />
    <LayoutMobileAside :is-open="isOpen" @close="isOpen = !isOpen">
      <template #top>
        <WidgetHeaderBalance v-if="!$route.meta.isAgenda" />
      </template>
    </LayoutMobileAside>
    <MarketLinks large-buttons no-description class="layout__header-links" />
    <div class="layout__header-right">
      <WidgetAgenda v-if="$route.meta.isAgenda" />
      <template v-else>
        <WidgetWorkSpaces />
        <WidgetTodo />
      </template>
      <WidgetCoach />
      <WidgetNotification />
      <WidgetProfile />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, Ref, ref, watchEffect } from 'vue';
import WidgetProfile from '@/components/widget/WidgetProfile.vue';
import WidgetNotification from '@/components/widget/WidgetNotification.vue';
import WidgetHeaderBalance from '@/components/widget/WidgetHeaderBalance.vue';
import UiBurger from '@/components/ui/UiBurger.vue';
import LayoutMobileAside from '@/components/layout/app/LayoutMobileAside.vue';
import MarketLinks from '@/components/market-links/MarketLinks.vue';
import WidgetTodo from '@/components/widget/WidgetTodo.vue';
import WidgetCoach from '@/components/widget/WidgetCoach.vue';
import { useCoachesStore } from '@/stores/coaches/coaches';
import { storeToRefs } from 'pinia';
import WidgetWorkSpaces from '@/components/widget/WidgetWorkSpaces.vue';
import { useRoute } from 'vue-router';
import WidgetAgenda from '@/components/widget/WidgetAgenda.vue';

const route = useRoute();
const isOpen = ref(false);

const isMobileResolution = inject<Ref<boolean>>('isMobileResolution');
const isMobile = computed(
  () => isMobileResolution?.value || route.meta.noAside,
);
const padded = computed(() => route.meta.padded);

const coachesStore = useCoachesStore();
const { activeCard, isCoachesVisible } = storeToRefs(coachesStore);

watchEffect(() => {
  isOpen.value =
    isCoachesVisible.value &&
    !!isMobileResolution?.value &&
    !!activeCard.value.highlight?.includes('sidebar');
});
</script>

<style lang="scss" scoped>
.test {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(0.5rem);
}
.layout {
  &__header {
    padding: 1.6rem 2.4rem;
    height: 8rem;
    background: #fcfcfc;
    transition: all 0.3s ease;

    &-right {
      @include flex-row(1rem);
      align-items: center;
      margin-left: auto;
    }

    &-links {
      @media (max-width: 1470px) {
        display: none;
      }
    }

    &.padded {
      padding: 1rem 2.4rem;
      height: calc(8rem - 1.2rem);
    }
  }
}

.header {
  @include flex-row(1rem);
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &__logo {
    height: 2.8rem;

    &-mobile {
      display: none;
    }

    @media (max-width: 1300px) {
      display: none;

      &-mobile {
        display: block;
      }
    }
  }

  &__search {
    width: 100%;
    max-width: 32.7rem;
  }

  &__burger {
    display: none;
    z-index: 1000;

    &.mobile {
      display: flex;
    }
  }

  &__widget {
    &-balance {
      @include break-point(1150px) {
        display: none;
      }
    }
  }
}
</style>
