<script setup lang="ts">
import { onMounted, ref } from 'vue';

type Props = { isInitialOpen?: boolean; static?: boolean };
const props = withDefaults(defineProps<Props>(), {
  isInitialOpen: false,
  static: false,
});
const isOpen = ref(false);

onMounted(() => (isOpen.value = props.isInitialOpen));
</script>

<template>
  <div class="ui-dropdown">
    <div class="ui-dropdown__label" @click="!static && (isOpen = !isOpen)">
      <slot name="label" :isOpen="isOpen" />
    </div>
    <div :class="['ui-dropdown__container', { open: static || isOpen }]">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.ui-dropdown {
  &__label {
    @include flex-row(0.5rem);
    align-items: center;
    @include f14;
    cursor: pointer;
    user-select: none;

    & > img {
      width: 3rem;
      aspect-ratio: 1;
      box-shadow: 0 0 4px 0 rgba(164, 164, 164, 0.25);
      border-radius: 0.8rem;
    }

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &__list {
    @include flex-column(0.6rem);
    @include f14;
    overflow: auto;

    & > * {
      margin-left: 1rem;
      padding: 0.4rem 0.8rem;
      @include gray-bg-hover;
      cursor: pointer;
      user-select: none;

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }

  &__container {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid 0.15s ease-in-out;

    &.open {
      grid-template-rows: 1fr;
    }
  }

  &__logo {
    width: 3rem;
    aspect-ratio: 1;
    box-shadow: 0 0 4px 0 rgba(164, 164, 164, 0.25);
    border-radius: 0.8rem;
  }

  &__options {
    margin-left: auto;

    & > img:last-of-type {
      @include gray-bg-hover;
      border-radius: 50%;
      padding: 0.5rem;
      vertical-align: middle;
    }
  }
}
</style>
