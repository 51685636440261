import { defineStore } from 'pinia';
import { useStepper } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Note, NotePlanner, RawTask } from '@/types/notes';
import services from '@/api/services';
import { useNotesListStore } from './useNotesList';
import { useTasksStore } from '@/stores/tasks/tasks';
import { useRawTasks } from '@/hooks/notes/useRawTasks';
const NoteTransformModes = {
  ONE: 'one',
  MANY: 'many',
} as const;

export type NoteTransformType = (keyof typeof NoteTransformModes)[number];

export const useNotesTransformStore = defineStore('notes-transform', () => {
  const { currentStep, goToStep, isStarted, setStepMode, goToNextStep } =
    useTransformSteps();

  const initialNotes = ref<Note[]>([]);
  const mode = ref<'one' | 'many'>('one');

  const planners = ref<NotePlanner[]>([]);
  const rawTasks = ref<RawTask[]>([]);

  const start = (items: Note[]) => {
    if (!items.length) return;
    initialNotes.value = items;
    mode.value = items.length === 1 ? 'one' : 'many';
    setStepMode(mode.value);
    goToStep('prepare');
  };

  const cancel = () => {
    goToStep('none');
  };

  const tasksStore = useTasksStore();
  const notesListStore = useNotesListStore();
  const router = useRouter();

  const { createTasks, convertRawTask } = useRawTasks();

  const end = async (canceled = true) => {
    if (canceled) {
      $reset();
      return;
    }
    if (mode.value === 'many') {
      await createTasks(rawTasks.value.map(convertRawTask));
    }
    await Promise.all([
      tasksStore.fetch(),
      tasksStore.getTodoCount(),
      notesListStore.toggleStatus(planners.value.map((p) => p.id)),
    ]);
    if (mode.value === 'many') {
      router.push('/calendar');
    }
    $reset();
  };

  const scheduleTasks = async () => {
    const { data } = await services.notes.scheduleTask(planners.value);
    rawTasks.value = data.map((rawTask) => {
      const note = initialNotes.value.find((n) => n.title === rawTask.name);
      if (!note) return rawTask;
      const planner = planners.value.find((p) => p.id === note.id);
      if (!planner) return rawTask;
      if (planner.selectedDate) {
        rawTask.deadline = planner.selectedDate;
      }
      rawTask.audioFileId = note.audioFileId;
      rawTask.companyId = planner.companyId;
      return rawTask;
    });
    return data;
  };

  const $reset = () => {
    cancel();
    mode.value = 'one';
    initialNotes.value = [];
    planners.value = [];
  };

  return {
    initialNotes,
    currentStep,
    isStarted,
    mode,
    planners,
    rawTasks,

    start,
    cancel,
    end,
    goToStep,
    goToNextStep,
    scheduleTasks,
  };
});

function useTransformSteps() {
  const steps = ref(['none', 'prepare', 'workspace', 'generate', 'task']);
  const {
    current: currentStep,
    goTo: goToStep,
    goToNext: goToNextStep,
  } = useStepper(steps);

  const isStarted = computed(() => currentStep.value !== 'none');

  const setStepMode = (type: NoteTransformType) => {
    if (type === 'one') {
      steps.value = ['none', 'prepare', 'workspace', 'generate', 'task'];
    } else {
      steps.value = ['none', 'prepare', 'workspace', 'calendar', 'generate'];
    }
  };

  return {
    currentStep,
    isStarted,
    goToStep,
    goToNextStep,
    setStepMode,
  };
}
