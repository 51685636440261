<template>
  <li class="card-tariff__list-item" v-if="isTranslateExisted">
    <icon-tick class="card-tariff__list-item-icon" />
    <span
      v-html="
        t(`card_tariff.services.advantages.${name}.${prop}`, {
          ...(count && { count }),
          accent,
        })
      "
    />
  </li>
</template>

<script setup lang="ts">
import IconTick from '@/components/icon/IconTick.vue';
import { ITariffItemResponse } from '@/types/tariffs';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

const { t, te } = useI18n();

const props = withDefaults(
  defineProps<{
    name: ITariffItemResponse['title'];
    count?: number | string;
    accent?: string;
    prop: string;
    markerColor: string;
  }>(),
  { accent: 'green' },
);

const isTranslateExisted = computed(() =>
  te(`card_tariff.services.advantages.${props.name}.${props.prop}`),
);
</script>

<style scoped lang="scss">
.card-tariff__list {
  &-item {
    display: grid;
    grid-template-columns: 1.6rem auto;
    align-items: center;
    column-gap: 0.8rem;
    line-height: 1.4;

    &-icon {
      align-self: baseline;
      color: v-bind('markerColor');
    }
  }
}
</style>
