<template>
  <transition name="blur" appear mode="out-in">
    <ui-spinner style="height: 25rem" v-if="pending" />
    <div class="workspace-invitations" v-else>
      <slot name="header">
        <h3>{{ $t('workspaces.create.title-invite') }}</h3>
        <h3>{{ $t('workspaces.create.subtitle-invite') }}</h3>
      </slot>
      <template v-for="(email, i) in inviteEmailsList.length + 1" :key="email">
        <ui-input-underscore
          v-if="i < 3"
          :class="['workspace-invitations__input', { error: isErrorState(i) }]"
          label="Email"
          v-model="inviteEmailsList[i]"
          @keydown.enter.stop="!isInvitationDisabled && onSendInvitations()"
        />
      </template>
      <span>{{ $t('workspaces.create.choose') }}</span>
      <el-select
        class="ui__multiselect"
        v-model="inviteUsersList"
        multiple
        collapse-tags
        placeholder="Select users"
        value-key="id"
        :disabled="!users.length"
      >
        <el-option
          v-for="item in users"
          :key="item.id"
          :label="item.fullName || item.email"
          :value="item"
        >
          <template #default>
            <div class="users-multiselect">
              <ui-avatar
                size="3rem"
                :avatar-link="item?.avatar?.uuidLink"
                :fallback-name="item.fullName"
              />
              {{ item.fullName || item.email }}
            </div>
          </template>
        </el-option>
      </el-select>
      <div class="workspace-invitations__footer">
        <slot
          name="footer"
          :on-invite="onSendInvitations"
          :disabled="isInvitationDisabled"
        >
          <ui-button plain @click="$emit('next-step')">
            {{ $t('workspaces.create.skip_btn') }}
          </ui-button>
          <ui-button
            :is-disabled="isInvitationDisabled"
            @click="onSendInvitations"
          >
            {{ $t('workspaces.create.invite_btn') }}
          </ui-button>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import UiAvatar from '@/components/ui/UiAvatar.vue';
import { computed, ref } from 'vue';
import { isEmailValid } from '@/utils/text';
import { Workspace } from '@/types/workspaces';
import { useInvitationsStore } from '@/stores/invitations/invitations';
import { useWorkspaceStore } from '@/stores/workspaces';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { SubordinateViewType } from '@/utils/subordinates';

const props = withDefaults(
  defineProps<{ workspace?: Workspace; users?: SubordinateViewType[] }>(),
  { users: () => [] },
);
const emits = defineEmits(['next-step']);

const { t } = useI18n();

const workspaceStore = useWorkspaceStore();

const invitationsStore = useInvitationsStore();

const inviteEmailsList = ref<string[]>([]);
const inviteUsersList = ref<SubordinateViewType[]>([]);
const pending = ref(false);
const isInvitationsSent = ref(false);

const invitationsCount = computed(
  () =>
    inviteEmailsList.value.filter(Boolean).length +
    inviteUsersList.value.length,
);

const isInvitationDisabled = computed(
  () =>
    !invitationsCount.value ||
    inviteEmailsList.value.some((_, i) => isErrorState(i)),
);

const isErrorState = (i: number) =>
  inviteEmailsList.value[i] && !isEmailValid(inviteEmailsList.value[i]);

const onSendInvitations = async () => {
  if (!props.workspace) return;
  const companyId = props.workspace.id;

  try {
    pending.value = true;
    const emailInvitations = inviteEmailsList.value.map((email) =>
      invitationsStore.createInvite({ email, companyId }),
    );
    const userInvitations = inviteUsersList.value.map(({ email }) =>
      invitationsStore.createInvite({ email, companyId }),
    );

    const [emailInvitationsResult, userInvitationsResult] = await Promise.all([
      Promise.allSettled(emailInvitations),
      Promise.allSettled(userInvitations),
    ]);

    const getRejectedEmails = (promiseResults: PromiseSettledResult<any>[]) => {
      return (
        promiseResults.filter(
          ({ status }) => status === 'rejected',
        ) as PromiseRejectedResult[]
      ).map((r) => r.reason?.config?.params?.email);
    };

    const rejectedUsersInvitationsEmails = getRejectedEmails(
      userInvitationsResult,
    );
    const rejectedEmailsInvitationsEmails = getRejectedEmails(
      emailInvitationsResult,
    );

    const addUsersToWorkspaceList = inviteUsersList.value.filter(
      ({ email }) => !rejectedUsersInvitationsEmails.includes(email),
    );

    await Promise.allSettled(
      addUsersToWorkspaceList.map(({ id: userId }) =>
        workspaceStore.addUser({ userId, companyId }),
      ),
    );

    isInvitationsSent.value = true;

    const rejectedInvitationsEmails = [
      ...rejectedUsersInvitationsEmails,
      ...rejectedEmailsInvitationsEmails,
    ];

    if (rejectedInvitationsEmails.length) {
      ElNotification.warning({
        message: `Some of invitations not sent! (${rejectedInvitationsEmails
          .filter(Boolean)
          .join(', ')})`,
        offset: 80,
      });
    } else {
      ElNotification.success({
        message: 'All invitations has been successfully sent!',
        offset: 80,
      });
    }
    emits('next-step', 3);
  } catch (e) {
    console.error(e);
    ElNotification.error({ message: t('some_error'), offset: 80 });
  } finally {
    pending.value = false;
  }
};
</script>

<style scoped lang="scss">
.workspace-invitations {
  @include flex-column(2rem);
  align-items: center;
  justify-content: center;
  padding: 2.5rem 3rem;
  min-width: 40rem;
  width: 100%;
  color: #1a2a39;

  & > h3 {
    @include f16;
  }

  &__input {
    width: inherit;
  }

  &__footer {
    @include flex-row(1.2rem);
    width: 100%;

    & > * {
      flex: 1;
    }
  }
}
</style>
