<template>
  <div class="subscription__info" v-if="convertPrice.price">
    <span class="subscription__info-price">
      {{ convertPrice.price }}{{ convertPrice.currency }} /{{
        tariffsView.content.typeRefresh || ''
      }}
    </span>
    <span
      :class="[
        'subscription__info-renewal',
        tariffsView?.autoRenewal ? 'green-text' : 'danger-text',
      ]"
    >
      {{ tariffsView?.autoRenewal ? 'automatic renewal' : 'plan cancelled' }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useCurrencyConvert } from '@/hooks/useCurrencyConvert';
import { useTariffStore } from '@/stores/tariffs/tariffs';
import { storeToRefs } from 'pinia';

const billingStore = useTariffStore();

const { tariffsView } = storeToRefs(billingStore);

const { convertPriceWithLanguagePartials } = useCurrencyConvert();

const convertPrice = computed(() => {
  const price = convertPriceWithLanguagePartials(
    tariffsView.value.content.price || 0,
    tariffsView.value.content.currency || 'USD',
  );
  const integer = price.find((item) => item.type === 'integer')?.value;
  const decimal = price.find((item) => item.type === 'decimal')?.value;
  const fraction = price.find((item) => item.type === 'fraction')?.value;

  return {
    price: `${integer}${decimal}${fraction}`,
    currency: price.find((item) => item.type === 'currency')?.value || '',
  };
});
</script>
<style lang="scss" scoped>
.subscription__info {
  @include flex-row();
  align-items: center;
  justify-content: space-between;

  &-price {
    @include f14;
    line-height: 2rem;
    color: #b8b8d2;
  }

  &-renewal {
    @include f12;
    line-height: 1.8rem;
  }
}
</style>
