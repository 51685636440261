<template>
  <transition name="blur" appear>
    <div v-if="lang">
      <el-popover
        placement="bottom"
        width="fit-content"
        trigger="click"
        v-model:visible="isOpen"
      >
        <div class="ui-dropdown-lang">
          <div class="ui-dropdown-lang__list">
            <div
              :class="[
                'ui-dropdown-lang__item',
                { current: settingsStore.lang === lang?.abbreviation },
              ]"
              v-for="lang in availableLangList"
              :key="lang.id"
              @click="
                setLang(lang);
                isOpen = false;
              "
            >
              {{ lang.name }}
              <el-radio
                size="small"
                v-if="settingsStore.lang === lang?.abbreviation"
              />
            </div>
          </div>
        </div>
        <template #reference>
          <div class="ui-dropdown-lang__reference">
            <img src="@images/earth.svg" alt="" />
            <span class="ui-dropdown-lang__label">
              {{ settingsStore?.lang }}
            </span>
            <icon-chevron :direction="isOpen ? 'top' : 'bottom'" />
          </div>
        </template>
      </el-popover>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useSettingsStore } from '@/stores/settings/settings';
import { storeToRefs } from 'pinia';
import { LangUI } from '@/types/lang';
import IconChevron from '@/components/icon/IconChevron.vue';

const settingsStore = useSettingsStore();
const { localeUiList, lang } = storeToRefs(settingsStore);

const isOpen = ref(false);

const availableLangList = computed(() => {
  return localeUiList.value?.filter((item: LangUI) =>
    ['RU', 'EN'].includes(item.abbreviation),
  );
});

const setLang = async (item: LangUI) => {
  await settingsStore.saveUiLocale(item.abbreviation);
  await settingsStore.getUserData();
  localStorage.lang = item.abbreviation.toLowerCase();
};

onMounted(() => {
  if (!lang.value) {
    settingsStore.getUserData();
    settingsStore.getLocaleUiList();
  }
});
</script>
<style scoped lang="scss">
.ui-dropdown-lang {
  &__list {
    @include flex-column;
  }

  &__item {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
    width: 100%;
    border-radius: 0.8rem;
    padding: 1rem 0.8rem;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    &:hover,
    &.current {
      background-color: #f2f7f7;
      color: var(--color-primary);
    }
  }

  &__reference {
    @include flex-row(1rem);
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__label {
    text-transform: capitalize;
    @media (max-width: 850px) {
      display: none;
    }
  }
}
</style>
