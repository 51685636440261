import { RouteRecordRaw } from 'vue-router';
import { onLogout } from '@/stores/heplers';

export default {
  path: '/logout',
  name: 'Logout',
  redirect: () => {
    onLogout();
    return { path: '/login' };
  },
  meta: {
    public: true,
  },
} as RouteRecordRaw;
